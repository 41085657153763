var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-select",
    {
      ref: "select",
      staticClass: "aos-select-tree",
      attrs: {
        size: _vm.size,
        multiple: _vm.multiple,
        clearable: _vm.clearable,
        disabled: _vm.disabled,
        placeholder: _vm.placeholder,
      },
      on: {
        "visible-change": _vm.visibleChange,
        clear: _vm.clearSelect,
        "remove-tag": _vm.removeTag,
      },
      model: {
        value: _vm.show,
        callback: function ($$v) {
          _vm.show = $$v
        },
        expression: "show",
      },
    },
    [
      _vm._l(_vm.selectOptions, function (item) {
        return _c("el-option", {
          key: item.value,
          staticClass: "aos-select-tree-options",
          staticStyle: { padding: "5px" },
          attrs: { value: item.value, label: item.label, disabled: "" },
        })
      }),
      _vm.showSearch
        ? _c("el-input", {
            attrs: {
              size: _vm.size,
              placeholder: _vm.$t("inputKeyworldForfilter"),
            },
            model: {
              value: _vm.filterText,
              callback: function ($$v) {
                _vm.filterText = $$v
              },
              expression: "filterText",
            },
          })
        : _vm._e(),
      _c("el-tree", {
        ref: "tree",
        staticClass: "aos-select-tree-tree",
        attrs: {
          size: _vm.size,
          data: _vm.data,
          "node-key": "value",
          "filter-node-method": _vm.filterNode,
          "check-on-click-node": !_vm.multiple,
          "expand-on-click-node": !_vm.multiple,
          "highlight-current": "",
        },
        on: { "node-click": _vm.nodeClick },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function (ref) {
              var node = ref.node
              var data = ref.data
              return _c(
                "div",
                {
                  staticClass: "aos-tree-slot-content",
                  class:
                    _vm.multiple &&
                    Array.isArray(_vm.value) &&
                    _vm.value.indexOf(data.value) > -1
                      ? "is-current"
                      : "",
                },
                [
                  _c("span", [_vm._v(_vm._s(node.label))]),
                  _vm.multiple &&
                  Array.isArray(_vm.value) &&
                  _vm.value.indexOf(data.value) > -1
                    ? _c("i", { staticClass: "el-icon-check" })
                    : _vm._e(),
                ]
              )
            },
          },
        ]),
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }