var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dropdown",
    { attrs: { trigger: "click" }, on: { command: _vm.handleSetLanguage } },
    [
      _c("i", { staticClass: "icon-zhongyingwen" }),
      _c(
        "el-dropdown-menu",
        { attrs: { slot: "dropdown" }, slot: "dropdown" },
        [
          _c(
            "el-dropdown-item",
            { attrs: { disabled: _vm.language === "zh", command: "zh" } },
            [_vm._v("中文\n    ")]
          ),
          _c(
            "el-dropdown-item",
            { attrs: { disabled: _vm.language === "en", command: "en" } },
            [_vm._v("English\n    ")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }