<template>
  <div class="avue-top">
    <div class="top-bar__left">
      <TopLeft />
      <TopTabs />
    </div>
    <div class="top-bar__right">
      <el-tooltip
        effect="dark"
        :content="$t('navbar.lock')"
        placement="bottom">
        <div class="top-bar__item help">
        </div>
      </el-tooltip>
      <!-- <topLang/> -->
      <img class="top-bar__img" :src="userInfo.avatar || headPic" />
      <el-dropdown>
        <span class="el-dropdown-link">
          {{ userInfo.nick_name }}
          <i class="el-icon-arrow-down el-icon--right"></i>
        </span>
        <el-dropdown-menu slot="dropdown">
          <el-dropdown-item>
            <router-link to="/">{{ $t("navbar.dashboard") }}</router-link>
          </el-dropdown-item>
          <el-dropdown-item>
            <router-link to="/useInfo/index">{{
              $t("navbar.userinfo")
            }}</router-link>
          </el-dropdown-item>
          <el-dropdown-item @click.native="logout" divided
            >{{ $t("navbar.logOut") }}
          </el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
    </div>
  </div>
</template>
<script>
import { resetRouter } from "@/router/router";
import { mapGetters, mapState } from "vuex";
import { fullscreenToggel, listenfullscreen } from "@/util/util";
import topLock from "./top-lock";
import topLang from "./top-lang";
import TopLeft from "./top-left";
import TopTabs from "./top-tabs";

export default {
  components: {
    topLock,
    topLang,
    TopLeft,
    TopTabs,
  },
  name: "top",
  data() {
    return {
      headPic: "/img/head_pic.png",
    };
  },
  filters: {},
  created() {},
  mounted() {
    listenfullscreen(this.setScreen);
  },
  computed: {
    ...mapState({
      showDebug: (state) => state.common.showDebug,
      showTheme: (state) => state.common.showTheme,
      showLock: (state) => state.common.showLock,
      showFullScren: (state) => state.common.showFullScren,
      showCollapse: (state) => state.common.showCollapse,
      showSearch: (state) => state.common.showSearch,
      showMenu: (state) => state.common.showMenu,
      showColor: (state) => state.common.showColor,
    }),
    ...mapGetters([
      "userInfo",
      "isFullScren",
      "tagWel",
      "tagList",
      "isCollapse",
      "tag",
      "logsLen",
      "logsFlag",
    ]),
  },
  methods: {
    handleScreen() {
      fullscreenToggel();
    },
    setCollapse() {
      this.$store.commit("SET_COLLAPSE");
    },
    setScreen() {
      this.$store.commit("SET_FULLSCREN");
    },
    logout() {
      this.$confirm(this.$t("logoutTip"), this.$t("tip"), {
        confirmButtonText: this.$t("submitText"),
        cancelButtonText: this.$t("cancelText"),
        type: "warning",
      }).then(() => {
        this.$store.dispatch("LogOut").then(() => {
          resetRouter();
          this.$router.push({ path: "/login" });
        });
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.el-dropdown-link {
  color: #ffffff;
}
.help{
  
}
</style>
