import request from "@/router/axios";

export const getList = (current, size, params) => {
  return request({
    url: "/api/cloud-task/project-management/page",
    method: "get",
    params: {
      ...params,
      current,
      size,
    },
  });
};

export const getDetail = (id) => {
  return request({
    url: "/api/cloud-task/project-management/detail",
    method: "get",
    params: {
      id,
    },
  });
};

export const remove = (ids) => {
  return request({
    url: "/api/cloud-task/project-management/remove",
    method: "delete",
    params: {
      ids,
    },
  });
};

export const add = (row) => {
  return request({
    url: "/api/cloud-task/project-management/add",
    method: "post",
    data: row,
  });
};

export const update = (row) => {
  return request({
    url: "/api/cloud-task/project-management/update",
    method: "put",
    data: row,
  });
};

export const getArarmType = (params) => {
  return request({
    url: "/api/cloud-task/project-management/get-ararm-type",
    method: "get",
    params,
  });
};

export const getUseEquipmentList = (data) => {
  return request({
    url: '/api/cloud-task/project-management/getUseEquipmentList',
    method: 'post',
    data
  })
}

export const setTitle = (data) => {
  return request({
    url: "/api/cloud-task/project-management/screenTitleSet",
    method: "put",
    data: data,
  });
};

export const getFlyingTask = (params) => {
  return request({
    url: '/api/cloud-task/project-management/getFlyingTask',
    method: 'post',
    params
  })
}