import request from "@/router/axios";

// 获取项目列表
export const forbiddenAreaQuery = (params) => {
  return request({
    url: "/api/forbidden-fly-area/geoPage",
    method: "get",
    params,
  });
};

export const getProjectList = (params) => {
  return request({
    url: "/api/screen-info/item-list",
    method: "get",
    params,
  });
};

export const getTaskList = (params) => {
  return request({
    url: "/api/screen-info/mission-list",
    method: "get",
    params,
  });
};

export const getTaskPSImageParams = (params) => {
  return request({
    url: "/api/mapping-config-entity/detail",
    method: "get",
    params,
  });
};

export const updateTaskPSImageParams = (data) => {
  return request({
    url: "/api/mapping-config-entity/update",
    method: "put",
    data,
  });
};

export const getMapRecord = (page, size, missionId) => {
  return request({
    url: "/api/mapping-record-entity/page",
    method: "get",
    params: {
      page,
      size,
      missionId,
    },
  });
};

export const getMapTaskList = (params) => {
  return request({
    url: "/api/mapping-config-entity/mission-plan",
    method: "get",
    params,
  });
};

export const getJointType = () => {
  return request({
    url: "/api/blade-system/dict/dictionary?code=splicingType",
    method: "get",
  });
};

export const startMappping = (missionId) => {
  return request({
    url: "/api/mapping-server/start-mapping",
    method: "get",
    params: {
      missionId,
    },
  });
};

export const getJointSchedule = (missionId) => {
  return request({
    url: "/api/mapping-server/mapping-progress",
    method: "get",
    params: {
      missionId,
    },
  });
};

export const getJointData = (missionId) => {
  return request({
    url: "/api/mapping-server/mapping-result",
    method: "get",
    params: {
      missionId,
    },
  });
};

export const downLoadFile = (missionId) => {
  return request({
    url: "/api/mapping-server/mapping-progress-result",
    method: "get",
    params: {
      missionId,
    },
  });
};

export const downLoadPic = (params) => {
  return request({
    url: "/api/mapping-record-entity/detail",
    method: "get",
    params,
  });
};

export const updateMapLabel = (data) => {
  return request({
    url: "/api/cloud-task/projecttaginfo/submit",
    method: "post",
    data,
  });
};

/********************标注点*********************/
// 保存标注点
export const saveMarkPoint = (data) => {
  return request({
    url: "/api/aos-gisservice/mapmarkpoint/submit",
    method: "post",
    data,
  });
};

// 获取标注点
export const getMarkPoint = (params) => {
  return request({
    url: "/api/aos-gisservice/mapmarkpoint/detail",
    method: "get",
    params,
  });
};

// 删除标注点
export const deleteMarkPoint = (params) => {
  return request({
    url: "/api/aos-gisservice/mapmarkpoint/remove",
    method: "post",
    params,
  });
};

// 保存标注线
export const saveMarkPolyline = (data) => {
  return request({
    url: "/api/aos-gisservice/mapmarkline/submit",
    method: "post",
    data,
  });
};

// 获取标注线
export const getMarkPolyline = (params) => {
  return request({
    url: "/api/aos-gisservice/mapmarkline/detail",
    method: "get",
    params,
  });
};

// 删除标注线
export const deleteMarkPolyline = (params) => {
  return request({
    url: "/api/aos-gisservice/mapmarkline/remove",
    method: "post",
    params,
  });
};

// 保存标注面
export const saveMarkPolygon = (data) => {
  return request({
    url: "/api/aos-gisservice/mapmarkpolygon/submit",
    method: "post",
    data,
  });
};

// 获取标注面
export const getMarkPolygon = (params) => {
  return request({
    url: "/api/aos-gisservice/mapmarkpolygon/detail",
    method: "get",
    params,
  });
};

// 删除标注面
export const deleteMarkPolygon = (params) => {
  return request({
    url: "/api/aos-gisservice/mapmarkpolygon/remove",
    method: "post",
    params,
  });
};


/********************油井*********************/
// 保存油井
export const saveOilwellpoint = (data) => {
  return request({
    url: "/api/aos-gisservice/oilwellpoint/submit",
    method: "post",
    data,
  });
};

// 获取油井详情
export const getOilwellpoint = (params) => {
  return request({
    url: "/api/aos-gisservice/oilwellpoint/detail",
    method: "get",
    params,
  });
};

// 删除油井
export const deleteOilwellpoint = (params) => {
  return request({
    url: "/api/aos-gisservice/oilwellpoint/remove",
    method: "post",
    params,
  });
};

// 修改石油井的高级设置
export const setOilwellpoint = (data) => {
  return request({
    url: "/api/aos-gisservice/oilwellpoint/advancedSet",
    method: "post",
    data,
  });
};

/********************储罐*********************/
// 保存储罐
export const saveStorageTank = (data) => {
  return request({
    url: "/api/aos-gisservice/oilstoragetank/submit",
    method: "post",
    data,
  });
};

// 获取储罐详情
export const getStorageTank = (params) => {
  return request({
    url: "/api/aos-gisservice/oilstoragetank/detail",
    method: "get",
    params,
  });
};

// 删除储罐
export const deleteStorageTank = (params) => {
  return request({
    url: "/api/aos-gisservice/oilstoragetank/remove",
    method: "post",
    params,
  });
};

// 修改储罐井的高级设置
export const setStorageTank = (data) => {
  return request({
    url: "/api/aos-gisservice/oilstoragetank/advancedSet",
    method: "post",
    data,
  });
};

/********************计量站*********************/
// 保存计量站
export const saveMeasurementStation = (data) => {
  return request({
    url: "/api/aos-gisservice/oilmeasurementstation/submit",
    method: "post",
    data,
  });
};

// 获取计量站详情
export const getMeasurementStation = (params) => {
  return request({
    url: "/api/aos-gisservice/oilmeasurementstation/detail",
    method: "get",
    params,
  });
};

// 删除计量站
export const deleteMeasurementStation = (params) => {
  return request({
    url: "/api/aos-gisservice/oilmeasurementstation/remove",
    method: "post",
    params,
  });
};

// 修改计量站井的高级设置
export const setMeasurementStation = (data) => {
  return request({
    url: "/api/aos-gisservice/oilmeasurementstation/advancedSet",
    method: "post",
    data,
  });
};

/********************油气站库*********************/
// 保存油气站库
export const saveStationStorage = (data) => {
  return request({
    url: "/api/aos-gisservice/oilstationstorage/submit",
    method: "post",
    data,
  });
};

// 获取油气站库详情
export const getStationStorage = (params) => {
  return request({
    url: "/api/aos-gisservice/oilstationstorage/detail",
    method: "get",
    params,
  });
};

// 删除油气站库
export const deleteStationStorage = (params) => {
  return request({
    url: "/api/aos-gisservice/oilstationstorage/remove",
    method: "post",
    params,
  });
};

// 修改油气站库井的高级设置
export const setStationStorage = (data) => {
  return request({
    url: "/api/aos-gisservice/oilstationstorage/advancedSet",
    method: "post",
    data,
  });
};

/********************电力设施*********************/
// 保存电力设施
export const savePowerEquipment = (data) => {
  return request({
    url: "/api/aos-gisservice/oilpowerequipment/submit",
    method: "post",
    data,
  });
};

// 获取电力设施库详情
export const getPowerEquipment = (params) => {
  return request({
    url: "/api/aos-gisservice/oilpowerequipment/detail",
    method: "get",
    params,
  });
};

// 删除电力设施
export const deletePowerEquipment = (params) => {
  return request({
    url: "/api/aos-gisservice/oilpowerequipment/remove",
    method: "post",
    params,
  });
};

// 修改电力设施井的高级设置
export const setPowerEquipment = (data) => {
  return request({
    url: "/api/aos-gisservice/oilpowerequipment/advancedSet",
    method: "post",
    data,
  });
};

/********************油井管线*********************/
// 保存油井管线
export const saveOilline = (data) => {
  return request({
    url: "/api/aos-gisservice/oilline/submit",
    method: "post",
    data,
  });
};

// 获取油井管线详情
export const getOilline = (params) => {
  return request({
    url: "/api/aos-gisservice/oilline/detail",
    method: "get",
    params,
  });
};

// 删除油井管线
export const deleteOilline = (params) => {
  return request({
    url: "/api/aos-gisservice/oilline/remove",
    method: "post",
    params,
  });
};

// 修改石油管线的高级设置
export const setOilline = (data) => {
  return request({
    url: "/api/aos-gisservice/oilline/advancedSet",
    method: "post",
    data,
  });
};

/********************油田公路*********************/

// 保存油田公路
export const saveOilRoad = (data) => {
  return request({
    url: "/api/aos-gisservice/oilroad/submit",
    method: "post",
    data,
  });
};

// 获取油田公路详情
export const getOilRoad = (params) => {
  return request({
    url: "/api/aos-gisservice/oilroad/detail",
    method: "get",
    params,
  });
};

// 删除油田公路
export const deleteOilRoad = (params) => {
  return request({
    url: "/api/aos-gisservice/oilroad/remove",
    method: "post",
    params,
  });
};

// 修改油田公路的高级设置
export const setOilRoad = (data) => {
  return request({
    url: "/api/aos-gisservice/oilroad/advancedSet",
    method: "post",
    data,
  });
};

/********************电力线路*********************/

// 保存电力线路
export const savePowerLine = (data) => {
  return request({
    url: "/api/aos-gisservice/oilpowerline/submit",
    method: "post",
    data,
  });
};

// 获取电力线路详情
export const getPowerLine = (params) => {
  return request({
    url: "/api/aos-gisservice/oilpowerline/detail",
    method: "get",
    params,
  });
};

// 删除电力线路
export const deletePowerLine = (params) => {
  return request({
    url: "/api/aos-gisservice/oilpowerline/remove",
    method: "post",
    params,
  });
};

// 修改电力线路的高级设置
export const setPowerLine = (data) => {
  return request({
    url: "/api/aos-gisservice/oilpowerline/advancedSet",
    method: "post",
    data,
  });
};

/********************石油气井*********************/

// 保存石油气井
export const saveOilGasWell = (data) => {
  return request({
    url: "/api/aos-gisservice/oilgaswellpoint/submit",
    method: "post",
    data,
  });
}
// 获取石油气井详情
export const getOilGasWell = (params) => {
  return request({
    url: "/api/aos-gisservice/oilgaswellpoint/detail",
    method: "get",
    params,
  });
}
// 删除石油气井
export const deleteOilGasWell = (params) => {
  return request({
    url: "/api/aos-gisservice/oilgaswellpoint/remove",
    method: "post",
    params,
  });
};
// 修改石油气井的高级设置
export const setOilGasWell = (data) => {
  return request({
    url: "/api/aos-gisservice/oilgaswellpoint/advancedSet",
    method: "post",
    data,
  });
};


/********************禁飞区*********************/
/**
 * 禁飞区数据查询（区域方式）
 * @param {*} params
 * @returns
 */
export const getProhibitData = (params) => {
  return request({
    url: "/api/cloud-task/forbidden-fly-area/areaQuery",
    method: "post",
    data: params,
  });
};
// 根据taskId获取航向
export const getRouteByTaskId = (params) => {
  return request({
    url: "/api/aos-gisservice/routeline/getRouteByTaskId",
    method: "post",
    params,
  });
};
