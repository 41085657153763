/*
 * @Description: 
 * @Autor: wq
 * @Date: 2022-09-14 17:45:40
 * @watermark: 成都傲势科技
 * @LastEditors: wq
 */
import request from '@/router/axios';

// 新增截图
// export const saveShot = (params) => {
//   return request({
//     url: '/api/mapping-record-entity/add',
//     method: 'post',
//     data: {
//       ...params
//     }
//   })
// }

// 截图
export const saveShot = (params) => {
  return request({
    url: '/api/mapping-server/screen-shot',
    method: 'post',
    data: {
      ...params
    }
  })
}

// 修改截图
export const updateMap = (params) => {
  return request({
    url: '/api/mapping-record-entity/update',
    method: 'put',
    data: {
      ...params
    }
  })
}

// 删除截图
export const remove = (ids) => {
  return request({
    url: '/api/mapping-record-entity/remove',
    method: 'delete',
    params: {
      ids
    }
  })
}

// 撤销-删除
export const removeShot = (ids) => {
  return request({
    url: '/api/mapping-server/screen-shot-record',
    method: 'delete',
    params: {
      ids
    }
  })
}

// 删除截图（撤销）
export const revoke = (ids) => {
  return request({
    url: '/api/mapping-server/screen-shot-record',
    method: 'delete',
    params: {
      ids
    }
  })
}
