import request from "@/router/axios";

// 获取正在执行的任务
export const getRunningTaskList = (params) => {
  return request({
    url: '/api/cloud-task/project-task/getRunningTaskList',
    method: 'get',
    params
  })
}

// 获取飞行中心项目详情
export const getProjectDetail = (params) => {
  return request({
    url: '/api/cloud-task/project-management/detail',
    method: 'get',
    params
  })
}
// 获取林草中心项目详情
export const getFGProjectDetail = (params) => {
  return request({
    url: '/api/aos-inspection/gladegrassinspectionproject/detail',
    method: 'get',
    params
  })
}
// 获取油气中心项目详情
export const getOGProjectDetail = (params) => {
  return request({
    url: '/api/aos-inspection/oilinspectionproject/detail',
    method: 'get',
    params
  })
}
// 获取应急中心项目详情
export const getETProjectDetail = (params) => {
  return request({
    url: '/api/aos-inspection/emergencytask/detail',
    method: 'get',
    params
  })
}

/**
 * 禁飞区数据查询（区域方式）
 * @param {*} params
 * @returns
 */
  export const getProhibitData = params => {
   return request({
     url:"/api/cloud-task/forbidden-fly-area/areaQuery",
     method: "post",
     data: params
   })
 };


/**
 * 获取飞行姿态字典
 * @param {*} params
 * @returns
 */
export const getDict = params => {
  return request({
    url: "/api/cloud-task/task-sync/cardJson",
    method: "get",
    params: {
      ...params
    }
  });
};


/**
 * 获取序列号
 * @param {*} params
 * @returns
 */
 export const getSerialNumberByTaskID = params => {
  return request({
    url: "/api/cloud-task/project-task/getSerialNumberByTaskID",
    method: "get",
    params: {
      ...params
    }
  });
};

/**
 * 获取序列号
 * @param {*} params
 * @returns
 */
export const findEquipmentModel = params => {
  return request({
    url: "/api/cloud-equipment/equipment-management/findEquipmentModel",
    method: "get",
    params
  });
};

/**
 * 获取序列号
 * @param {*} params
 * @returns
 */
 export const findHistoryRouteTrack = params => {
  return request({
    url: "/api/cloud-biglog/state-log-message/findHistoryRouteTrack",
    method: "get",
    params: {
      ...params
    }
  });
};

export const getUavDataModel = data => {
  return request({
    url: "/api/cloud-biglog/uavDataModel/page",
    method: "post",
    data
  });
};

export const deptTree = params => {
  return request({
    url: "/api/blade-system/dept/tree",
    method: "get",
    params
  });
};

// 获取基站数据
export const getBasestationList = params => {
  return request({
    url: "/api/aos-gisservice/basestation/list",
    method: "get",
    params
  });
};
// 获取基站详情
export const getBasestationDetail = params => {
  return request({
    url: "/api/aos-gisservice/basestation/detail",
    method: "get",
    params
  });
};