import request from '@/router/axios';

export const getList = (current, size, params) => {
  return request({
    url: '/api/equipment-manager/page',
    method: 'get',
    params: {
      ...params,
      current,
      size,
    }
  })
}

export const getEquipList = (current, size, params) => {
  return request({
    url: "/api/cloud-equipment/equipment-management/page",
    method: "get",
    params: {
      ...params,
      current,
      size,
    },
  });
};

export const getDetail = (id) => {
  return request({
    url: "/api/cloud-equipment/equipment-management/detail",
    method: "get",
    params: {
      id,
    },
  });
};

export const remove = (ids) => {
  return request({
    url: "/api/cloud-equipment/equipment-management/remove",
    method: "delete",
    params: {
      ids,
    },
  });
};

export const add = (row) => {
  return request({
    url: "/api/cloud-equipment/equipment-management/add",
    method: "post",
    data: row,
  });
};

export const update = (row) => {
  return request({
    url: "/api/cloud-equipment/equipment-management/update",
    method: "put",
    data: row,
  });
};

export const getEntity = (params) => {
  return request({
    url: "/api/aos-slabentities/entitymodel/getEmBySign",
    method: "get",
    params,
  });
};