<template>
  <div class="avue-contail" :class="{ 'avue--collapse': isCollapse }">
    <div class="avue-header" style="color: #FFF">
      <!-- 顶部导航栏 -->
      <top ref="top" />
      <!-- 顶部二级标签卡 -->
      <tags/>
    </div>
    <div class="avue-layout">
      <!-- 左侧导航栏 -->
      <div class="avue-main">
        <keep-Alive :include="include">
          <router-view />
        </keep-Alive>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import tags from "./tags";
import search from "./search";
import top from "./top/";
import admin from "@/util/admin";
import { validatenull } from "@/util/validate";
import { calcDate } from "@/util/date.js";
import { getStore } from "@/util/store.js";

export default {
  name: "layout-index",
  components: {
    top,
    tags,
    search
  },
  provide() {
    return {
      index: this,
    };
  },
  data() {
    return {
      //刷新token锁
      refreshLock: false,
      //刷新token的时间
      refreshTime: "",
      alarmSideBar: null,
    };
  },
  computed: {
    ...mapGetters([
      "isCollapse",
      'menuAll',
      "website",
    ]),
    include() {
      let include = []
      if(this.$route.matched && Array.isArray(this.$route.matched) && this.$route.matched.length > 1){
        const root = this.$route.matched[0]
        for (let i = 0; i < this.menuAll.length; i++) {
          const item = this.menuAll[i];
          if (item.code === root.name && item.children) {
            include = item.children.map(_item => {
              return _item.code
            })
          }
        }
      }
      return include
    }
  },
  created() {
    //实时检测刷新token
    this.refreshToken();
  },
  mounted() {
    this.init();
  },
  methods: {
    // 初始化
    init() {
      this.$store.commit("SET_SCREEN", admin.getScreen());
      window.onresize = () => {
        setTimeout(() => {
          this.$store.commit("SET_SCREEN", admin.getScreen());
        }, 0);
      };
      this.$store.dispatch("FlowRoutes").then(() => {});
    },
    //打开菜单
    openMenu(item = {}) {
      this.$store.dispatch("GetMenu", item.id).then((data) => {
        if (data.length !== 0) {
          this.$router.$avueRouter.formatRoutes(data, true);
        }
      });
    },
    // 定时检测token
    refreshToken() {
      this.refreshTime = setInterval(() => {
        const token =
          getStore({
            name: "user-center-access-token",
            debug: true,
          }) || {};
        const date = calcDate(token.datetime, new Date().getTime());
        if (validatenull(date)) return;
        if (date.seconds >= this.website.tokenTime && !this.refreshLock) {
          this.refreshLock = true;
          this.$store
            .dispatch("refreshToken")
            .then(() => {
              this.refreshLock = false;
            })
            .catch(() => {
              this.refreshLock = false;
            });
        }
      }, 10000);
    },
  },
  beforeDestroy() {
    this.refreshTime && clearInterval(this.refreshTime);
    this.refreshTime = '';
  }
};
</script>

<style lang="scss" scoped>
.main {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  height: calc(100% - 33px);
  &-content {
    flex-grow: 1;
  }
  .avue-view{
    height: 100%;
  }
}
</style>
