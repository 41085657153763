import request from "@/router/axios";

export const getInspectionList = (params) => {
  return request({
    url: "/api/cloud-task/project-task/gladeGrass/taskPage",
    method: "get",
    params,
  });
};

export const getInspectionDetail = (params) => {
  return request({
    url: "/api/aos-inspection/gladegrassinspectionproject/detail",
    method: "get",
    params,
  });
};

export const submitInspectionrecords = (data) => {
  return request({
    url: "/api/aos-inspection/gladegrassinspectionproject/submit",
    method: "post",
    data,
  });
};

export const removeInspectionrecords = (params) => {
  return request({
    url: "/api/aos-inspection/gladegrassinspectionproject/remove",
    method: "post",
    params,
  });
};