var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "top-left" }, [
    _c("div", { staticClass: "top-left-box", on: { click: _vm.toHome } }, [
      _c("img", {
        staticClass: "logo",
        attrs: { src: "/img/businessLogo.png", alt: "" },
      }),
      _c("span", { staticClass: "title" }, [_vm._v(_vm._s(_vm.$t("title")))]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }