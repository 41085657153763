import request from "@/router/axios";

export const getAiIdentifyList= params => {
    return request({
        url: "/api/cloud-task/aiidentifyalarm/listByCurrentUser",
        method: "get",
        params
    });
};

export const submit= data => {
    return request({
        url: "/api/cloud-task/aiidentifyalarm/submit",
        method: "post",
        data
    });
};