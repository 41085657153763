import request from '@/router/axios';

export const getList = (current, size, params) => {
    return request({
        url: '/api/mission-alarm/page',
        method: 'post',
        params: {
            current,
            size,
        },
        data: {
            ...params,
        }
    })
}

export const download = (taskId) => {
    return request({
        url: '/api/cloud-task/mission-alarm/download/doc',
        method: 'get',
        responseType:'blob',
        params: {
            taskId,
        },
    })
}


