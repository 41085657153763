/**
 * 全站权限配置
 *
 */
import router from './router/router'
import store from './store'
import {validatenull} from '@/util/validate'
import {getToken} from '@/util/auth'
import NProgress from 'nprogress' // progress bar
import 'nprogress/nprogress.css' // progress bar style
NProgress.configure({showSpinner: false});
const lockPage = store.getters.website.lockPage; //锁屏页
const  whiteList = ['/no-login', '/screenLogin']

function getLoginPath() {
  // let routerPath = {path: '/login'};
  // let pram = new URLSearchParams(location.href.split('?')[1]);
  // let screenOil = pram.get("screenOil");
  // if (screenOil) {
  //   routerPath.query.screenOil = true;
  // }
  // return routerPath;
}

router.beforeEach((to, from, next) => {
  if (whiteList.indexOf(to.path) > -1) {
    next()
    return
  }
  if (getToken()) {
    if (to.path === '/404') {
      setTimeout(() => {
        next({path: from.path})
      }, 1000)
      return
    }
    //如果系统激活锁屏，全部跳转到锁屏页
    if (store.getters.isLock && to.path !== lockPage) {
      next({path: lockPage})
      return
    }
    //如果登录成功访问登录页跳转到主页
    if (to.path === '/login') {
      next({path: '/'})
      return
    }
    //如果用户信息为空则获取用户信息，获取用户信息失败，跳转到登录页
    if (store.getters.token.length === 0) {
      store.dispatch('FedLogOut').then(() => {
        next({path: '/login'})
      })
      return
    }
    next()
  } else {
    //判断是否需要认证，没有登录访问去登录页
    if (to.path === '/404') {
      setTimeout(() => {
        next({path: '/login'})
      }, 1000)
      return
    }
    if (to.path === '/login') {
      next()
      return
    }
    next('/login')
  }
})

router.afterEach(() => {
  NProgress.done();
  const currentRoute = router.currentRoute
  const leafRoute = currentRoute.matched[currentRoute.matched.length - 1]
  const title = leafRoute && leafRoute.meta? leafRoute.meta.title: window.global.title
  router.$avueRouter.setTitle(title);
});
