import request from "@/router/axios";

// 获取列表
export const getTreeList = (params) => {
  return request({
    url: "/api/group_element/treeList",
    method: "get",
    params
  });
};

// 获取文件夹list
export const getMapdirList = (params) => {
  return request({
    url: "/api/aos-gisservice/mapdir/list",
    method: "get",
    params
  })
};

// 获取标注、巡检对象等（有文件夹分类）
export const getLabelTreeList = (params) => {
  return request({
    url: "/api/aos-gisservice/mapdir/dirs",
    method: "get",
    params
  })
};

// 获取石油项目所有巡检对象（无文件夹分类）
export const getOilInspectionProjectObjects = (params) => {
  return request({
    url: "/api/aos-inspection/oilinspectionproject/getOilInspectionProjectObjects",
    method: "get",
    params
  })
};

// 获取飞行项目绑定的巡检对象（无文件夹分类）
export const getInspectionObjects = (params) => {
  return request({
    url: "/api/cloud-task/project-management/getInspectionObjects",
    method: "get",
    params
  })
};

// 获取飞行项目标注（无文件夹分类）
export const getMarkObjects = (params) => {
  return request({
    url: "/api/aos-gisservice/mapmark/getList",
    method: "post",
    params
  })
};

// 增加tree节点
export const addTreeNode = (data) => {
  return request({
    url: "/api/aos-gisservice/mapdir/submit",
    method: "post",
    data
  })
};

// 删除tree节点
export const deleteTreeNode = (params) => {
  return request({
    url: "/api/aos-gisservice/mapdir/removeDir",
    method: "post",
    params
  })
};

// 修改tree节点
export const updateTreeNode = (data) => {
  return request({
    url: "/api/aos-gisservice/mapdir/submit",
    method: "post",
    data
  })
};

// 隐藏tree节点
export const updateDirVisibility = (params) => {
  return request({
    url: "/api/aos-gisservice/mapdir/updateDirVisibility",
    method: "post",
    params
  })
};

// 新增文件夹
export const addGroup = (row) => {
  return request({
    url: "/api/group_element/add",
    method: "post",
    data: row,
  });
};


export const addLabel = (row)=>{
  return request({
    url: "/api/element/add",
    method: "post",
    data: row
  })
}

export const getExpand = (workSpaceId) => {
  return request({
    url: "/api/expand-node/detail",
    method: "get",
    params: {
      workSpaceId,
    },
  });
};

export const setExpand = (row)=>{
  return request({
    url: "/api/expand-node/save-or-update",
    method: "post",
    data: row
  })
}

export const removeGroup = (ids) => {
  return request({
    url: '/api/group_element/remove',
    method: 'delete',
    params: {
      ids,
    }
  })
}

export const removeNode = (ids) => {
  return request({
    url: '/api/element/remove',
    method: 'delete',
    params: {
      ids,
    }
  })
}