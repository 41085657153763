var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("transition", { attrs: { name: "nemeoftransition" } }, [
    _vm.AIvisible && _vm.total > 0
      ? _c(
          "div",
          { staticClass: "AINotification-main-box" },
          [
            _c("div", { staticClass: "AINotification-header" }, [
              _c("span", { staticClass: "AINotification-text" }, [
                _vm._v(
                  "\n        " +
                    _vm._s(_vm.$t("warning.dialogTitle")) +
                    "\n      "
                ),
              ]),
              _c("i", {
                staticClass: "icon el-icon-close",
                on: { click: _vm.close },
              }),
            ]),
            _c("div", { staticClass: "AINotification-content" }, [
              _c(
                "div",
                { staticClass: "AINotification-content-img-box" },
                [
                  _c("el-image", {
                    attrs: { src: _vm.imgUrl, "preview-src-list": _vm.imgUrls },
                  }),
                ],
                1
              ),
              _c("div", { staticClass: "AINotification-content-inner-box" }, [
                _c("div", { staticClass: "content-inner-item" }, [
                  _c("span", { staticClass: "inner-item-label" }, [
                    _vm._v(
                      "\n            " +
                        _vm._s(_vm.$t("warning.type")) +
                        _vm._s(_vm.$t("colonMark")) +
                        "\n          "
                    ),
                  ]),
                  _c("span", { staticClass: "inner-item-value type-line" }, [
                    _c("span", [_vm._v(_vm._s(_vm.current.finalTypeName))]),
                    _c(
                      "i",
                      {
                        staticClass: "el-icon-aim icon-location",
                        on: { click: _vm.locationTo },
                      },
                      [_c("span", { staticClass: "icon-center-dot" })]
                    ),
                  ]),
                ]),
                _c("div", { staticClass: "content-inner-item" }, [
                  _c("span", { staticClass: "inner-item-label" }, [
                    _vm._v(
                      "\n            " +
                        _vm._s(_vm.$t("FilghtProjectName")) +
                        _vm._s(_vm.$t("colonMark")) +
                        "\n          "
                    ),
                  ]),
                  _c("span", { staticClass: "inner-item-value" }, [
                    _vm._v(
                      "\n            " +
                        _vm._s(_vm.current.projectName) +
                        "\n          "
                    ),
                  ]),
                ]),
                _c("div", { staticClass: "content-inner-item" }, [
                  _c("span", { staticClass: "inner-item-label" }, [
                    _vm._v(
                      "\n            " +
                        _vm._s(_vm.$t("inspection.project")) +
                        _vm._s(_vm.$t("colonMark")) +
                        "\n          "
                    ),
                  ]),
                  _c("span", { staticClass: "inner-item-value" }, [
                    _vm._v(
                      "\n            " +
                        _vm._s(_vm.current.inspectProjectName) +
                        "\n          "
                    ),
                  ]),
                ]),
                _c("div", { staticClass: "content-inner-item" }, [
                  _c("span", { staticClass: "inner-item-label" }, [
                    _vm._v(
                      "\n            " +
                        _vm._s(_vm.$t("longitudeAndLatitude")) +
                        _vm._s(_vm.$t("colonMark")) +
                        "\n          "
                    ),
                  ]),
                  _c("span", { staticClass: "inner-item-value" }, [
                    _vm._v(
                      "\n            " +
                        _vm._s(
                          "\n                " +
                            (_vm.current.lon
                              ? _vm.current.lon.slice(0, 8)
                              : "--") +
                            ", \n                " +
                            (_vm.current.lat
                              ? _vm.current.lat.slice(0, 8)
                              : "--") +
                            "\n              "
                        ) +
                        "\n          "
                    ),
                  ]),
                ]),
                _c("div", { staticClass: "content-inner-item" }, [
                  _c("span", { staticClass: "inner-item-label" }, [
                    _vm._v(
                      "\n            " +
                        _vm._s(_vm.$t("warning.time")) +
                        _vm._s(_vm.$t("colonMark")) +
                        "\n          "
                    ),
                  ]),
                  _c("span", { staticClass: "inner-item-value" }, [
                    _vm._v(
                      "\n            " +
                        _vm._s(_vm.current.createTime) +
                        "\n          "
                    ),
                  ]),
                ]),
                _c("div", { staticClass: "content-inner-btns" }, [
                  _c(
                    "span",
                    { staticClass: "inner-btn", on: { click: _vm.ignore } },
                    [
                      _vm._v(
                        "\n            " +
                          _vm._s(_vm.$t("warning.ignore")) +
                          "\n          "
                      ),
                    ]
                  ),
                  _c(
                    "span",
                    { staticClass: "inner-btn", on: { click: _vm.amend } },
                    [
                      _vm._v(
                        "\n            " +
                          _vm._s(_vm.$t("warning.corrections")) +
                          "\n          "
                      ),
                    ]
                  ),
                  _c(
                    "span",
                    { staticClass: "inner-btn", on: { click: _vm.confirm } },
                    [
                      _vm._v(
                        "\n            " +
                          _vm._s(_vm.$t("warning.confirm")) +
                          "\n          "
                      ),
                    ]
                  ),
                ]),
              ]),
            ]),
            _c("div", { staticClass: "AINotification-footer" }, [
              _c(
                "div",
                [
                  _c(
                    "el-checkbox",
                    {
                      on: { change: _vm.checkedHandel },
                      model: {
                        value: _vm.checked,
                        callback: function ($$v) {
                          _vm.checked = $$v
                        },
                        expression: "checked",
                      },
                    },
                    [
                      _vm._v(
                        "\n          " +
                          _vm._s(_vm.$t("warning.doNotShowAgain")) +
                          "\n        "
                      ),
                    ]
                  ),
                ],
                1
              ),
              _c(
                "div",
                [
                  _c("el-pagination", {
                    staticClass: "AINotification-pagination",
                    attrs: {
                      small: "",
                      "page-size": 1,
                      "current-page": _vm.currentPage,
                      "pager-count": 5,
                      layout: "prev, pager, next, total",
                      total: _vm.total,
                    },
                    on: {
                      "update:currentPage": function ($event) {
                        _vm.currentPage = $event
                      },
                      "update:current-page": function ($event) {
                        _vm.currentPage = $event
                      },
                    },
                  }),
                ],
                1
              ),
            ]),
            _c(
              "el-dialog",
              {
                attrs: {
                  title: _vm.alarmTitle,
                  "append-to-body": "",
                  visible: _vm.alarmVisible,
                  width: "500px",
                  "before-close": _vm.handleAlarmClose,
                },
                on: {
                  "update:visible": function ($event) {
                    _vm.alarmVisible = $event
                  },
                },
              },
              [
                _c(
                  "el-form",
                  {
                    ref: "alarmForm",
                    staticClass: "demo-ruleForm",
                    attrs: {
                      model: _vm.alarmForm,
                      rules: _vm.rules,
                      "label-width": "auto",
                    },
                  },
                  [
                    _vm.alarmAction === "amend"
                      ? _c(
                          "el-form-item",
                          {
                            attrs: {
                              label: _vm.$t("warning.type") + "：",
                              prop: "finalTypeCode",
                            },
                          },
                          [
                            _c("select-tree", {
                              staticStyle: { width: "248px" },
                              attrs: {
                                data: _vm.alarmTypeList,
                                size: "small",
                                clearable: true,
                                placeholder: _vm.$t("selectPlease"),
                              },
                              model: {
                                value: _vm.alarmForm.finalTypeCode,
                                callback: function ($$v) {
                                  _vm.$set(_vm.alarmForm, "finalTypeCode", $$v)
                                },
                                expression: "alarmForm.finalTypeCode",
                              },
                            }),
                          ],
                          1
                        )
                      : _vm._e(),
                    _c(
                      "el-form-item",
                      {
                        attrs: {
                          label: _vm.$t("warning.level") + "：",
                          prop: "level",
                        },
                      },
                      [
                        _c("select-tree", {
                          staticStyle: { width: "248px" },
                          attrs: {
                            data: _vm.levelList,
                            size: "small",
                            clearable: true,
                            placeholder: _vm.$t("selectPlease"),
                          },
                          model: {
                            value: _vm.alarmForm.level,
                            callback: function ($$v) {
                              _vm.$set(_vm.alarmForm, "level", $$v)
                            },
                            expression: "alarmForm.level",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "span",
                  {
                    staticClass: "dialog-footer",
                    attrs: { slot: "footer" },
                    slot: "footer",
                  },
                  [
                    _c("el-button", { on: { click: _vm.handleAlarmClose } }, [
                      _vm._v(_vm._s(_vm.$t("btnCancel"))),
                    ]),
                    _c(
                      "el-button",
                      {
                        attrs: { type: "primary" },
                        on: { click: _vm.alarmSubmit },
                      },
                      [_vm._v(_vm._s(_vm.$t("btnOk")))]
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
          1
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }