import request from '@/router/axios';

export const getList = ( params) => {
  return request({
    url: '/api/media-file/page',
    method: 'get',
    params: {
      ...params,
    }
  })
}

export const makeDir = (data) => {
  return request({
      url: '/api/media-file/makeDir',
      method: 'post',
      // headers: {
      //     'Content-Type': 'application/x-www-form-urlencoded'
      // },
      data: data
  })
}

export const search = (params) => {
  return request({
      url: '/api/media-file/search',
      method: 'get',
      // headers: {
      //     'Content-Type': 'application/x-www-form-urlencoded'
      // },
      params
  })
}

export const upload = (data) => {
  return request({
      url: '/api/media-file/upload',
      method: 'post',
      headers: {
          'Content-Type': 'multipart/form-data'
      },
      timeout: 0,
      data: data
  })
}


export const remove = (ids) => {
  return request({
    url: '/api/media-file/remove',
    method: 'delete',
    params: {
      ids,
    }
  })
}

export const download = (params) => {
  return request({
      url: params.filePath,
      method: 'get',
      params,
      responseType: 'blob', // default
      timeout: 0,
  })
}

export const bucketSize = (params) => {
  return request({
      url: '/api/media-file/bucketSize',
      method: 'get',
      params
  })
}


