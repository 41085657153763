import request from '@/router/axios';

// 分页
export const getBrandList = (current, size = 9999, params) => {
  return request({
    url: '/api/cloud-equipment/manufacturer/page',
    method: 'get',
    params: {
      current,
      size,
      ...params,
    }
  })
}

// 详情
export const getDetail = (id) => {
  return request({
    url: '/api/cloud-equipment/manufacturer/detail',
    method: 'get',
    params: {
      id
    }
  })
}

// 删除
export const remove = (id) => {
  return request({
    url: '/api/cloud-equipment/manufacturer/remove',
    method: 'delete',
    params: {
      id,
    }
  })
}

// 新增
export const add = (row) => {
  return request({
    url: '/api/cloud-equipment/manufacturer/add',
    method: 'post',
    data: row
  })
}

// 修改
export const update = (row) => {
  return request({
    url: '/api/cloud-equipment/manufacturer/update',
    method: 'put',
    data: row
  })
}