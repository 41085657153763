// import Cookies from 'js-cookie'
const TokenKey = 'user-center-access-token'
const RefreshTokenKey = 'refreshToken'
const UserInfoKey = "user_info";
function isRemember() {
  //记住密码
  return localStorage.getItem("remember") === "true";
}
export function setToken(token) {
  const remember = isRemember();
  if (remember) {
    localStorage.setItem(TokenKey, token);
  } else {
    sessionStorage.setItem(TokenKey, token);
  }
}
export function setRefreshToken(freshToken) {
  const remember = isRemember();
  if (remember) {
    localStorage.setItem(RefreshTokenKey, freshToken);
  } else {
    sessionStorage.setItem(RefreshTokenKey, freshToken);
  }
}
export function getToken() {
  const remember = isRemember();
  if (remember) {
    return localStorage.getItem(TokenKey);
  }
  return sessionStorage.getItem(TokenKey);
}
export function getRefreshToken() {
  const remember = localStorage.getItem("remember");
  if (remember) {
    return localStorage.getItem(RefreshTokenKey);
  }
  return sessionStorage.getItem(RefreshTokenKey);
}
export function getUserInfo() {
  const remember = isRemember();
  if (remember) {
    return JSON.parse(localStorage.getItem(UserInfoKey));
  }
  return JSON.parse(sessionStorage.getItem(UserInfoKey));
}
export function clearStorage() {
  localStorage.removeItem("user-center-access-token");
  localStorage.removeItem("remember");
  localStorage.removeItem("user-center-tagList");
  localStorage.removeItem("user-center-refresh-token");
  localStorage.removeItem("refUserId");
  localStorage.removeItem("user-center-tag");
  localStorage.removeItem("user-center-userInfo");
  localStorage.removeItem("user-center-menuAll");
  localStorage.removeItem("user-center-menu");
  localStorage.removeItem("user-center-flowRoutes");
  localStorage.removeItem("user-center-permission");
  //session
  sessionStorage.removeItem("user-center-access-token");
  sessionStorage.removeItem("remember");
  sessionStorage.removeItem("user-center-refresh-token");
  sessionStorage.removeItem("user-center-tagList");
  sessionStorage.removeItem("refUserId");
  sessionStorage.removeItem("user-center-tag");
  sessionStorage.removeItem("user-center-userInfo");
  sessionStorage.removeItem("user-center-menuAll");
  sessionStorage.removeItem("user-center-menu");
  sessionStorage.removeItem("user-center-flowRoutes");
  sessionStorage.removeItem("user-center-permission");
}

