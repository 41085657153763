import request from '@/router/axios';
// 删除数据
export const remove = (ids) => {
    return request({
        url: '/api/alarm-pic/remove',
        method: 'delete',
        params: {
            ids,
        }
    })
}

// 获得详情
export const detail = (alarmId) => {
    return request({
        url: '/api/alarm-pic/detail',
        method: 'get',
        params: {
            alarmId,
        }
    })
}

// 获得ai报警数据/alarm-pic/page
export const getAlarmData = (page, size, params) => {
    return request({
        url: '/api/alarm-pic/page',
        method: 'get',
        params: {
            ...params,
            page,
            size
        }
    })
}

// 修改报警数据
export const updateAlarmData = (params) => {
    return request({
        url: '/api/alarm-pic/update',
        method: 'put',
        data: {
            ...params
        }
    })
}

// 新增报警数据  1
export const addAlarmData = (paramas) => {
    return request({
        url: '/api/alarm-pic/add',
        method: 'post',
        data: {
            ...paramas
        }
    })
}


// 上传报警截图
export const addScreen = (paramas) => {
    return request({
      url: "/api/cloud-task/alarm-pic/screen/add",
      method: "post",
      data: {
        ...paramas,
      },
    });
  };


