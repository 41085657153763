// 维护全局变量一定要写注释, 以后逐步补全
const getters = {
  tag: (state) => state.tags.tag,
  language: (state) => state.common.language,
  website: (state) => state.common.website,
  userInfo: (state) => state.user.userInfo,
  colorName: (state) => state.common.colorName,
  themeName: (state) => state.common.themeName,
  isShade: (state) => state.common.isShade,
  isCollapse: (state) => state.common.isCollapse,
  keyCollapse: (state, getters) => getters.screen > 1 ? getters.isCollapse : false,
  screen: (state) => state.common.screen,
  isLock: (state) => state.common.isLock,
  isFullScren: (state) => state.common.isFullScren,
  isMenu: (state) => state.common.isMenu,
  lockPasswd: (state) => state.common.lockPasswd,
  tagList: (state) => state.tags.tagList,
  tagWel: (state) => state.tags.tagWel,
  token: (state) => state.user.token,
  roles: (state) => state.user.roles,
  permission: (state) => state.user.permission,
  menu: (state) => state.user.menu,
  subMenu: (state) => state.user.subMenu,
  menuId: (state) => state.user.menuId,
  menuAll: (state) => state.user.menuAll,
  logsList: (state) => state.logs.logsList,
  logsLen: (state) => state.logs.logsList.length || 0,
  logsFlag: (state, getters) => getters.logsLen === 0,
  flowRoutes: (state) => state.dict.flowRoutes,
  dataUrl: (state) => state.map.dataUrl,
  homePoint: (state) => state.map.homePoint,
  placemarkList: (state) => state.map.placemarkList,
  projectPoint: (state) => state.map.projectPoint,
  handlerType: (state) => state.map.handlerType,
  routeId: (state) => state.map.routeId,
  labels: (state) => state.map.labels,
  newFeature: (state) => state.map.newFeature,
  // 航线
  routeDetail: (state) => state.route.routeDetail,
  routeVisible: (state) => state.route.routeVisible,

  // 标注颜色集合
  markColorList: (state) => state.map.markColorList,
  // 飞行中心数据
  map3d_project: (state) => state.map3d.map3d_project,
  // 林草巡检数据
  map3d_FG: (state) => state.map3d.map3d_FG,
  // 油气巡检数据
  map3d_OG: (state) => state.map3d.map3d_OG,
  // 应急中心数据
  map3d_ET: (state) => state.map3d.map3d_ET,
  // 需要编辑的项目数据目前飞行中心、林草巡检、油气巡检、应急中心都公用此缓存
  editProject: (state) => state.common.editProject,
  // 在列表页选择的当前项目的缓存，飞行中心、林草巡检、油气巡检、应急中心都公用此缓存
  selectProject: (state) => state.map.selectProject,
  // 报警左侧列表
  warningList: (state) => state.map.warningList,
  // 当前选择的报警点
  selectWarning: (state) => state.map.selectWarning,
  // 当前巡检记录
  selectInspection: (state)=> state.inspection.selectInspection,
  // 巡检纪录列表
  inspectionList: (state)=> state.inspection.inspectionList,
  // 当前选中应急记录
  selectEmergencyRecord: (state)=> state.map.selectEmergencyRecord,
  // 巡检应急记录列表
  emergencyRecordsList: (state)=> state.map.emergencyRecordsList,
  // 当前选中航线----飞行中心----航线管理
  selectRoute: (state)=> state.map.selectRoute,


  flylineLength: (state) => state.map.flylineLength,
  routeWebSocket: (state) => state.map.routeWebSocket,
  // 报警点详情信息，以后后台提供数据，暂时不删除
  showWarningDetialDialog: (state) => state.map.showWarningDetialDialog,
  // 大屏老方案的数据流
  wsTaskInfo: (state) => state.map.wsTaskInfo,
  // 大屏新方案的数据流
  newSocketData: (state) => state.newSocketData,
  // ？？？？？？？？？？  -。-
  socketData: (state) => state.socketData,
  // 大屏当前选择的正在执行的任务
  task: (state) => state.task,
  // ？？？？？？？？？？  -。-
  wsData: (state) => state.wsData,
  // 通过大屏的管理容器
  currentTaskMap: (state)=> state.screen.currentTaskMap,
  // 通过大屏当前选中正在执行的任务
  selectTask: (state)=> state.screen.selectTask,
  // 通过大屏当前正在执行的任务列表
  currentTaskList: (state)=> state.screen.currentTaskList,
  // 每当currentTaskMap的成员重要值有变化，itemForTaskMapChange改变，通知订阅者
  itemForTaskMapChange: (state)=> state.screen.itemForTaskMapChange,
  // 禁飞区
  noflySwitch: (state)=> state.map.noflySwitch,
  noflyCheckList: (state)=> state.map.noflyCheckList,
  noflyTypeValue: (state)=> state.map.noflyTypeValue
};
export default getters;
