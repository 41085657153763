import request from "@/router/axios";

export const getWeather = (params) => {
  return request({
    url: "/api/cloud-task/meteorology/getMeteorologyInfo",
    method: "get",
    params,
  });
};

// 获取ai报警chart图数据
export const getlineChartData = (params) => {
  return request({
    url: "/api/cloud-task/screen/ai-alarm-trend",
    method: "get",
    params,
  });
};

export const getFile = (orderId) => {
  return request({
    url: "/screen/alarm-data/word/demo1",
    method: "get",
    responseType: "blob",
    params: {
      orderId,
    },
  });
};

export const getScreenData = (params) => {
  return request({
    url: "/api/cloud-task/screen/screen-data",
    method: "get",
    params,
  });
};


