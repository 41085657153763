import request from "@/router/axios";

export const getNoFlyZoneList = params => {
    return request({
        url: "/api/aos-gisservice/flyzone/list",
        method: "get",
        params
    });
};

export const getNoFlyZoneDetail = params => {
    return request({
        url: "/api/aos-gisservice/flyzone/detail",
        method: "get",
        params
    });
};

export const deleteNoFlyZoneItem = params => {
    return request({
        url: "/api/aos-gisservice/flyzone/remove",
        method: "post",
        params
    });
};

export const addEditNoFlyZone = params => {
    return request({
        url: "/api/aos-gisservice/flyzone/submit",
        method: "post",
        data: params
    });
};

export const importNoFlyZone = params => {
    return request({
        url: "/api/aos-gisservice/flyzone/import",
        method: "post",
        data: params
    });
};