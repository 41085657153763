/**
 * 通用大屏专门维护变量, 以后重构其他大屏全部，使用此全局变量
*/
import Vue from "vue";
let vm = new Vue()
let screen = {
    state: {
        // 当前大屏项目
        screenProject: {},
        // 当前选中正在执行的任务
        selectTask: {},
        // 当前正在执行的任务
        currentTaskList: [],
        /**
         * map的元素设计
         *  key： 任务id
         *  value: {
         *      socket： 轨迹的websocket连接
         *      data： 轨迹数据
         *      videoUrl： 视屏地址
         *      icon: 无人机图标，
         *      selectIcon： 选中无人机图标
         * }
        */
        currentTaskMap: {},
        // map的承运中当有重要的值变化时，需要通知到对应的使用者
        itemForTaskMapChange: {}
    },
    actions: {
        // 设置大屏正在展示项目
        setScreenProject ({ commit }, value) {
            commit("SET_SCREEN_PROJECT", value);
        },
        // 当前选中的正在执行的任务
        setSelectTask ({ commit }, value) {
            commit("SET_SELECT_TASK", value);
        },
        // 设置正在执行的任务列表
        setCurrentTaskList ({ commit }, list) {
            commit("SET_CURRENT_TASK_LIST", list);
        },
        // 增加正在执行任务的map
        addCurrentTaskList ({ commit }, value) {
            try {
                var map = new CurrentTaskMap(value)
                commit("ADD_CURRENT_TASK", map);
            } catch (error) {
                console.warn(error);
            }
        },
        // 删除正在执行任务的map
        removeCurrentTaskList ({ commit }, key) {
            commit("REMOVE_CURRENT_TASK_BY_KEY", key);
        },
        // 清空map
        clearCurrentTaskList ({ commit }) {
            commit("CLEAR_CURRENT_TASK");
        },
        // 设置正在执行任务对应的轨迹数据
        setCurrentTaskDataByKey({ commit },data) {
            try {
                if (!data && !data.key && typeof data.key !== 'string') {
                    throw '请传检查参数格式{key: string, value: object}';
                }
                if (!data && !data.value && typeof data.value !== 'object') {
                    throw '请传检查参数格式{key: string, value: object}';
                }
                commit("SET_CURRENT_TASK_DATA_BY_KEY", data);
            } catch (error) {
                console.error(error);
            }
        }
    },
    mutations: {
        SET_SCREEN_PROJECT: (state, value) => {
            state.screenProject = value;
        },
        SET_SELECT_TASK: (state, value) => {
            state.selectTask = value;
        },
        SET_CURRENT_TASK_LIST: (state, list) => {
            state.currentTaskList = list;
        },
        ADD_CURRENT_TASK: (state, map) => {
            vm.$set(state.currentTaskMap, map.key, map.value)
        },
        REMOVE_CURRENT_TASK_BY_KEY: (state, key) => {
            vm.$delete(state.currentTaskMap, key)
        },
        CLEAR_CURRENT_TASK: (state) => {
            // 关闭websocket
            for (var key in state.currentTaskMap) {
                let item = state.currentTaskMap[key]
                if (item && item.socket && item.socket.close) {
                    item.socket.close()
                }
            }
            // 清空数据
            vm.$set(state, 'currentTaskMap',{})
        },
        SET_CURRENT_TASK_DATA_BY_KEY: (state, data) => {
            if (state.currentTaskMap[data.key]) {
                vm.$set(state.currentTaskMap[data.key], 'data', data.value)
                state.itemForTaskMapChange = {
                    key: data.key,
                    valueKey: 'data',
                    data: data.value,
                    airName: data.airName
                }
            }
        }
    }
};

class CurrentTaskMap {
    constructor(options) {
        try {
            if (!options && !options.key && typeof options.key !== 'string') {
                throw '请传检查参数格式{key: string, value: object}';
            }
            if (!options && !options.value && typeof options.value !== 'object') {
                throw '请传检查参数格式{key: string, value: object}';
            }
            this.key = options.key
            this.value = {}
            this.value.socket = options.value.socket
            this.value.data = options.value.data
            this.value.videoUrl = options.value.videoUrl
            this.value.icon = options.value.icon
            this.value.selectIcon = options.value.selectIcon
        }catch(err) {}
    }
}
  
export default screen;
  