const map3d = {
  state: {
    // cesium中index组件需要缓存数据-----飞行中心
    map3d_project: {
      // 飞行中心的项目列表。
      list: [],
      // 飞行中心项目详情。
      detail: {}
    },
    // cesium中index组件需要缓存数据-----林草巡检
    map3d_FG: {
      // 林草巡检的项目列表。
      list: [],
      // 林草巡检项目详情。
      detail: {}
    },
    // cesium中index组件需要缓存数据-----油气巡检
    map3d_OG: {
      // 油气巡检的项目列表。
      list: [],
      // 油气巡检项目详情。
      detail: {}
    },
    // cesium中index组件需要缓存数据-----应急中心
    map3d_ET: {
      // 应急中心的项目列表。
      list: [],
      // 应急中心项目详情。
      detail: {}
    }
  },
  actions: {
  },
  mutations: {
    // 飞行中心
    SET_MAP3D_PROJECT_LIST: (state, list) => {
      state.map3d_project.list = list
    },
    SET_MAP3D_PROJECT_DETAIL: (state, value) => {
      state.map3d_project.detail = value
    },
    // 林草巡检
    SET_MAP3D_FG_LIST: (state, list) => {
      state.map3d_FG.list = list
    },
    SET_MAP3D_FG_DETAIL: (state, value) => {
      state.map3d_FG.detail = value
    },
    // 油气巡检
    SET_MAP3D_OG_LIST: (state, list) => {
      state.map3d_OG.list = list
    },
    SET_MAP3D_OG_DETAIL: (state, value) => {
      state.map3d_OG.detail = value
    },
    // 应急中心
    SET_MAP3D_ET_LIST: (state, list) => {
      state.map3d_ET.list = list
    },
    SET_MAP3D_ET_DETAIL: (state, value) => {
      state.map3d_ET.detail = value
    }
  },
};

export default map3d;
