import request from '@/router/axios';

export const register = (data) => {
  return request({
    url: '/api/cloud-user/register',
    method: 'post',
    data
  })
}

export const findPassword = (data) => {
  return request({
    url: '/api/blade-user/find-password',
    method: 'post',
    data
  })
}

export const getSystemInfo = (data) => {
  return request({
    url: '/api/blade-system/getSystemInfo',
    method: 'get',
    data
  })
}


export const sendSmsCode = (params) => {
  return request({
    url: '/api/cloud-user/sendSmsCode',
    method: 'get',
    params
  })
}

export const bindPhone = (data) => {
  return request({
    url: '/api/cloud-user/bindPhone',
    method: 'put',
    data
  })
}
