<template>
    <el-select
        v-model="show"
        ref="select"
        :size="size"
        :multiple="multiple"
        :clearable="clearable"
        :disabled="disabled"
        class="aos-select-tree"
        @visible-change="visibleChange"
        @clear="clearSelect"
        @remove-tag="removeTag"
        :placeholder="placeholder">
        <el-option
            class="aos-select-tree-options"
            v-for="item in selectOptions"
            :key="item.value"
            :value="item.value"
            :label="item.label"
            style="padding: 5px"
            disabled>
        </el-option>
        <el-input
            v-if="showSearch"
            :size="size"
            :placeholder="$t('inputKeyworldForfilter')"
            v-model="filterText">
        </el-input>
        <el-tree
            :size="size"
            class="aos-select-tree-tree"
            :data="data"
            ref="tree"
            node-key="value"
            :filter-node-method="filterNode"
            :check-on-click-node="!multiple"
            :expand-on-click-node="!multiple"
            highlight-current
            @node-click="nodeClick">
            <div :class="multiple && Array.isArray(value) && value.indexOf(data.value) > -1? 'is-current': ''" class="aos-tree-slot-content" slot-scope="{node, data}">
                <span>{{ node.label }}</span>
                <i v-if="multiple && Array.isArray(value) && value.indexOf(data.value) > -1" class="el-icon-check"></i>
            </div>
        </el-tree>
    </el-select>
</template>

<script>
export default {
    name: 'SelectTree',
    model: {
        prop: "value",
        event: "change",
    },
    props: {
        value: [String, Number, Array],
        props: {
            type: Object,
            default(){
                return {}
            }
        },
        data: {
            type: Array,
            default() {
                return []
            }
        },
        multiple: false,
        clearable: false,
        size: {
            type: String,
            default() {
                return ''
            }
        },
        placeholder: {
            type: String,
            default() {
                return this.$t('selectPlease')
            }
        },
        disabled: {
            type: Boolean,
            default() {
                return false
            }
        },
        showSearch: {
            type: Boolean,
            default() {
                return true
            }
        }
    },
    data(){
        return {
            filterText: '',
            tmp: null,
        }
    },
    computed: {
        show: {
            get() {
                let value = this.value
                value = this.findLabel(value)
                return value
            },
            set(v) {
                this.value = v
            }
        },
        selectOptions() {
            let list = this.getOpitions(this.data)
            return list
        }
    },
    watch: {
      filterText(val) {
        this.$refs.tree.filter(val);
      },
      value() {
        this.$emit('change', this.value)
      }
    },
    methods: {
        nodeClick(node, data) {
            if (node.disabled) return;
            if (this.multiple) {
                if (!Array.isArray(this.value)) {
                        this.value = []
                }
                if (this.value.indexOf(node.value) === -1) {
                    this.value.push(node.value)
                }
            } else {
                this.value = node.value
                this.$refs.select.blur()
            } 
        },
        filterNode(value, data) {
            if (!value) return true;
            return data.label.indexOf(value) !== -1;
        },
        findLabel(value, arr) {
            let res = value
            let list = (arr && Array.isArray(arr))? arr: this.data
            for (let i = 0; i < list.length; i++) {
                let item = list[i]
                if (this.value === item.value ) {
                    res = item.label
                    break
                }
                if (item.children && Array.isArray(item.children)) {
                    res = this.findLabel(value, item.children)
                    if (res != value) {
                        break
                    }
                }
            }
            return res
        },
        getOpitions(tree) {
            let list = []
            for (var i = 0; i < tree.length; i++) {
                let item = tree[i]
                list.push({
                    label: item.label,
                    value: item.value
                })
                if (item.children && Array.isArray(item.children)) {
                    list = list.concat(this.getOpitions(item.children))
                }
            }
            return list
        },
        isEmpty(value) {
            try {
                const type = typeof value
                switch (type) {
                    case 'string':
                        return value === ''
                    case 'number':
                        return value === null
                    case 'object':
                        if (Array.isArray(value)) {
                            return value.length === 0
                        }
                        throw 'value的类型应该为string、array、number'
                    default:
                        throw 'value的类型应该为string、array、number'
                }
                
            } catch (error) {
                console.error(error);
            }
            
        },
        visibleChange(v) {
            if (v) {
                this.tmp = this.cloneValue(this.value)
            }else {
                if (!this.equation(this.tmp, this.value)) {
                    this.$emit('select-change', this.value)
                }
                this.tmp = null
            }
        },
        clearSelect() {
            this.$emit('clear')
        },
        removeTag(v) {
            this.$emit('remove-tag', v)
        },
        cloneValue(value) {
            let res;
            if (Array.isArray(value)) {
                res = value.map(item=>item)
            } else {
                res = value
            }
            return res
        },
        equation(a, b) {
            if (Array.isArray(a) && Array.isArray(b)) {
                if (a.length !== b.length) {
                    return false
                }
                for (let i = 0; i < a.length; i++) {
                    const item = a[i];
                    if (item !== b[i]) {
                        return false
                    }
                }
                return true
            } else {
                return a === b
            }
        }
    }
}
</script>
<style lang="scss">
.aos-select-tree-options{
    display: none;
}
.aos-select-tree-tree {
    .el-tree-node__content{
        width: 100%;
        .aos-tree-slot-content {
            flex-grow: 1;
            display: flex;
            font-size: 14px;
        }
        .aos-tree-slot-content.is-current{
            color: #409eff;
        }
    }
}
</style>
