import request from "@/router/axios";

export const getEquipList = (current, size, params) => {
  return request({
    url: "/api/cloud-equipment/equipment-management/page",
    method: "get",
    params: {
      ...params,
      current,
      size,
    },
  });
};

export const getDetail = (id) => {
  return request({
    url: "/api/cloud-equipment/equipment-management/detail",
    method: "get",
    params: {
      id,
    },
  });
};

export const remove = (ids) => {
  return request({
    url: "/api/cloud-equipment/equipment-management/remove",
    method: "delete",
    params: {
      ids,
    },
  });
};

export const add = (row) => {
  return request({
    url: "/api/cloud-equipment/equipment-management/add",
    method: "post",
    data: row,
  });
};

export const update = (row) => {
  return request({
    url: "/api/cloud-equipment/equipment-management/update",
    method: "put",
    data: row,
  });
};

export const getEntity = (params) => {
  return request({
    url: "/api/aos-slabentities/entitymodel/getEmBySign",
    method: "get",
    params,
  });
};

// 查询是不是飞机
export const getAirplaneType = () => {
  return request({
    url: "/api/blade-system/dict/dictionary?code=airplane_type",
    method: "get",
  });
};

export const getequipmentModel = (code) => {
  return request({
    url: "/api/blade-system/dict-biz/dictionary",
    method: "get",
    params:{
      code
    }
  });
};
