import request from "@/router/axios";

export const getList = (current, size, params) => {
  return request({
    url: "/api/cloud-task/project-task/page",
    method: "get",
    params: {
      ...params,
      current,
      size,
    },
  });
};

export const getDetail = (id) => {
  return request({
    url: "/api/missionPlan/detail",
    method: "get",
    params: {
      id,
    },
  });
};

export const remove = (id) => {
  return request({
    url: "/api/missionPlan/remove",
    method: "delete",
    params: {
      id,
    },
  });
};

export const add = (row) => {
  return request({
    url: "/api/missionPlan/add",
    method: "post",
    data: row,
  });
};

export const update = (row) => {
  return request({
    url: "/api/missionPlan/edit",
    method: "post",
    data: row,
  });
};

export const startMission = (id) => {
  return request({
    url: "/api/missionPlan/startMission",
    method: "get",
    params: {
      id,
    },
  });
};
export const stopMission = (id) => {
  return request({
    url: "/api/missionPlan/stopMission",
    method: "get",
    params: {
      id,
    },
  });
};

export const getFlyName = (projectId) => {
  return request({
    url: "/api/item-manager-config/fly-list",
    method: "get",
    params: {
      projectId
    }
  })
}

export const getHistoryVideo = (id) => {
  return request({
    url: "/api/missionPlan/get_history_video",
    method: "get",
    params: {
      id
    }
  })
}