import request from "@/router/axios";

export const getHangarByManufacturerId = params => {
    return request({
        url: "/api/cloud-equipment/uavhangar/selectListByManufacturerId",
        method: "get",
        params
    });
};

export const getHangarPage = params => {
    return request({
        url: "/api/cloud-equipment/uavhangar/page",
        method: "get",
        params
    });
};

export const getHangarList = (data)=> {
    return request({
        url: "/api/cloud-equipment/uavhangar/list",
        method: "post",
        data
    });
};

// 获取品牌下的航线列表
export const selectRouteListByManufacturerId = (params)=> {
    return request({
        url: "/api/aos-gisservice/routemanageline/selectListByManufacturerId",
        method: "get",
        params
    });
};

// uver航迹列表
export const getUverRouteList = (params)=> {
    return request({
        url: "/api/cloud-task/uver/task/track/list",
        method: "get",
        params
    });
};

export const getEquipmentTypeList = params => {
    return request({
        url: "/api/blade-system/dict-biz/getDictBizByParentKey",
        method: "get",
        params
    });
}

export const uploadFile = params => {
    return request({
        url: "/api/blade-resource/oss/endpoint/upload-file",
        method: "post",
        data: params
    });
}

export const changeHangarVisibility = params => {
    return request({
        url: "/api/cloud-equipment/uavhangar/updateVisibility",
        method: "post",
        params
    });
}

export const getHangarDetail = params => {
    return request({
        url: "/api/cloud-equipment/uavhangar/detail",
        method: "get",
        params
    });
};

export const deleteHangarItem = params => {
    return request({
        url: "/api/cloud-equipment/uavhangar/remove",
        method: "post",
        params
    });
};

export const addEditHangar = params => {
    return request({
        url: "/api/cloud-equipment/uavhangar/submit",
        method: "post",
        data: params
    });
};

export const getHangarPlayStream = params => {
    return request({
        url: "/api/cloud-equipment/uavhangar/getPlayStream",
        method: "get",
        params
    });
};

export const getHangarScheTaskList = params => {
    return request({
        url: "/api/cloud-task/project-task/getHangarScheTaskList",
        method: "get",
        params
    });
}

// 机库操控
export const hangarOperate = params => {
    return request({
        url: "/api/cloud-equipment/uavhangar/hangarControl",
        method: "post",
        data: params
    });
}