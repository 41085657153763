import Vue from "vue";
import Vuex from "vuex";
import user from "./modules/user";
import common from "./modules/common";
import tags from "./modules/tags";
import logs from "./modules/logs";
import dict from "./modules/dict";
import map from "./modules/map";
import route from "./modules/route";
import inspection from "./modules/inspection"
// import airline from "./modules/airline";
import getters from "./getters";
import screen from "./modules/screen"
import map3d from "./modules/map3d"
import { getScreenData } from "@/api/screen/index.js";
Vue.use(Vuex);
const store = new Vuex.Store({
  state: {
    // 老方案下的ws数据
    wsData: {
      equipmentStatistics: {},
      alarmDataList: [],
      todayStaticsAlarmDataVOList: [],
      uavData: {},
      uavMap: {},
      uavTaskList: [],
      onDuty: {},
      videoUrlGroup: {
        videoUrl: null,
      },
    },

    // 新方案下的ws数据
    newWsData: {
      equipmentStatistics: {},
      alarmDataList: [],
      todayStaticsAlarmDataVOList: [],
      uavData: {},
      uavMap: {},
      uavTaskList: [],
      onDuty: {},
      videoUrlGroup: {
        videoUrl: null,
      },
    },

    videoUrl: '',
    lastChoiceTask:'',

    // 老方案下socketData
    socketData: {
      type: "",
      data: {},
    },

    // 新方案下socketData
    newSocketData: {
      type: "",
      data: {},
    },

    // 存放当前执行任务数据(大屏使用)，可判断新老方案
    task: {},

    // 通用视频正在执行任务的无人机管理容器：
    TaskMapUAV: {}
  },
  actions: {
    GetScreenData({ commit }, params) {
      return new Promise((resolve, reject) => {
        getScreenData(params)
          .then((res) => {
            commit("SET_SCREEN_DATA", res.data.data);
            commit("SET_SCREEN_VIDEOURL", res.data.data.uavDataDTO.videoUrl);
            resolve();
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
  },
  mutations: {
    SET_SCREEN_DATA: (state, data) => {
      state.wsData = data;
    },
    SET_SCREEN_VIDEOURL: (state, data) => {
      state.videoUrl = data;
    },
    SET_LAST_CHOICE_TASK: (state, data) => {
      state.lastChoiceTask = data;
    },
    SET_GLOBAL_DATA(state, data) {
      state.socketData = data;
    },
    CLEAR_GLOBAL_DATA(state) {
      state.wsData = {
        equipmentStatistics: {},
        alarmDataList: [],
        todayStaticsAlarmDataVOList: [],
        uavData: {},
        uavMap: {},
        uavTaskList: [],
        onDuty: {},
        lastChoiceTask: '',
        videoUrlGroup: {
          videoUrl: null,
        },
      };
    },
    SET_NEW_GLOBAL_DATA (state, data) {
      state.newSocketData = data
    },
    CLEAR_NEW_GLOBAL_DATA(state) {
      state.newWsData = {
        equipmentStatistics: {},
        alarmDataList: [],
        todayStaticsAlarmDataVOList: [],
        uavData: {},
        uavMap: {},
        uavTaskList: [],
        onDuty: {},
        lastChoiceTask: '',
        videoUrlGroup: {
          videoUrl: null,
        },
      }
    },
    SET_TASK (state, data) {
      state.task = data
    },
    SET_TASK_MAP_UAV(state, data) {
      state.TaskMapUAV = data
    }
  },
  modules: {
    user,
    common,
    logs,
    tags,
    dict,
    map,
    route,
    inspection,
    screen,
    map3d
    // airline,
  },
  getters,
});

export default store;
