<template>
  <div id="app">
    <loading-view v-if="loading" />
    <router-view v-else />
    <ai-notification/>
  </div>
</template>

<script>
import loadingView from '@/components/loadingview/index.vue';
import aiNotification from '@/components/notification/index.vue';

const configTypeList = [
  {envKey: 'development', configKey: 'Dev'},
  {envKey: 'test', configKey: 'Uat'},
  {envKey: 'production', configKey: 'Pro'},
  {envKey: 'pri', configKey: 'Pri'}
];

export default {
  name: "app",
  components: { 
    loadingView,
    aiNotification
  },
  data() {
    return {
      loading: true,
    }
  },
  created() {
    this.loadConfigFile(); // 初始化动态加载对应配置config文件
  },
  mounted() {
  },
  computed: {},
  methods: {
    // 动态加载config配置文件
    loadConfigFile() {
      let configItem = configTypeList.find(t => t.envKey === process.env.NODE_ENV);
      console.log(`${configItem.configKey}环境`);
      let configKey = configItem.configKey || 'Dev';
      let oScript = document.createElement("script");
      oScript.setAttribute("type", "text/javascript");
      oScript.setAttribute("src", `./config${configKey}.js`);
      let head = document.head || document.getElementsByTagName("head")[0];
      head.appendChild(oScript);
      oScript.onload = () => {
        setTimeout(() => {
          this.loading = false;
        }, 300);
      }
    }
  }
};
</script>

<style lang="scss">
#app {
  width: 100%;
  height: 100%;
  overflow: auto;
}
</style>
