import request from '@/router/axios';

// 获取项目列表
export const getEmergencytaskPage = (params) => {
  return request({
    url: '/api/aos-inspection/emergencytask/page',
    method: 'get',
    params
  })
}

// 通过id获取项目详情
export const getEmergencytaskDetail = (params) => {
  return request({
    url: '/api/aos-inspection/emergencytask/detail',
    method: 'get',
    params
  })
}

// 删除项目
export const deleteEmergencytask = (params) => {
  return request({
    url: "/api/aos-inspection/emergencytask/remove",
    method: "post",
    params
  });
};

// 新增项目
export const updateEmergencytask = (data) => {
  return request({
    url: "/api/aos-inspection/emergencytask/submit",
    method: "post",
    data,
  });
};

//配置大屏
export const screenTitleSet = (data) => {
  return request({
    url: "/api/aos-inspection/emergencytask/screenTitleSet",
    method: "put",
    data,
  });
};

// 获取字典
export const getDictList = (params) => {
  return request({
    url: "/api/blade-system/dict/dictionary",
    method: "get",
    params,
  });
};

// 获取人员列表
export const getUserList = (params) => {
  return request({
    url: '/api/item-manager-config/user',
    method: 'get',
    params
  })
}

// 获取角色权限
export const getRoleList = (params) => {
  return request({
    url: "/api/blade-system/role/list",
    method: "get",
    params,
  });
};


// 获取设备列表
export const getEquipList = (params) => {
  return request({
    url: "/api/item-manager-config/page-equipment",
    method: "get",
    params,
  });
};



// 获取应急记录列表
export const getEmergencyRecordPage = (params) => {
  return request({
    url: '/api/aos-inspection/emergencytaskrecord/page',
    method: 'get',
    params
  })
}

// 通过id获取应急记录详情
export const getEmergencyRecordDetail = (params) => {
  return request({
    url: '/api/aos-inspection/emergencytaskrecord/detail',
    method: 'get',
    params
  })
}

// 删除应急记录
export const deleteEmergencyRecord = (params) => {
  return request({
    url: "/api/aos-inspection/emergencytaskrecord/remove",
    method: "post",
    params
  });
};

// 新增应急记录
export const updateEmergencyRecord = (data) => {
  return request({
    url: "/api/aos-inspection/emergencytaskrecord/submit",
    method: "post",
    data,
  });
}