export default {
  tip: '提示',
  title: '业务中心',
  logoutTip: '退出系统, 是否继续?',
  submitText: '确定',
  cancelText: '取消',
  requestErrorText: '请求失败',
  requestSuccessText: '请求成功',
  openText: '开启',
  closeText: '关闭',
  correct: '是',
  deny: '否',
  idle: '空闲',
  working: '正在工作',
  search: '请输入搜索内容',
  inputPlease: '请输入内容',
  selectPlease: '请选择内容',
  uploadPlease: '请上传文件',
  inputKeyworldForfilter: '输入关键字进行过滤',
  menuTip: '没有发现菜单',
  longitude: '经度',
  latitude: '纬度',
  chooseFile: '选择文件',
  file: '文件',
  folder: '文件夹',
  file: '文件',
  duration: '时长',
  area: '面积',
  folderName: '文件夹名称',
  uploadText: '上传',
  type: '类型',
  contactPerson: '联系人',
  contactTelephone: '联系电话',
  name: '名称',
  loading: '加载中',
  resourceLoading: '正在加载资源',
  pleaseHoldOn: '请耐心等候',
  objectType: '对象类型',
  projectBelong: '所属项目',
  taskBelong: '所属任务',
  startTime: '开始时间',
  endTime: '结束时间',
  notes: '备注',
  screenShot: '截屏',
  allType: '所有类型',
  allProjects: '所有项目',
  allTasks: '所有任务',
  has: '有',
  hasNo: '无',
  operationSuccessful: '操作成功',
  currentMode: '当前模式',
  CurrentBatteryLevel: '当前电量',
  unlockingSignal: '解锁信号',
  screenMode: '画面模式',
  videoAzimuth: '吊舱方位角',
  videoPitch: '吊舱俯仰角',
  speed: '速度',
  height:'高度',
  Homedist: '距home点',
  RealTimeMileage: '实时里程',
  FilghtProjectName: '飞行项目',
  FlightTime: '飞行时间',
  FilghtTask: '飞行任务',
  WaitingForVideoTransmission: '等待视频传输中...',
  Monday: '星期一',
  Tuesday: '星期二',
  Wednesday: '星期三',
  Thursday: '星期四',
  Friday: '星期五',
  Saturday: '星期六',
  Sunday: '星期日',
  unlocked: '已解锁',
  locked: '已加锁',
  gs: '地速',
  refresh: '刷新',
  UAVFootage: '吊舱视频',
  operationSuccessfully: '操作成功',
  LatitudeLongitudeNotObtained: '未获取到经纬度，请检查航线',
  videoSource: '视频源',
  noVideoUrl: '暂无历史视频地址!',
  downLoadVideo: '视频下载',
  current: '当前',
  color: "颜色",
  icon: "图标",
  btnAdd: '新 增',
  btnDelete: '删 除',
  btnEdit: '编 辑',
  btnEnd: '结 束',
  btnOk: '确 定',
  btnCancel: '取 消',
  btnExport: '导 出',
  btnClose: '关 闭',
  searchBtn: '搜 索',
  emptyBtnText: '清 空',
  updateTime: '更新时间',
  remark: '备注',
  enterProject: '进入项目',
  manage: '管理',
  addProject: '添加项目',
  editProject: '编辑项目',
  longitudeAndLatitude: '经纬度',
  eventType: '事件类型',
  eventLevel: '事件等级',
  eventTypeName: '事件名称',
  save: '保存',
  addTask: '新增任务',
  editTask: '编辑任务',
  copyTask: '复制任务',
  export: '导出',
  to: '至',
  baseInfo: '基础信息',
  fileInfo: '文件信息',
  uploadFile: '上传文件',
  Upload: '请上传',
  Import: '导入',
  execute: '执行',
  index: '序号',
  view: '查看',
  edit: '编辑',
  delete: '删除',
  saveSuccessTip: '保存成功!',
  saveErrorTip: '保存失败!',
  deleteSuccessTip: '删除成功!',
  deleteErrorTip: '删除失败!',
  clickToUpload: '点击上传',
  questionMark: '？',
  colonMark: '：',
  ConfirmToDelete: '确定删除',
  videoFile: '视频文件',
  checkAll: '全选',
  downLoadAll: '批量下载',
  department: '所属团体',
  needToDelete: '确定将选择数据删除',
  executor: '执行人',
  department: '所属部门',
  comma: '，',
  // crud
  crud: {
    searchBtn: '搜索',
    emptyBtnText: '清空'
  },
  wel: {
    info: '早安！',
    dept: 'Aossci研发',
    team: '团队内排名',
    project: '项目访问',
    count: '项目数',
    data: {
      subtitle: '实时',
      column1: '分类统计',
      column2: '附件统计',
      column3: '文章统计',
      key1: '分',
      key2: '附',
      key3: '评',
      text1: '当前分类总记录数',
      text2: '当前上传的附件数',
      text3: '评论次数'
    },
    data2: {
      column1: '今日注册',
      column2: '今日登录',
      column3: '今日订阅',
      column4: '今日评论'
    },
    data3: {
      column1: '转化率（日同比 28%）',
      column2: '签到率（日同比 11%）',
      column3: '签到率（日同比 11%）'
    },
    data4: {
      column1: '错误日志',
      column2: '数据展示',
      column3: '权限管理',
      column4: '用户管理'
    },
    table: {
      rw: '工作任务',
      nr: '工作内容',
      sj: '工作时间'
    }
  },
  // 视频直播
  live: {
    warningType: '报警类型',
    chooseWarningType: '请选择报警类型'
  },

  route: {
    info: '个人信息',
    website: 'Aossci Admin',
    avuexwebsite: 'Aossci Admin',
    dashboard: '首页',
    more: '更多',
    tags: '标签',
    store: '本地存储',
    api: '全局函数',
    logs: '日志监控',
    table: '表格',
    form: '表单',
    top: '返回顶部',
    data: '数据展示',
    permission: '权限',
    error: '异常页面',
    test: '测试页面'
  },

  login: {
    title: '登录 ',
    info: 'Aossci后台管理系统',
    tenantId: '请输入租户ID',
    username: '请输入账号',
    password: '请输入密码',
    wechat: '微信',
    qq: 'QQ',
    github: 'github',
    gitee: '码云',
    phone: '请输入手机号',
    code: '请输入验证码',
    submit: '登录',
    userLogin: '账号密码登录',
    phoneLogin: '手机号登录',
    thirdLogin: '第三方系统登录',
    msgText: '发送验证码',
    msgSuccess: '秒后重发',
  },

  navbar: {
    info: 'info',
    logOut: '退出登录',
    userinfo: '个人信息',
    dashboard: '首页',
    lock: '锁屏',
    bug: '没有错误日志',
    bugs: '条错误日志',
    screenfullF: '退出全屏',
    screenfull: '全屏',
    language: '中英文',
    notice: '消息通知',
    theme: '主题',
    color: '换色'
  },

  tagsView: {
    search: '搜索',
    menu: '更多',
    clearCache: '清除缓存',
    closeOthers: '关闭其它',
    closeAll: '关闭所有'
  },

  userinfo:{
    head: '头像',
    headTip: '只能上传jpg/png用户头像，且不超过500kb',
    name: '姓名',
    username:'用户名',
    mobile: '手机号',
    email: '邮箱',
    editPwd: '修改密码',
    oldPwd: '原密码',
    newPwd: '新密码',
    confirmPwd: '确认密码'
  },
  
  //用户中心
  user: {
    emergencyranks: {
      name: '队伍名称',
      parentName: '上级队伍名称',
      memberNames: '队伍成员',
      memberNumber: '成员数量',
      leaderName: '负责人',
      remark: '队伍描述',
      eventType: '时间类型'
    }
  },

  // 飞行中心
  mission: {
    MissionDetails: '任务详情',
    taskType: '任务类型',
    taskCycle: '任务周期',
    flightMissionDetails: '飞行任务详情',
    name: '任务名称',
    device: '使用设备',
    team: '所属团队',
    operator: '执行飞手',
    history: '历史轨迹',
    flightInformation: '飞行信息',
    press: '按下',
    forScreenshot: '快捷截屏',
    route: '航线',
    missionRoute: '任务航线',
    chooseTaskCycle: '请选择任务周期',
    usingRoute: '使用航线',
    hangarTask: '机库任务',
    hangarTaskType: '机库任务类型',
    executionTime: '执行时间',
    cycleByClick: '请点击“选择日期”按钮',
    choseDate: '选择日期',
    usingHangar: '使用机库',
    usingPod: '使用载荷',
    usingDataLink: '使用数据链',
    brand: '选择品牌',
    usingUAV: '使用飞机',
    pleaseUsingHanger: '请选择“使用机库”',
    cycleTaskFailure: '周期任务列表获取失败',
    executeTask: '任务已开始执行',
    businessType: '业务分类',
    uploadFileLimitText1: '当前限制选择',
    uploadFileLimitText2: '个文件，本次选择了',
    uploadFileLimitText3: '个文件，共选择了',
    uploadFileLimitText4: '个文件',
    uploadVideo: '上传视频',
    uploadVideoDesc: '支持格式：mp4, flv。大小不超过2GB。',
    uploadRoute: '上传航线',
    uploadRouteDesc: '支持格式：kml, geojson。',
    ImageSizeLimit: '大小限制：5MB',
    uploadOperationArea: '上传作业区',
    uploadOperationAreaDesc: '支持格式：kml, geojson。',
    aerocraft: '飞行器',
    creator: '创建人',
    taskReplay: '任务回放',
    taskRecord: '补录任务',
    planRealTime: '计划/实际执行时间',
    taskCode: '任务编号',
    hangarTask: '机库任务',
    viewRoute: '查 看',
    selectEquipment: '选择设备',
    failNotice: '任务执行失败'
  },

  // 标注
  mark: {
    mark: '标注',
    info: '标注信息',
    MarkPoint: '点',
    MarkLine: '线',
    MarkPolygon: '面',
    horizontalDistance: '水平距离',
    distance: "直线距离",
    horizontalPerimeter: '水平周长',
    horizontalArea: '水平面积',
    icon: '标注图标',
    pointDesc: '系统预置了不同行业类型的图标，方便在地图展示。',
    coordinatePoint: '坐标点',
    firePoint: '火点',
    searchByName: '按标注名称搜索',
    upload: '导入标注',
    type: '标注类型'
  },

  //巡检
  inspection: {
    project: "巡检项目",
    selfName: '巡检对象',
    info: '巡检对象信息',
    type: '巡检对象类型',
    baseInfo: '基本信息',
    survey: '测量数据',
    horizontal_Distance: '水平距离',
    createBy: '绘制者',
    oilWell: '油井',
    oilLine: '石油管线',
    oilRoad: '油田公路',
    oilStorageTank: '石油储罐',
    oilStationStorage: '油气站库',
    oilPowerLine:'电力线路',
    oilMeasurementStation:'计量站',
    oilPowerEquipment: '电力设施',
    oilGasWell: '石油气井',
    PipelineLength: '管线长度',
    pointInfo: '端点信息',
    uploadInspection: '导入巡检对象',
    uploadKML: '请上传kml文件',
    possessionUnit: '属地单位',
    meteringstation: '计量站',
    waterRoom: '配水间',
    record: '巡检记录',
    exportRecords: '导出巡检报告',
    advancedSetting: '高级设置',
    intoMessage1: '进入对象',
    intoMessage2: '米范围开始巡检',
    outtoMessage1: '脱离对象范围',
    outtoMessage2: '秒后结束巡检',
    useToAll: '应用到所有',
    name: '记录名称',
    device: '巡检设备',
    duration: '巡检时长',
    hasWarning: '有无报警',
    editRecord: '编辑巡检记录',
    cannotdeleted: '管线节点数量小于2，不能删除',
    inspectionPhotos: '巡检照片'
  },

  // 报警
  warning: {
    type: '报警类型',
    correctionType: '更正前类型',
    place: '报警地点',
    alarmLevel: '报警级别',
    device: '报警设备',
    time: '报警时间',
    photo: '报警照片',
    video: '报警视频',
    manualAlarm: '人工报警',
    AIalarm: 'AI报警',
    // ai报警状态
    ignore: '忽略',
    corrections: '更正',
    confirm: '确认',
    untreated: '未处理',
    confirmed: '已确认',
    corrected: '已更正',
    neglected: '已忽略',
    dialogTitle: 'AI报警信息',
    doNotShowAgain: '本次登录不再提示',
    levelOne: '一级',
    levelTwo: '二级',
    levelThree: '三级',
    levelFour: '四级',
    correctAlarm: '更正报警',
    confirmAlarm: '确认报警',
    level: '报警级别',
    confirmWarning: '确认报警',
    amendWarning: '更正报警'
  },

  map: {
    coordinateSystem: '坐标系'
  },

  // 大屏
  screen: {
    largeScreen: '项目大屏',
    mainTitle: '主标题',
    subtitle: '副标题',
    largeScreenTemplate: '大屏模板',
    teamLeader: '带班领导',
    dutyOfficer: '值班主任',
    dutyDispatch: '值班调度',
    dutySectionChief: '值班科长',
    dutyCommunication: '值班通讯',
    dutyDriver: '值班司机',
    dutyPersonnel: '值班人员',
    communicationPhone: '通讯电话',
    viewLargeScreen: '查看大屏',
    selecteTemplate: '选择大屏模板',
    UAVMap: '无人机飞行地图',
    UAVFootage: '无人机画面',
    UAVInfo: '无人机信息',
    goback: '管理后台',
    realMap: '实时地图',
    realVideo: '实时视频',
    currentFootage: '当前画面',
    taskInfo: '任务信息',
    UAVModel: '无人机型号',
    UAVNumber: '无人机编号'
  },

  // 石油巡检大屏
  oilGasScreen: {
    screenTitle: '无人机智巡智检系统',
    exit: '退出',
    daily: '按日',
    weekly: '按周',
    monthly: '按月',
    today: '今天',
    theWeek: '本周',
    theMonth: '本月',
    noData: '暂无数据...',
    // 全局概况
    globalOverview: '全局概况',
    deviceOverview: '设备信息概况',
    more: '更多',
    inLineDevice: '在线设备',
    devicesTotal: '设备总数',
    fixedWing: '固定翼',
    multiRotors: '多旋翼',
    sets: '台',
    hangar: '机库',
    pcs: '个',
    uavFlightStatistics: '无人机飞行统计',
    sortie: '架次',
    duration: '时长',
    mileage: '里程',
    historySortie: '历史架次概况',
    podOverview: '吊舱概况',
    pleaseSelectDrone: '请选择无人机',
    pipeInspection: '管线巡检里程统计',
    oilWellInspection: '油井巡检次数统计',
    realTimeAlarm: '实时报警',
    uavLedger: '无人机台账',
    brand: '品牌',
    droneName: '无人机名称',
    droneType: '无人机类型',
    droneModel: '无人机型号',
    belongingAirport: '所属机场',
    state: '状态',
    pleaseSelectState: '请选择状态',
    alarmSearch: '高级筛选',
    threeDay: '近3天',
    oneWeek: '近1周',
    oneMonth: '近1月',
    custom: '自定义',
    // 实时任务
    realTimeTask: '实时任务',
    // 巡检记录
    inspectionRecord: '巡检记录',
    downloadReport: '下载巡检报告',
    date: '报告日期',
    project: '巡检项目',
    downloadLoadingText: '正在下载记录，请稍候！',
    typeStatistics: '对象类型统计',
    abnormalRanking: '巡检对象异常排名',
    pleaseSelectInspectionItem: '请选择巡检项目',
    inspectionRecords: '巡检记录统计',
    inspectionDuration: '巡检时长统计',
    inspectionTrend: '巡检趋势',
    // 报警记录
    alarmRecord: '报警记录',
    pleaseSelectAlarmType: '请选择报警类型',
    factoryAlarm: '厂处报警统计',
    objectAlarm: '巡检对象报警统计',
    alarmType: '报警类型统计',
    alarmTrend: '报警趋势图',
    alarmTypes: '报警类型',
    time: '时间',
    uavName: '无人机',
    latLon: '经纬度',
    noProject: '巡检项目错误！',
    noAlarmType: '报警类型错误！',
    noTime: '时间范围错误！',
    // 报警信息弹窗
    alarmInfo: '报警信息',
    alarmTypeInfo: '报警类型',
    alarmLocationInfo: '报警地点',
    alarmDeviceInfo: '报警设备',
    flightProjectInfo: '飞行项目',
    flightTaskInfo: '飞行任务',
    inspectionProject: '巡检项目',
    inspectionObject: '巡检对象',
    alarmTimeInfo: '报警时间',
    alarmPicInfo: '报警照片'
  },

  // 林草巡检
  forestGrass: {
    targetDuration: '目标时长',
    finishDuration: '已巡检时长',
    targetArea: '目标面积',
    progress: '完成进度',
    AreaKM: '巡检面积（km²）',
    AreaMU: '巡检面积（万亩）',
    mileage: '巡检总里程',
    warnCount: '巡检报警数'
  },

  oilGas: {
    inspectionNumber: '巡检对象数',
    recordNumber: '巡检记录数',
    inspectionDuration: '巡检总时长',
    mileage: '飞行总里程',
    warningNumber: '巡检报警数',
    unit: "属地单位"
  },

  emergency: {
    taskName: '任务名称',
    taskDesc: '任务描述',
    accidentLocation: '事故发生地',
    drawLocation: '绘制位置',
    team: '应急队伍',
    dept: '所属团队',
    leader: '负责人',
    selectEquipment: '选择设备',
    endTaskSummary: '结束任务总结',
    endTaskDesc: '注意！结束项目后不可恢复。',
    hadEnd: '该任务已经结束',
    records: '任务记录',
    name: '记录名称',
    device: '使用设备',
    duration: '任务时长',
    viewSummary: '查看任务总结',
    screen: '应急大屏',
  },

  // 禁飞区管理
  noFlyZone: {
    noFly: '禁飞区',
    noFlyExplain: '此数据来源于民航局公布的民用航空机场障<br/>碍物限制面保护范围数据，此区域内禁止放<br/>飞飞行器。',

    temporary: '临时禁飞区',
    temporaryExplain: '此区域为政府公告的进行临时飞行管制的区<br/>域，在规定时间内未经飞行管制部门许可的<br/>飞行器不得飞入该区域。',

    heightLimit: '机场限高区',
    heightLimitExplain: '此区域为各地政府公布的机场净空区（机场<br/>跑道中心线两侧各10公里、跑道两端20公里<br/>范围）数据。此区域为限高区，部分政府规<br/>定此区域内需审批后飞行。',

    bufferZone: '机场10KM缓冲区',
    bufferZoneExplain: '机场周边10公里范围为机场缓冲区，缓冲区<br/>内未划入禁飞区的地方建议不要放飞飞行器。',

    customZone: '自定义禁飞区',

    noFlySearch: '请输入关键字',
    noFlySearchProvinces: '请选择省市',

    noFlyInformation: '禁飞区信息',
    basicInformation: '基本信息',
    type: '类型',
    name: '名称',
    startTime: '开始时间',
    endTime: '结束时间',
    provinces: '省市',
    painter: '绘制者',
    createdTime: '创建时间',
    centerPosition: '中心点位置信息',
    centerLongitude: '中心点经度',
    centerLatitude: '中心点纬度',
    measuredData: '测量数据',
    shape: '形状',
    horizontalArea: '水平面积',
    horizontalPerimeter: '水平周长',
    horizontalDistance: '水平距离',
    noFlySide: '禁飞侧',
    shunshiNeedleSide: '顺时针侧',
    counterclockwiseSide: '逆时针侧',
    radius: '半径',
    endpointInformation: '端点信息',

    drawingPolygon: '多边形',
    drawingCircle: '圆形',
    drawingLine: '线段',

    fileFormat: '文件格式',
    fileName: '文件名称',
    fileNamePlaceholder: '多个文件支持上传.zip进行导入'
  },

  // 机场管理
  airportManager: {
    moduleTitle: '机场管理',
    moduleSearch: '请输入关键字',

    coordinateSystem: '坐标系',
    fileName: '文件名称',
    fileNamePlaceholder: '请上传kml文件',

    airportInformation: '机场信息',
    basicInformation: '基本信息',
    temperature: '温度',
    humidity: '湿度',
    windSpeed: '风速',
    windDirection: '风向',
    rainfall: '雨量',
    positionInformation: '位置信息',
    longitude: '经度',
    latitude: '纬度',
    measurementInformation: '测量信息',
    horizontalArea: '水平面积',
    horizontalPerimeter: '水平周长',
    airportEquipment: '机场设备',
    addDevice: '添加设备',
    deviceName: '设备名称',
    devicetype: '设备类型',
    serialNumber: '序列号',
    operation: '操作',
    endpointInformation: '端点信息',
    equipmentSearch: '请输入设备名称',

    cannotdeleted: '机场面节点数量不能小于3，无法删除',
    saveButton: '保存',
    cancelButton: '取消'
  },

  // 基站
  baseStation: {
    baseStation: '基站点',
    name: '基站名称',
    type: '基站类型',
    address: '基站地点',
    baseStationIp: '基站IP',
    edgeServerAdHocIp: '服务器自组网IP',
    edgeServerLanIp: '服务器内网IP',
    communicationPort: '通信端口',
    addBaseStation: '新增基站',
    editBaseStation: '编辑基站',
    viewBaseStation: '查看基站',
    selectLocation: '选择地点经纬度',
    selectLocationTip: '请输入关键经纬度信息如：130,29',
    selectLocationError: '经纬度坐标有误，请检查!',
    deleteTip: '删除后不可恢复, 是否继续?',
    lonlatErrorTip1: '请填写经纬度坐标!',
    lonlatErrorTip2: '请填写正确的经纬度坐标!'
  },

  // 机库管理
  hangar: {
    hangarName: '机库名称',
    droneName: '无人机名称',
    addHangar: '新增机库',
    editHangar: '编辑机库',
    equipmentNumber: '设备序列号',
    equipmentBrand: '设备品牌',
    equipmentModel: '设备型号',
    bindingDrones: '绑定无人机',
    deviceImages: '设备图片',
    deviceImagesRemark: '大小限制：5MB。请上传清晰正确的设备照片，更好的展示设备信息',
    hangarInformation: '机库信息',
    model: '型号',
    serialNumber: '序列号',
    workingStatus: '工作状态',
    selfInspectionStatus: '自检状态',
    cabinTemperature: '舱内温度',
    ambientTemperature: '环境温度',
    windSpeed: '风速',
    lightStripSwitch: '灯带开关',
    flooded: '是否水淹',
    cumulativeRainfall: '当前累计雨量',
    alternateLon: '备降点经度',
    alternateLat: '备降点纬度',
    creator: '创建人',
    droneInformation: '无人机信息',
    flightSorties: '累计飞行架次',
    flightDuration: '累计飞行时长',
    flightMileage: '累计飞行里程',
    chargeCount: '电池充电次数',
    batteryTemperature: '电池温度',
    electricityLevel: '电量',
    realTimeVideo: '实时视频',
    aerocraft: '飞行器',
    hangarOut: '机库外',
    hangarInside: '机库内',
    hatch: '舱门',
    middlePole: '归中杆',
    charge: '充电',
    airVehicle: '飞行器',
    opening: '开启中',
    opened: '已开启',
    closing: '关闭中',
    closed: '已关闭',
    expanding: '展开中',
    expanded: '已展开',
    gathering: '归拢中',
    gathered: '已归拢',
    // powering: '上电中',
    charging: '充电中',
    // underPowering: '下电中',
    uncharged: '未充电',
    // during: '开机中',
    alreadyTurned: '已开机',
    // shutdowning: '关机中',
    notTurned: '已关机',
    open: '开启',
    close: '关闭',
    expand: '展开',
    gather: '归拢',
    powerOn: '开机',
    // shutdown: '关机',
    noControlData: '暂未获取到机库操控信息！'
  },
  
  // 航线管理
  routeManager: {
    route: '航线',
    routeManager: '航线管理',
    name: '航线名称',
    type: '航线类型',
    screenshot: '航线截图',
    UAVManufacturer: '飞机品牌',
    associationHangar: '关联机库',
    UAVModel: '飞机指纹',
    uavType: '飞机类型',
    uavNumber: '飞机型号',
    podType: '载荷类型',
    routeType: '航线类型',
    status: '是否公开',
    importDesc: 'kmz 中需包含 wpmz/template.kml 及 wpmz/waylines.wpml 文件'
  }
}
