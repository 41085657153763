<template>
  <div class="tags">
    <div class="tags-router" v-if="subMenu && subMenu.length">
      <template v-for="item in subMenu">
        <span
          :index="item.id"
          :class="
            nowTagValue.includes(item.path)
              ? 'tags-router-item tags-router-item--active'
              : 'tags-router-item'
          "
          :key="item[labelKey]"
          @click="changeRouter(item)"
        >
          {{ item.name }}
        </span>
      </template>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import config from "./sidebar/config.js";

export default {
  name: "tags",
  inject: ["index"],
  data() {
    return {
      config: config,
      activeId: "",
    };
  },
  computed: {
    ...mapGetters([
      "website",
      "menu",
      "subMenu",
      "tag",
      "keyCollapse",
      "screen",
      "menuId",
    ]),
    nowTagValue: function () {
      return this.$router.$avueRouter.getValue(this.$route);
    },
  },
  created() {
    this.index.openMenu();
  },
  methods: {
    changeRouter(item) {
      this.activeId = item.id;
      this.$router.push(item.path);
    },
    // vaildActive(item) {      
    //   console.log(item)
    //   console.log(this.$route.path.includes(item.path))
    //   return this.$route.path.includes(item.path)
    // },
  },
};
</script>
<style lang="scss" scoped>
.tags {
  user-select: none;
  position: relative;
  height: 33px;
  box-sizing: border-box;
  overflow: hidden;
  border-top: 1px solid rgba(151, 151, 151, 0.26);
  background-color: rgba(15, 18, 25, 1);
  &-router {
    display: flex;
    align-items: center;
    &-item {
      font-family: MicrosoftYaHei, MicrosoftYaHeiUI;
      color: #CCCCCC;
      font-size: 14px;
      padding: 0 13px;
      line-height: 32px;
      height: 32px;
      cursor: pointer;
      &--active {
        font-weight: bold;
        background: rgba(30, 34, 42, 1);
        color: rgba(88, 139, 255, 1);
      }
    }
  }
}
</style>

