<template>
  <transition name="nemeoftransition">
    <div
      v-if="AIvisible && total > 0"
      class="AINotification-main-box">
      <div class="AINotification-header">
        <span class="AINotification-text">
          {{$t('warning.dialogTitle')}}
        </span>
        <i
          class="icon el-icon-close"
          @click="close">
        </i>
      </div>
      <div class="AINotification-content">
        <div class="AINotification-content-img-box">
          <el-image 
            :src="imgUrl"
            :preview-src-list="imgUrls">
          </el-image>
        </div>
        <div class="AINotification-content-inner-box">
          <div class="content-inner-item">
            <span class="inner-item-label">
              {{$t('warning.type')}}{{$t('colonMark')}}
            </span>
            <span class="inner-item-value type-line">
              <span>{{current.finalTypeName}}</span>
              <i @click="locationTo" class="el-icon-aim icon-location">
                <span class="icon-center-dot"></span>
              </i>
            </span>
          </div>
          <div class="content-inner-item">
            <span class="inner-item-label">
              {{$t('FilghtProjectName')}}{{$t('colonMark')}}
            </span>
            <span class="inner-item-value">
              {{current.projectName}}
            </span>
          </div>
          <div class="content-inner-item">
            <span class="inner-item-label">
              {{$t('inspection.project')}}{{$t('colonMark')}}
            </span>
            <span class="inner-item-value">
              {{current.inspectProjectName}}
            </span>
          </div>
          <div class="content-inner-item">
            <span class="inner-item-label">
              {{$t('longitudeAndLatitude')}}{{$t('colonMark')}}
            </span>
            <span class="inner-item-value">
              {{
                `
                  ${current.lon? current.lon.slice(0, 8): '--'}, 
                  ${current.lat? current.lat.slice(0, 8): '--'}
                `
              }}
            </span>
          </div>
          <div class="content-inner-item">
            <span class="inner-item-label">
              {{$t('warning.time')}}{{$t('colonMark')}}
            </span>
            <span class="inner-item-value">
              {{current.createTime}}
            </span>
          </div>
          <div class="content-inner-btns">
            <span class="inner-btn" @click="ignore">
              {{$t('warning.ignore')}}
            </span>
            <span class="inner-btn" @click="amend">
              {{$t('warning.corrections')}}
            </span>
            <span class="inner-btn" @click="confirm">
              {{$t('warning.confirm')}}
            </span>
          </div>
        </div>
      </div>
      <div class="AINotification-footer">
        <div>
          <el-checkbox
            @change="checkedHandel"
            v-model="checked">
            {{$t('warning.doNotShowAgain')}}
          </el-checkbox>
        </div>
        <div>
          <el-pagination
            class="AINotification-pagination"
            small
            :page-size="1"
            :current-page.sync="currentPage"
            :pager-count="5"
            layout="prev, pager, next, total"
            :total="total">
          </el-pagination>
        </div>
      </div>
      <el-dialog
        :title="alarmTitle"
        append-to-body
        :visible.sync="alarmVisible"
        width="500px"
        :before-close="handleAlarmClose">
        <el-form
          :model="alarmForm"
          :rules="rules"
          ref="alarmForm"
          label-width="auto"
          class="demo-ruleForm">
          <!-- 报警类型 -->
          <el-form-item
            v-if="alarmAction==='amend'"
            :label="`${$t('warning.type')}：`"
            prop="finalTypeCode">
            <select-tree
              v-model="alarmForm.finalTypeCode"
              :data="alarmTypeList"
              size="small"
              :clearable="true"
              style="width: 248px"
              :placeholder="$t('selectPlease')"
            >
            </select-tree>
          </el-form-item>
          <!-- 报警级别 -->
          <el-form-item 
              :label="`${$t('warning.level')}：`"
              prop="level">
            <select-tree
              v-model="alarmForm.level"
              :data="levelList"
              size="small"
              :clearable="true"
              style="width: 248px"
              :placeholder="$t('selectPlease')">
            </select-tree>
          </el-form-item>
        </el-form>
        <span slot="footer" class="dialog-footer">
          <el-button @click="handleAlarmClose">{{$t('btnCancel')}}</el-button>
          <el-button type="primary" @click="alarmSubmit">{{$t('btnOk')}}</el-button>
        </span>
      </el-dialog>
    </div>
  </transition>
</template>

<script>
import { getStore, setStore } from '@/util/store'
import { getToken } from '@/util/auth'
import { getAiIdentifyList, submit } from '@/api/AI/AI-identify'
import { getDictionary } from '@/api/system/dictbiz'

export default {
  name: "AINotification",
  data() {
    return {
      token: getToken(),
      // 显示AI弹窗
      AIvisible: false,
      // 未读报警数据
      data: [],
      // 总页数
      total: 0,
      // 勾选
      checked: false,
      // 当前显示条数
      currentPage: 1,
      // 未读计数
      count: 0,
      // 更正表单弹窗
      alarmVisible: false,
      alarmAction: 'confirm',
      // 更正表单
      alarmForm: {
        finalTypeCode: '',
        level: 3
      },
      // 效验规则
      rules: {
        finalTypeCode: [
          { required: true, message: this.$t("selectPlease"), trigger: "blur" },
        ],
        level: [
          { required: true, message: this.$t("selectPlease"), trigger: "blur" },
        ]
      },
      // 
      finalTypeCodeList: [],
       // 报警级别(1-一级;2-二级;3-三级;4-四级)
      levelList: [
        {
          label: this.$t('warning.levelOne'),
          value: 1
        },
        {
          label: this.$t('warning.levelTwo'),
          value: 2
        },
        {
          label: this.$t('warning.levelThree'),
          value: 3
        },
        {
          label: this.$t('warning.levelFour'),
          value: 4
        }
      ],
      polling: false,
      // 报警类型列表 
      alarmTypeList: []
    }
  },
  watch: {
    $route() {
      if (this.$route.path === "/login" || this.$route.path === "/screenLogin") {
        this.AIvisible = false
        this.endPolling()
      }
    }
  },
  computed: {
    canAIVisible() {
      return !getStore({ name:'do-not-show-again' }) && !!this.token
    },
    // 图片地址
    imgUrl(){
      let hasList = this.current && Array.isArray(this.current.picLinkUrlList)  && this.current.picLinkUrlList[0]
      return hasList ? this.current.picLinkUrlList[0]: '/img/route-no.png'
    },
    // 图片list
    imgUrls() {
      return this.current && Array.isArray(this.current.picLinkUrlList) ? 
      this.current.picLinkUrlList: []
    },
    // 当前报警信息
    current() {
      if (this.data.length > 0 && this.currentPage > 0 &&this.data[this.currentPage - 1]) {
          return this.data[this.currentPage - 1]
        }
      return {}
    },
    // 编辑报警题目
    alarmTitle() {
      return this.alarmAction === 'confirm'? this.$t('warning.confirmWarning'): this.$t('warning.amendWarning')
    }
  },
  mounted() {
    this.$EventBus.$off("login-notice-to-AI");
    this.$EventBus.$on("login-notice-to-AI",this.noticeFromLogin);
    if (this.canAIVisible) {
      this.AIvisible = true
      setTimeout(()=>{
          this.beginPolling()
      }, 3000)
    }
  },
  methods: {
    // 
    getWarningType() {
      getDictionary({
        code: 'OilPatrolInspection'
      }).then(res=>{
        this.alarmTypeList = res.data.data.map(item=>{
          return {
            label: item.dictValue,
            value: item.dictKey
          }
        })
      })
    },
    // 
    close() {
      this.AIvisible = false
    },
    // 
    checkedHandel(value) {
      if (value) {
        this.endPolling()
      } else {
        this.beginPolling()
      }
      setStore({
        name: 'do-not-show-again',
        content: value,
        type:'message' 
      })
    },
    // 开始轮询
    beginPolling() {
      if (this.$route.path === "/login" || this.$route.path === "/screenLogin") {
        return
      }
      this.polling = true
      var success = false
      var duration = 1000
      this.getAiIdentifyUnread().then(data=>{
        if(!this.polling) return
        const count = data.length
        this.total = data.length
        this.data = data
        if (this.count < count) {
          if (this.canAIVisible) {
            this.$nextTick(()=>{
              this.AIvisible = true
            })
          }
        }
        this.count = count
        success = true
      }).finally(()=>{
        duration = success? duration: 5000
        if (!getStore({ name:'do-not-show-again' }) && this.polling) {
          this.$options.timer = setTimeout(()=>{
            this.beginPolling()
          }, duration)
        }
      })
    },
    // 结束轮询
    endPolling() {
      this.polling = false
      clearTimeout(this.$options.timer)
      this.$options.timer = null
    },
    // 忽略
    ignore() {
      this.handleMessage({
        id: this.current.id,
        alarmStatus: 'ignore'
      })
    },
    // 更正
    amend() {
      this.getWarningType()
      this.alarmAction = 'amend'
      this.alarmVisible = true
      this.alarmForm.finalTypeCode = this.current.firstTypeCode
    },
    // 报警弹窗
    alarmSubmit() {
      this.$refs.alarmForm.validate(bool=>{
        if(bool){
          switch (this.alarmAction) {
            case 'amend':
              this.handleMessage({
                id: this.current.id,
                alarmStatus: 'amend',
                ...this.alarmForm
              })
              break;
            case 'confirm':
              let data = {
                id: this.current.id,
                alarmStatus: 'confirm',
                ...this.alarmForm
              }
              delete data.finalTypeCode
              this.handleMessage(data)
              break;
            default:
              break;
          }
          this.handleAlarmClose()
        }
      })
    },
    // 确认
    confirm() {
      this.alarmAction = 'confirm'
      this.alarmVisible = true
    },
    // 报警信息操作
    handleMessage(params) {
      this.endPolling()
      submit(params).then(res=>{
        if (res.data.code === 200) {
          this.$message.success(res.data.msg);
        }
      }).finally(()=>{
        setTimeout(() => {
          this.beginPolling()
        }, 2000);
      })
      this.data = this.data.filter(item=>{
        return item.id !== this.current.id
      })
      this.total--
      this.count--
      if(this.currentPage > this.data.length){
        this.currentPage = this.data.length
      }
    },
    // 弹窗关闭
    handleAlarmClose() {
      this.$refs.alarmForm.resetFields()
      this.alarmVisible = false
    },
    // 用户登录
    noticeFromLogin() {
      if(this.canAIVisible) {
        this.beginPolling()
      }
    },
    // 获取未读信息
    getAiIdentifyUnread() {
      return new Promise((resolve, reject)=>{
        getAiIdentifyList().then(res=>{
          if (res.data.code === 200) {
            return resolve(res.data.data)
          }
          return reject()
        }).catch(()=>{
          reject()
        })
      })
    },
    // 定位
    locationTo() {
      this.$EventBus.$emit('AI-Notification-Location', this.current)
    }
  }
}
</script>

<style lang="scss">
  .nemeoftransition-enter-active {
      transition: opacity 1s;
  }
  .nemeoftransition-enter,.nemeoftransition-leave-to {
      opacity: 0;
  }
  .AINotification-main-box {
    display: flex;
    flex-direction: column;
    position: fixed;
    right: 10px;
    bottom: 10px;
    width: 400px;
    height: 220px;
    background-color: #fff;
    z-index: 9999;
    border: lightgray 1px solid;
    .AINotification-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: 30px;
      font-size: 14px;
      padding: 0px 10px;
      line-height: 30px;
      border-bottom: lightgray 1px solid;
      font-family: MicrosoftYaHeiUI-Bold, MicrosoftYaHeiUI;
      .icon {
        cursor: pointer;
        font-size: 24px;
      }
    }
    .AINotification-content {
      flex-grow: 1;
      padding: 10px 20px 6px 20px;
      display: flex;
      .AINotification-content-img-box {
        width: 130px;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        img {
          width: 130px;
        }
      }
      .AINotification-content-inner-box {
        flex-grow: 1;
        height: 100%;
        padding-left: 10px;
        .content-inner-item {
          display: flex;
          justify-content: space-between;
          height: 22px;
          line-height: 22px;
          .inner-item-label {
            display: inline-block;
            width: 80px;
            text-align: right;
            font-size: 14px;
            color: #999999;
          }
          .inner-item-value {
            display: inline-block;
            width: calc(100% - 80px);
            text-align: left;
            font-size: 14px;
            color: #000000;
          }
          .type-line{
            display: inline-flex;
            justify-content: space-between;
            .icon-location{
              position: relative;
              color: #409EFF;
              font-weight: 600;
              font-size: 18px;
              cursor: pointer;
              line-height: 22px;
              .icon-center-dot {
                display: inline-block;
                position: absolute;
                width: 4px;
                height: 4px;
                border-radius: 2px;
                left: 50%;
                top: 50%;
                transform: translateX(-50%) translateY(-50%);
                background-color: #409EFF;
              }
            }
          }
        }
        .content-inner-btns {
          text-align: right;
          padding-top: 5px;
          .inner-btn {
            margin-right: 10px;
            color: #409EFF;
            font-size: 14px;
            user-select: none;
            cursor: pointer;
          }
        }
      }
    }
    .AINotification-footer {
      border-top: lightgray 1px solid;
      height: 40px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0px 10px;
      .AINotification-pagination {
        .el-pager{
          li {
            display: none;
          }
          li.active {
            display: block;
          }
        }
      }
    }
  }
</style>