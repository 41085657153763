import request from '@/router/axios';

// 获取项目列表
export const getProjectList = (params) => {
  return request({
    url: '/api/aos-inspection/gladegrassinspectionproject/page',
    method: 'get',
    params
  })
}

// 通过id获取项目详情
export const getProjectDetail = (params) => {
  return request({
    url: '/api/aos-inspection/gladegrassinspectionproject/detail',
    method: 'get',
    params
  })
}

// 删除项目
export const deleteProject = (params) => {
  return request({
    url: "/api/aos-inspection/gladegrassinspectionproject/remove",
    method: "post",
    params
  });
};

// 新增项目
export const updateProject = (data) => {
  return request({
    url: "/api/aos-inspection/gladegrassinspectionproject/submit",
    method: "post",
    data,
  });
};

// 获取字典
export const getDictList = (params) => {
  return request({
    url: "/api/blade-system/dict/dictionary",
    method: "get",
    params,
  });
};

// 获取人员列表
export const getUserList = (params) => {
  return request({
    url: '/api/item-manager-config/user',
    method: 'get',
    params
  })
}

// 获取角色权限
export const getRoleList = (params) => {
  return request({
    url: "/api/blade-system/role/list",
    method: "get",
    params,
  });
};


// 获取设备列表
export const getEquipList = (params) => {
  return request({
    url: "/api/item-manager-config/page-equipment",
    method: "get",
    params,
  });
};

// 解绑项目人员
export const unbindMember = (data) => {
  return request({
    url: "/api/item-manager-config/unbind-member",
    method: "post",
    data,
  });
};

// 解绑设备
export const unbindEquip = (data) => {
  return request({
    url: "/api/item-manager-config/unbind-machine",
    method: "post",
    data,
  });
};

// 新增或更新项目人员权限
export const bindMember = (data) => {
  return request({
    url: "/api/item-manager-config/modify-member",
    method: "post",
    data,
  });
};

// 新增或更新绑定设备
export const bindEquip = (data) => {
  return request({
    url: "/api/item-manager-config/bind-machine",
    method: "post",
    data,
  });
};

// 新增项目默认添加创建人
export const addManager = () => {
  return request({
    url: "/api/item-manager-config/current-user",
    method: "get"
  });
};


export const getProjectTypeOptions = () => {
  return request({
    url: "/api/blade-system/dict/dictionary?code=project_type",
    method: "get"
  });
};


export const getProjectTeamOptions = () => {
  return request({
    url: "/api/blade-system/dept/tree",
    method: "get"
  });
};

export const getUserNew = (params) => {
  return request({
    url: '/api/blade-user/projectUserList',
    method: 'get',
    params
  })
}

export const getUseEquipmentList = (data) => {
  return request({
    url: '/api/cloud-task/project-management/getUseEquipmentList',
    method: 'post',
    data
  })
}

//配置大屏
export const screenTitleSet = (data) => {
  return request({
    url: "/api/aos-inspection/gladegrassinspectionproject/screenTitleSet",
    method: "put",
    data,
  });
};

export const getEquListByProjectId = (params) => {
  return request({
    url: '/api/cloud-task/project-management/getEquListByProjectId',
    method: 'get',
    params
  })
}

export const getAlarmData = (params) => {
  return request({
    url: 'api/cloud-task/alarm-data/page',
    method: 'get',
    params
  })
};

export const getAlarmDetailById = (params) => {
  return request({
    url: 'api/cloud-task/alarm-data/detail',
    method: 'get',
    params
  })
};

export const getTemplates = (params) => {
  return request({
    url: "/api/cloud-task/projectreporttemplate/list",
    method: "get",
    params
  });
};

export const addTemplate = (data) => {
  return request({
    url: "/api/cloud-task/projectreporttemplate/save",
    method: "post",
    data
  });
};

export const removeTemplate = (params) => {
  return request({
    url: "/api/cloud-task/projectreporttemplate/remove",
    method: "post",
    params
  });
};

export const updateTemplate = (params) => {
  return request({
    url: "/api/cloud-task/projectreporttemplate/updateStatus",
    method: "post",
    params
  });
};

export const screenOnDutySet = (data) => {
  return request({
    url: "/api/cloud-task/project-management/screenTitleSet",
    method: "put",
    data
  });
};
