var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "loading-view-box" }, [
    _c("div", { staticClass: "avue-home" }, [
      _c("div", { staticClass: "avue-home__main" }, [
        _c("img", {
          staticClass: "avue-home__loading",
          attrs: { src: "/svg/loading-spin.svg", alt: "loading" },
        }),
        _c("div", { staticClass: "avue-home__title" }, [
          _vm._v(
            "\n                " +
              _vm._s(_vm.$t("resourceLoading")) +
              "\n            "
          ),
        ]),
        _c("div", { staticClass: "avue-home__sub-title" }, [
          _vm._v(
            "\n                " +
              _vm._s(_vm.$t("pleaseHoldOn")) +
              "\n            "
          ),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }