<template>
  <div class="top-left">
    <div class="top-left-box" @click="toHome">
      <img class="logo" src="/img/businessLogo.png" alt="" />
      <span class="title">{{$t('title')}}</span>
    </div>
    <!-- <div class="top-left-branch">
      <el-select
        class="uav-select"
        popper-class="source-branch-selection-popper"
        v-model="value"
        placeholder="请选择"
      >
        <el-option
          v-for="item in options"
          :key="item.value"
          :label="item.label"
          :value="item.value"
        >
        </el-option>
      </el-select>
    </div> -->
  </div>
</template>

<script>
export default {
  name: "topLeft",
  data() {
    return {
      options: [
        {
          value: "选项1",
          label: "黄金糕",
        },
        {
          value: "选项2",
          label: "双皮奶",
        },
        {
          value: "选项3",
          label: "蚵仔煎",
        },
        {
          value: "选项4",
          label: "龙须面",
        },
        {
          value: "选项5",
          label: "北京烤鸭",
        },
      ],
      value: "",
    };
  },
  methods: {
    toHome() {
      // 跳转路由
      this.$router.push('/mission-center/item-manager-config')
      // 通知首页切换到项目列表
      this.$EventBus.$emit('toProjectList')
    }
  }
};
</script>

<style lang="scss">
.top-left-branch {
  .uav-select {
    .el-input input {
      width: 160px;
      height: 27px;
      font-weight: bold;
    }
  }
}
.source-branch-selection-popper {
  top: 55px !important;
}
</style>
<style lang="scss" scoped>
.top-left {
  height: 100%;
  display: flex;
  align-items: center;
  .top-left-box {
    cursor: pointer;
    padding-right: 22px;
    height: 40px;
    border-right: 1px solid rgba(151, 151, 151, 0.26);
    display: flex;
    justify-content: space-between;
    align-items: center;
    .logo {
      width: 40px;
      margin-right: 5px;
      margin-top: 4px;
    }
    .title {
      font-size: 18px;
      font-family: MicrosoftYaHeiUI-Bold, MicrosoftYaHeiUI;
      font-weight: bold;
      color: #ffffff;
    }
  }
}
.top-left-branch {
  padding-left: 23px;
  .el-select .el-input.is-focus .el-input__inner {
    border-color: rgba(255, 255, 255, 0.51) !important;
  }
}
</style>
