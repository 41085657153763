export default {
  title: 'air system-test',
  tip: 'tip',
  logoutTip: 'Exit the system, do you want to continue?',
  submitText: 'submit',
  cancelText: 'cancel',
  requestErrorText: 'request failure',
  requestSuccessText: 'Request successful',
  openText: 'open',
  closeText: 'close',
  correct: 'correct',
  deny: 'deny',
  idle: 'idle',
  working: 'working',
  search: 'Please input search content',
  inputPlease: '请输入内容',
  selectPlease: '请选择内容',
  uploadPlease: '请上传文件',
  inputKeyworldForfilter: '输入关键字进行过滤',
  menuTip: 'none menu list',
  longitude: 'Longitude',
  latitude: 'Latitude',
  chooseFile: 'Choose file',
  file: '文件',
  folder: '文件夹',
  file: '文件',
  duration: 'Duration',
  area: 'Area',
  folderName: '文件夹名称',
  uploadText: '上传',
  type: '类型',
  contactPerson: '联系人',
  contactTelephone: '联系电话',
  name: '名称',
  loading: '加载中',
  resourceLoading: '正在加载资源',
  pleaseHoldOn: '请耐心等候',
  objectType: '对象类型',
  projectBelong: '所属项目',
  taskBelong: '所属任务',
  startTime: 'Start time',
  endTime: 'End time',
  notes: '备注',
  screenShot: 'Screenshot',
  allType: '所有类型',
  allProjects: '所有项目',
  allTasks: '所有任务',
  has: '有',
  hasNo: '无',
  operationSuccessful: '操作成功',
  currentMode: 'Current mode',
  CurrentBatteryLevel: 'battery level',
  unlockingSignal: 'Signal Unlock',
  screenMode: '画面模式',
  videoAzimuth: '吊舱方位角',
  videoPitch: '吊舱俯仰角',
  speed: 'speed',
  height:'Height',
  Homedist: 'Home dist',
  RealTimeMileage: 'Real-time mileage',
  FilghtProjectName: 'Flight Project name',
  FlightTime: 'Flight time',
  FilghtTask: 'Filght task',
  WaitingForVideoTransmission: 'Waiting for video transmission...',
  Monday: 'Monday',
  Tuesday: 'Tuesday',
  Wednesday: 'Wednesday',
  Thursday: 'Thursday',
  Friday: 'Friday',
  Saturday: 'Saturday',
  Sunday: 'Sunday',
  unlocked: 'Unlocked',
  locked: 'Locked',
  gs: 'Ground speed',
  refresh: 'Refresh',
  UAVFootage: 'UAV footage',
  operationSuccessfully: 'Operation successfully',
  LatitudeLongitudeNotObtained: ' Latitude and longitude not obtained，please check the route',
  videoSource: '视频源',
  noVideoUrl: 'There is currently no historical video address available!',
  downLoadVideo: 'Video download',
  current: 'Current',
  color: "Color",
  icon: "Icon",
  btnAdd: 'Add',
  btnDelete: 'Delete',
  btnEdit: 'Edit',
  btnEnd: '结 束',
  btnOk: '确 定',
  btnCancel: '取 消',
  btnExport: '导 出',
  btnClose: '关 闭',
  searchBtn: '搜 索',
  emptyBtnText: '清 空',
  updateTime: '更新时间',
  remark: 'remark',
  enterProject: '进入项目',
  manage: '管理',
  addProject: '添加项目',
  editProject: '编辑项目',
  longitudeAndLatitude: '经纬度',
  eventType: '事件类型',
  eventLevel: '事件等级',
  eventTypeName: '事件名称',
  save: '保存',
  addTask: '新增任务',
  editTask: '编辑任务',
  copyTask: '复制任务',
  export: '导出',
  to: '至',
  baseInfo: '基础信息',
  fileInfo: '文件信息',
  uploadFile: '上传文件',
  Upload: '请上传',
  Import: '导入',
  execute: '执行',
  index: '序号',
  view: '查看',
  edit: '编辑',
  delete: '删除',
  saveSuccessTip: '保存成功!',
  saveErrorTip: '保存失败!',
  deleteSuccessTip: '删除成功!',
  deleteErrorTip: '删除失败!',
  clickToUpload: '点击上传',
  questionMark: '？',
  colonMark: '：',
  ConfirmToDelete: '确定删除',
  videoFile: '视频文件',
  checkAll: '全选',
  downLoadAll: '批量下载',
  department: '所属团体',
  needToDelete: '确定将选择数据删除',
  executor: '执行人',
  department: '所属部门',
  comma: '，',
  // crud
  crud: {
    searchBtn: '搜索',
    emptyBtnText: '清空'
  },
  wel: {
    info: 'Good morning',
    dept: 'Aossci Admin',
    team: 'Team ranking',
    project: 'Project access',
    count: 'Item number',
    data: {
      subtitle: 'real time',
      column1: 'Classified statistics',
      column2: 'Annex statistics',
      column3: 'Article statistics',
      key1: 'C',
      key2: 'A',
      key3: 'A',
      text1: 'Total Record Number of Classifications',
      text2: 'Number of attachments Uploaded',
      text3: 'Comment frequency'
    },
    data2: {
      column1: 'Registration today',
      column2: 'Login today',
      column3: 'Subscription today',
      column4: 'Todays review'
    },
    data3: {
      column1: 'Conversion rate（Day 28%）',
      column2: 'Attendance rate（Day 11%）',
      column3: 'Attendance rate（Day 33%）'
    },
    data4: {
      column1: 'Error log',
      column2: 'Data display',
      column3: 'Privilege management',
      column4: 'user management'
    },
    table: {
      rw: 'Work Tasks',
      nr: 'Work content',
      sj: 'Working hours',
    }
  },

  live: {
    warningType: 'Alarm type',
    chooseWarningType: '请选择报警类型'
  },

  route: {
    info: 'info',
    website: 'website',
    avuexwebsite: 'avuex',
    dashboard: 'dashboard',
    more: 'more',
    tags: 'tags',
    store: 'store',
    permission: 'permission',
    api: 'api',
    logs: 'logs',
    table: 'table',
    form: 'form',
    top: 'backtop',
    data: 'data',
    error: 'error',
    test: 'test'
  },

  login: {
    title: 'Login ',
    info: 'Enterprise Development Platform',
    tenantId: 'Please input tenantId',
    username: 'Please input username',
    password: 'Please input a password',
    wechat: 'Wechat',
    qq: 'QQ',
    github: 'github',
    gitee: 'gitee',
    phone: 'Please input a phone',
    code: 'Please input a code',
    submit: 'Login',
    userLogin: 'userLogin',
    phoneLogin: 'phoneLogin',
    thirdLogin: 'thirdLogin',
    msgText: 'send code',
    msgSuccess: 'reissued code',
  },

  navbar: {
    info: 'info',
    logOut: 'logout',
    userinfo: 'userinfo',
    dashboard: 'dashboard',
    lock: 'lock',
    bug: 'none bug',
    bugs: 'bug',
    screenfullF: 'exit screenfull',
    screenfull: 'screenfull',
    language: 'language',
    notice: 'notice',
    theme: 'theme',
    color: 'color'
  },

  tagsView: {
    search: 'Search',
    menu: 'menu',
    clearCache: 'Clear Cache',
    closeOthers: 'Close Others',
    closeAll: 'Close All'
  },

  userinfo:{
    head: '头像-en',
    headTip: '只能上传jpg/png用户头像，且不超过500kb',
    name: '姓名',
    username:'用户名',
    mobile: '手机号',
    email: '邮箱',
    editPwd: 'edit password',
    oldPwd: 'old password',
    newPwd: 'new password',
    confirmPwd: 'confirm password'
  },
  
  //用户中心
  user: {
    emergencyranks: {
      name: '队伍名称',
      parentName: '上级队伍名称',
      memberNames: '队伍成员',
      memberNumber: '成员数量',
      leaderName: '负责人',
      remark: '队伍描述',
      eventType: '时间类型'
    }
  },

  // 飞行中心
  mission: {
    MissionDetails: 'Mission details',
    taskType: '任务类型',
    taskCycle: '任务周期',
    flightMissionDetails: 'Flight mission details',
    name: 'Mission name',
    device: 'Device name',
    team: 'Team',
    operator: 'Operator',
    history: '历史轨迹',
    flightInformation: '飞行信息',
    press: '按下',
    forScreenshot: '快捷截屏',
    route: '航线',
    missionRoute: '任务航线',
    chooseTaskCycle: '请选择任务周期',
    usingRoute: '使用航线',
    hangarTask: '机库任务',
    hangarTaskType: '机库任务类型',
    executionTime: '执行时间',
    cycleByClick: '请点击“选择日期”按钮',
    choseDate: '选择日期',
    usingHangar: '使用机库',
    usingPod: '使用载荷',
    usingDataLink: '使用数据链',
    brand: '选择品牌',
    usingUAV: '使用飞机',
    pleaseUsingHanger: '请选择“使用机库”',
    cycleTaskFailure: '周期任务列表获取失败',
    executeTask: '任务已开始执行',
    businessType: '业务分类',
    uploadFileLimitText1: '当前限制选择',
    uploadFileLimitText2: '个文件，本次选择了',
    uploadFileLimitText3: '个文件，共选择了',
    uploadFileLimitText4: '个文件',
    uploadVideo: '上传视频',
    uploadVideoDesc: '支持格式：mp4, flv。大小不超过2GB。',
    uploadRoute: '上传航线',
    uploadRouteDesc: '支持格式：kml, geojson。',
    ImageSizeLimit: '大小限制：5MB',
    uploadOperationArea: '上传作业区',
    uploadOperationAreaDesc: '支持格式：kml, geojson。',
    aerocraft: '飞行器',
    creator: '创建人',
    taskReplay: '任务回放',
    taskRecord: '补录任务',
    planRealTime: '计划/实际执行时间',
    taskCode: '任务编号',
    hangarTask: '机库任务',
    viewRoute: '查 看',
    selectEquipment: '选择设备',
    failNotice: '任务执行失败'
  },

  // 标注
  mark: {
    mark: '标注',
    info: '标注信息',
    MarkPoint: '点',
    MarkLine: '线',
    MarkPolygon: '面',
    horizontalDistance: '水平距离',
    distance: "直线距离",
    horizontalPerimeter: '水平周长',
    horizontalArea: '水平面积',
    icon: '标注图标',
    pointDesc: '系统预置了不同行业类型的图标，方便在地图展示。',
    coordinatePoint: '坐标点',
    firePoint: '火点',
    searchByName: '按标注名称搜索',
    upload: '导入标注',
    type: '标注类型'
  },

  //巡检
  inspection: {
    project: "巡检项目",
    selfName: '巡检对象',
    info: '巡检对象信息',
    type: '巡检对象类型',
    baseInfo: '基本信息',
    survey: '测量数据',
    horizontal_Distance: '水平距离',
    createBy: '绘制者',
    oilWell: '油井',
    oilLine: '石油管线',
    oilRoad: '油田公路',
    oilStorageTank: '石油储罐',
    oilStationStorage: '油气站库',
    oilPowerLine:'电力线路',
    oilMeasurementStation:'计量站',
    oilPowerEquipment: '电力设施',
    oilGasWell: '石油气井',
    PipelineLength: '管线长度',
    pointInfo: '端点信息',
    uploadInspection: '导入巡检对象',
    uploadKML: '请上传kml文件',
    possessionUnit: '属地单位',
    meteringstation: '计量站',
    waterRoom: '配水间',
    record: '巡检记录',
    exportRecords: '导出巡检报告',
    advancedSetting: '高级设置',
    intoMessage1: '进入对象',
    intoMessage2: '米范围开始巡检',
    outtoMessage1: '脱离对象范围',
    outtoMessage2: '秒后结束巡检',
    useToAll: '应用到所有',
    name: '记录名称',
    device: '巡检设备',
    duration: '巡检时长',
    hasWarning: '有无报警',
    editRecord: '编辑巡检记录',
    cannotdeleted: '管线节点数量小于2，不能删除',
    inspectionPhotos: 'Inspection photos'
  },

  // 报警
  warning: {
    type: '报警类型',
    correctionType: 'Correction type',
    place: '报警地点',
    alarmLevel: 'alarm Level',
    device: '报警设备',
    time: '报警时间',
    photo: '报警照片',
    video: 'video',
    manualAlarm: 'ManualAlarm',
    AIalarm: 'AIalarm',
    // ai报警状态
    ignore: 'ignore',
    corrections: 'corrections',
    confirm: 'confirm',
    untreated: 'untreated',
    confirmed: 'Confirmed',
    corrected: 'Corrected',
    neglected: 'Neglected',
    dialogTitle: 'AI报警信息',
    doNotShowAgain: '本次登录不再提示',
    levelOne: 'level One',
    levelTwo: 'level Two',
    levelThree: 'level Three',
    levelFour: 'level Four',
    correctAlarm: 'correct Alarm',
    confirmAlarm: 'confirm Alarm',
    level: '报警级别',
    confirmWarning: '确认报警',
    amendWarning: '更正报警'
  },

  map: {
    coordinateSystem: '坐标系'
  },

  // 大屏
  screen: {
    largeScreen: 'Project Large Screen',
    mainTitle: 'Main title',
    subtitle: 'Subtitle',
    largeScreenTemplate: 'Large screen template',
    teamLeader: 'Team leader',
    dutyOfficer: 'Duty Officer',
    dutyDispatch: 'On duty dispatch',
    dutySectionChief: 'On duty section chief',
    dutyCommunication: 'Duty communication',
    dutyDriver: 'On duty driver',
    dutyPersonnel: 'Duty personnel',
    communicationPhone: 'Communication phone',
    viewLargeScreen: 'View large screen',
    selecteTemplate: 'Choose a large screen template',
    UAVMap: 'UAV flight map',
    UAVFootage: 'UAV footage',
    UAVInfo: 'UAV information',
    goback: 'Back',
    realMap: 'Real time map',
    realVideo: 'real time video ',
    currentFootage: 'Current screen',
    taskInfo: 'Task Information',
    UAVModel: 'Drone model',
    UAVNumber: 'Drone number'
  },

  // 石油巡检大屏
  oilGasScreen: {
    screenTitle: 'UAV Inspection system',
    exit: 'exit',
    daily: 'Daily',
    weekly: 'weekly',
    monthly: 'monthly',
    today: 'today',
    theWeek: 'week',
    theMonth: 'month',
    noData: 'No data...',
    // 全局概况
    globalOverview: 'Overview',
    deviceOverview: 'Device Overview',
    more: 'more',
    inLineDevice: 'in-line device',
    devicesTotal: 'Devices Total',
    fixedWing: 'fixed wing',
    multiRotors: 'multi-rotors',
    sets: 'sets',
    hangar: 'hangar',
    pcs: 'pcs',
    uavFlightStatistics: 'Drone Flight Statistics',
    sortie: 'sortie',
    duration: 'duration',
    mileage: 'mileage',
    historySortie: 'Historical Flights',
    podOverview: 'Overview of the Pod',
    pleaseSelectDrone: 'Please select drone',
    pipeInspection: 'Pipeline inspection mileage',
    oilWellInspection: 'Number of oil well inspections',
    realTimeAlarm: 'Real-time Alarm',
    uavLedger: 'Drone ledger',
    brand: 'brand',
    droneName: 'Drone Name',
    droneType: 'Drone type',
    droneModel: 'Drone model',
    belongingAirport: 'Belonging airport',
    state: 'state',
    pleaseSelectState: 'Please select the status',
    alarmSearch: 'Alarm Search',
    threeDay: 'past 3 day',
    oneWeek: 'past 1 week',
    oneMonth: 'past 1 month',
    custom: 'custom',
    // 实时任务
    realTimeTask: 'Real-time',
    // 巡检记录
    inspectionRecord: 'Inspection',
    downloadReport: 'Download Inspection Report',
    date: 'date',
    project: 'project',
    downloadLoadingText: 'Downloading records, please wait!',
    typeStatistics: 'Object type statistics',
    abnormalRanking: 'Abnormal ranking of objects',
    pleaseSelectInspectionItem: 'Please select the inspection item',
    inspectionRecords: 'Inspection record statistics',
    inspectionDuration: 'Inspection Duration statistics',
    inspectionTrend: 'Inspection Trend',
    // 报警记录
    alarmRecord: 'Alarm',
    pleaseSelectAlarmType: 'Please select the alarm type',
    factoryAlarm: 'Factory alarm',
    objectAlarm: 'Inspection object alarm',
    alarmType: 'Alarm type',
    alarmTrend: 'Alarm Trend',
    alarmTypes: 'Alarm Types',
    time: 'time',
    uavName: 'uav',
    latLon: 'latLon',
    noProject: 'Inspection project error!',
    noAlarmType: 'Alarm type incorrect!',
    noTime: 'Time range error!',
    // 报警信息弹窗
    alarmInfo: 'Alarm Info',
    alarmTypeInfo: 'Alarm Type',
    alarmLocationInfo: 'Location',
    alarmDeviceInfo: 'Device',
    flightProjectInfo: 'flight Project',
    flightTaskInfo: 'flight Task',
    inspectionProject: 'inspection Project',
    inspectionObject: 'inspection Object',
    alarmTimeInfo: 'Time',
    alarmPicInfo: 'Alarm photos'
  },

  // 林草巡检
  forestGrass: {
    targetDuration: '目标时长',
    finishDuration: '已巡检时长',
    targetArea: '目标面积',
    progress: '完成进度',
    AreaKM: '巡检面积（km²）',
    AreaMU: '巡检面积（万亩）',
    mileage: '巡检总里程',
    warnCount: '巡检报警数'
  },

  oilGas: {
    inspectionNumber: '巡检对象数',
    recordNumber: '巡检记录数',
    inspectionDuration: '巡检总时长',
    mileage: '飞行总里程',
    warningNumber: '巡检报警数',
    unit: "属地单位"
  },

  emergency: {
    taskName: '任务名称',
    taskDesc: '任务描述',
    accidentLocation: '事故发生地',
    drawLocation: '绘制位置',
    team: '应急队伍',
    dept: '所属团队',
    leader: '负责人',
    selectEquipment: '选择设备',
    endTaskSummary: '结束任务总结',
    endTaskDesc: '注意！结束项目后不可恢复。',
    hadEnd: '该任务已经结束',
    records: '任务记录',
    name: '记录名称',
    device: '使用设备',
    duration: '任务时长',
    viewSummary: '查看任务总结',
    screen: '应急大屏',
  },

  // 禁飞区管理
  noFlyZone: {
    noFly: 'No fly zone',
    noFlyExplain: 'This data is sourced from the data on the protection range <br/>of civil aviation airport obstacles published by the Civil Aviation Administration, and flying aircraft are <br/>prohibited in this area.',

    temporary: 'Temporary no fly zone',
    temporaryExplain: 'This area is a temporary flight control zone announced by the government. <br/>Aircraft without permission from the flight control department are not allowed to fly <br/>into this area within the specified time.',

    heightLimit: 'Airport height limit area',
    heightLimitExplain: 'This area is the airport clearance zone data published by local governments (within a range <br/>of 10 kilometers on both sides of the runway centerline and 20 kilometers <br/>on both ends of the runway). This area is a restricted height zone, and some government regulations require approval <br/>before flying within this area.',

    bufferZone: 'Airport 10km buffer zone',
    bufferZoneExplain: 'A 10 kilometer radius around the airport is the airport buffer zone. It is recommended <br/>not to release aircraft in areas within the buffer zone <br/>that have not been designated as no fly zones.',

    customZone: 'Custom no fly zone',

    noFlySearch: 'Please enter keywords',
    noFlySearchProvinces: 'Please select a province or city',

    noFlyInformation: 'No fly zone information',
    basicInformation: 'Basic information',
    type: 'type',
    name: 'name',
    startTime: 'Start time',
    endTime: 'End time',
    provinces: 'Provinces and cities',
    painter: 'Painter',
    createdTime: 'Creation time',
    centerPosition: 'Center point position',
    centerLongitude: 'longitude',
    centerLatitude: 'latitude',
    measuredData: 'measured data ',
    shape: 'shape',
    horizontalArea: 'Horizontal area',
    horizontalPerimeter: 'Horizontal perimeter',
    horizontalDistance: 'Horizontal distance',
    noFlySide: 'No fly side',
    shunshiNeedleSide: 'Shunshi needle side',
    counterclockwiseSide: 'Counterclockwise side',
    radius: 'radius',
    endpointInformation: 'Endpoint information',

    drawingPolygon: 'polygon',
    drawingCircle: 'circle',
    drawingLine: 'line',

    fileFormat: 'File Format',
    fileName: 'File Name',
    fileNamePlaceholder: 'Multiple files support uploading. zip for import'
  },

  // 机场管理
  airportManager: {
    moduleTitle: 'Airport management',
    moduleSearch: 'Please enter keywords',

    coordinateSystem: 'Coordinate system',
    fileName: 'File Name',
    fileNamePlaceholder: 'Please upload the kml file',

    airportInformation: 'Airport information',
    basicInformation: 'Basic information',
    temperature: 'temperature',
    humidity: 'humidity',
    windSpeed: 'wind speed',
    windDirection: 'wind direction',
    rainfall: 'rainfall',
    positionInformation: 'position information',
    longitude: 'longitude',
    latitude: 'latitude',
    measurementInformation: 'Measurement information',
    horizontalArea: 'Horizontal area',
    horizontalPerimeter: 'Horizontal perimeter',
    airportEquipment: 'Airport equipment',
    addDevice: 'Add device',
    deviceName: 'Device Name',
    devicetype: 'Device Type',
    serialNumber: 'serial number',
    operation: 'operation',
    endpointInformation: 'Endpoint information',
    equipmentSearch: 'Please enter the device name',

    cannotdeleted: 'The number of airport nodes cannot be less than 3 and cannot be deleted',
    saveButton: 'save',
    cancelButton: 'cancel'
  },

  // 基站
  baseStation: {
    baseStation: 'Base station point',
    name: 'name',
    type: 'type',
    address: 'address',
    baseStationIp: 'baseStationIp',
    edgeServerAdHocIp: 'edgeServerAdHocIp',
    edgeServerLanIp: 'edgeServerLanIp',
    communicationPort: 'communicationPort',
    addBaseStation: 'Add base station',
    editBaseStation: 'Edit base station',
    viewBaseStation: 'View base stations',
    selectLocation: 'Select latitude',
    selectLocationTip: 'Please enter key latitude and longitude information, such as: 130,29',
    selectLocationError: 'The latitude and longitude coordinates are incorrect, please check!',
    deleteTip: 'Do you want to continue?',
    lonlatErrorTip1: 'Please fill in the latitude and longitude coordinates!',
    lonlatErrorTip2: 'Please fill in the correct latitude and longitude coordinates!'
  },

  // 机库管理
  hangar: {
    hangarName: 'Hangar name',
    droneName: 'Drone Name',
    addHangar: 'Add hangar',
    editHangar: 'Edit hangar',
    equipmentNumber: 'Equipment serial number',
    equipmentBrand: 'Equipment brand',
    equipmentModel: 'Equipment model',
    bindingDrones: 'Binding drones',
    deviceImages: 'Device images',
    deviceImagesRemark: 'Size limit: 5MB. Please upload clear and correct device photos to better display device information',
    hangarInformation: 'Hangar information',
    model: 'model',
    serialNumber: 'serial number',
    workingStatus: 'working condition ',
    selfInspectionStatus: 'Self inspection status',
    cabinTemperature: 'Cabin temperature',
    ambientTemperature: 'ambient temperature',
    windSpeed: 'wind speed',
    lightStripSwitch: 'Light strip switch',
    flooded: 'Is it flooded',
    cumulativeRainfall: 'Current cumulative rainfall',
    alternateLon: 'Alternate longitude',
    alternateLat: 'Alternate latitude',
    creator: 'creator',
    droneInformation: 'Drone Information',
    flightSorties: 'Flight sorties',
    flightDuration: 'Flight duration',
    flightMileage: 'Flight mileage',
    chargeCount: 'Charging frequency',
    batteryTemperature: 'Battery temperature',
    electricityLevel: 'Electricity level',
    realTimeVideo: 'real-time video ',
    aerocraft: 'aerocraft',
    hangarOut: 'Outside the hangar',
    hangarInside: 'Inside the hangar',
    hatch: 'hatch',
    middlePole: 'middlePole',
    charge: 'charge',
    airVehicle: 'air vehicle',
    opening: 'Opening',
    opened: 'opened',
    closing: 'Closing',
    closed: 'closed',
    expanding: 'Expanding',
    expanded: 'expanded',
    gathering: 'Gathering',
    gathered: 'gathered',
    // powering: 'During power on',
    charging: 'charging',
    // underPowering: 'Under power',
    uncharged: 'uncharged',
    // during: 'During startup',
    alreadyTurned: 'Already turned on',
    // shutdowning: 'Shutdown in progress',
    notTurned: 'Not turned on',
    open: 'open',
    close: 'close',
    expand: 'expand',
    gather: 'gather',
    powerOn: 'Power on',
    // shutdown: 'Shutdown',
    noControlData: 'No hangar control information has been obtained yet!'
  },
  
  // 航线管理
  routeManager: {
    route: '航线',
    routeManager: '航线管理',
    name: '航线名称',
    type: '航线类型',
    screenshot: '航线截图',
    UAVManufacturer: '飞机品牌',
    associationHangar: '关联机库',
    UAVModel: '飞机指纹',
    uavType: '飞机类型',
    uavNumber: '飞机型号',
    podType: '载荷类型',
    routeType: '航线类型',
    status: '是否公开',
    importDesc: 'kmz 中需包含 wpmz/template.kml 及 wpmz/waylines.wpml 文件'
  }
}
