<template>
  <div class="top-tabs">
    <template v-for="item in menu">
      <div
        v-if="item.code !== 'system'"
        :key="item.id"
        :class="isActivePath(item)
            ? 'top-tabs-item top-tabs-item--active'
            : 'top-tabs-item'
        "
        @click="changePage(item)"
      >
        {{ item.name }}
      </div>
    </template>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "topTabs",
  data() {
    return {
      activePath: null,
      value: "",
      // // 对获取应该制作为导航栏的路由，应该判断定义路由时是否
      // menu: [],
      subMenu: [],
    };
  },
  computed: {
    ...mapGetters([
      "website",
      "menu",
      "tag",
      "keyCollapse",
      "screen",
      "menuId",
    ]),

    activePath() {
      let route = this.$router.$avueRouter.getValue(this.$route)
      if (route.length) {
        let item = '/' + route.split('/')[1]
        this.menu.map(iitem => {
          if (iitem.path === item) {
            if (iitem.children.length) {
              this.subMenu = iitem.children;
              this.$store.dispatch("SetSubMenu", this.subMenu);
            }
          }
        })
      }
      return route;
    },

    isActivePath() {
      return (item) => {
        const firstPath = this.$route.matched[0].path;
        return firstPath.includes(item.path);
      };
    }
  },
  watch: {
    menu: {
      handler() {
        this.initRouter();
      },
      immediate: true
    }
  },
  mounted() {
     //防止重复绑定
    this.$EventBus.$off('toProjectList', this.changePage)
    this.$EventBus.$on('toProjectList', this.changePage)
  },
  methods: {
    initRouter() {
      setTimeout(() => {
        const firstPath = this.$route.matched[0].path;
        this.activePath = firstPath;
        for (let i = 0; i < this.menu.length; i++) {
          var item = this.menu[i]
          if (this.activePath.indexOf(item.path) > -1) {
            this.$store.dispatch("SetSubMenu", item.children);
          }
        }
        return firstPath
      }, 200);
    },
    changePage(item) {
      if (!item) {
        // 点击产品图标跳转至项目中心首页
        item = this.menu[0]
      }
      const path = item.path;
      this.activePath = path;
      if (item.children.length) {
        this.subMenu = item.children;
        this.$store.dispatch("SetSubMenu", this.subMenu);
        this.$router.push(item.children[0].path);
      } else {
        this.$router.push(path);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.top-tabs {
  display: flex;
  margin-left: 40px;
  &-item {
    padding: 23px 16px;
    font-size: 14px;
    font-family: MicrosoftYaHeiUI-Bold, MicrosoftYaHeiUI;
    font-weight: bold;
    color: #ffffff;
    display: flex;
    align-items: center;
    cursor: pointer;
    &--active {
      background: #265edd;
    }
  }
}
</style>
