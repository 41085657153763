/*
 * @Description:
 * @Autor: wq
 * @Date: 2022-06-24 17:22:48
 * @watermark: 成都傲势科技
 * @LastEditors: wq
 */
/** @type {*} */
const map = {
  state: {
    /**
     * handlerType为地图当前操作类型取值如下：
     * null  无任何操作
     * projectPoint  设置项目中心点    结构：{type:"projectPoint" , params:null }
     * switchWorkSpace  切换绘图层(如果没有则新增)   结构：{type:"switchWorkSpace" , params:{ groupId:"",  workSpace:"", groupName:"", visible: true/false }} 目前groupId、visible必填
     * homePoint     设置Home点    结构：{type:"homePoint" , params: [lng , lat, height] }
     * 
     */
    handlerType: null,

    /**
     * newFeature用于存储地图新增要素
     *
     * null  无任新增
     *
     * type: point-点  linestring-线  polygon-面
     *
     * 有要素新增时，此结构为：{ type: "", geojson: "", groupId: "", label: ""}
     *
     */
    newFeature: null,

    /**
     * 当前激活显示的航线 结构见  store/data.js 中 folder->placemarkList
     */
    placemarkList: [],

    /**
     * 当前激活显示的航线Id
     */
    routeId: null,

    /**
     * 航线长度
     */
    flylineLength: null,

    routeWebSocket: null,

    /*******************标注********************/
    markColorList: [
      "#0096FF",
      "#FF8900",
      "#FF4443",
      "#A94BFF",
      "#8CD51C"
    ],
    /*******************标注********************/

    /*******************项目中心********************/
    // 项目起飞点
    homePoint: null,
    // 项目中心点
    projectPoint: null,
    // 在项目列表页选中的项目
    selectProject: null,
    /*******************项目中心********************/

    /*******************报警*******************/
    // 报警列表
    warningList: [],
    // 当前选择的报警详情
    selectWarning: {},
    // 显示报警详情弹窗（）
    showWarningDetialDialog: false,
    /*******************报警*******************/

    /*******************应急*******************/
    // 应急记录列表
    emergencyRecordsList: [],
    // 当前选择的应急记录详情
    selectEmergencyRecord: {},
    /*******************应急*******************/

    // 飞行中心---航线管理
    selectRoute: {},

    //任务的推流信息(历史轨迹也在使用)
    wsTaskInfo: {},

    // 禁飞区选中节点
    noflySwitch: true,
    noflyCheckList: ['noFly', 'temporary', 'heightLimit', 'bufferZone', 'customZone'],
    noflyTypeValue: [
      {label: 'noFly', name: 'noFlyZone.noFly', disabled: false, img: 'no-fly'},
      {label: 'temporary', name: 'noFlyZone.temporary', disabled: false, img: 'temporary'},
      {label: 'heightLimit', name: 'noFlyZone.heightLimit', disabled: false, img: 'height-limit'},
      {label: 'bufferZone', name: 'noFlyZone.bufferZone', disabled: false, img: 'buffer-zone'},
      {label: 'customZone', name: 'noFlyZone.customZone', disabled: false, img: 'custom-zone'}
    ]
  },
  actions: {
    setHomePoint({ commit }, position) {
      commit("SET_HOME_POINT", position);
    },

    setPlaceMarkList({ commit }, { routeInfoId, placemarkList }) {
      commit("SET_PLACEMARK_LIST", { routeInfoId, placemarkList });
    },

    setProjectPoint({ commit }, point) {
      commit("SET_PROJECT_POINT", point);
    },

    setHandlerType({ commit }, payload) {
      commit("SET_HANDLER_TYPE", payload);
    },

    setNewFeature({ commit }, feature) {
      commit("SET_NEW_FEATURE", feature);
    },

    setSelectProject({ commit }, record) {
      commit("SET_SELECT_PROJECT", record);
    },

    setFlyLineLength({ commit }, flylineLength) {
      commit("SET_FLYLINE_LENGTH", flylineLength);
    },

    setRouteWebSocket({ commit }, route) {
      commit("SET_ROUTE_WEBSOCKET", route);
    },

    setWarningList ({ commit }, arr) {
      commit("SET_WARNINGLIST", arr);
    },

    setSelectWarning({ commit }, node){
      commit("SET_SELECT_WARNING", node);
    },
    setShowWarningDetialDialog({ commit }, bool){
      commit("SET_SHOW_DETAIL", bool);
    },
    setWsTaskInfo({ commit }, node){
      commit("SET_WS_TASK_INFO", node);
    },
    clearWsTaskInfo({ commit }){
      commit("CLEAR_WS_TASK_INFO");
    }
  },
  mutations: {
    SET_HOME_POINT: (state, point) => {
      state.homePoint = point;
    },

    SET_PLACEMARK_LIST: (state, { routeInfoId, placemarkList }) => {
      state.placemarkList = placemarkList;
      state.routeId = routeInfoId;
    },

    SET_SELECT_PROJECT: (state, record) => {
      state.selectProject = record;
    },

    SET_PROJECT_POINT: (state, point) => {
      state.projectPoint = point;
    },

    SET_HANDLER_TYPE: (state, payload) => {
      state.handlerType = payload;
    },

    SET_NEW_FEATURE: (state, feature) => {
      state.newFeature = feature;
    },

    SET_FLYLINE_LENGTH: (state, flylineLength) => {
      state.flylineLength = flylineLength;
    },

    SET_ROUTE_WEBSOCKET: (state, route) => {
      state.routeWebSocket = route;
    },
    /******************报警****************/
    SET_SELECT_WARNING: (state, node) => {
      state.selectWarning = node;
    },
    SET_WARNINGLIST: (state, arr) => {
      state.warningList = arr;
    },
    SET_SHOW_DETAIL: (state, bool) => {
      state.showWarningDetialDialog = bool;
    },

    /******************应急****************/
    SET_SELECT_EMERGRNCY_RECORD: (state, node) => {
      state.selectEmergencyRecord = node;
    },
    SET_EMERGRNCY_RECORDS_LIST: (state, arr) => {
      state.emergencyRecordsList = arr;
    },

    SET_WS_TASK_INFO: (state, node)=>{
      state.wsTaskInfo = node
    },
    CLEAR_WS_TASK_INFO: (state)=>{
      state.wsTaskInfo = {}
    },

    SET_NO_FLY_SWITCH: (state, val) => {
      state.noflySwitch = val;
    },
    SET_NO_FLY_CHECKLIST: (state, data) => {
      state.noflyCheckList = data;
    },
    SET_NO_FLY_TYPE_VALUE: (state, data) => {
      state.noflyTypeValue = data
    },
    SET_SELECT_ROUTE: (state, data) => {
      state.selectRoute = data
    }
  },
};

export default map;
