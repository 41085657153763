const inspection = {
    state: {
      inspectionList: [
      ],
      selectInspection: {}
    },
    actions: {
      setinspectionList ({ commit }, arr) {
        commit("SET_INSPECTION_LIST", arr);
      },
      setselectInspection({ commit }, node){
        commit("SET_SELECT_INSPECTION", node);
      },
    },
    mutations: {
      SET_SELECT_INSPECTION: (state, id) => {
        state.selectInspection = id;
      },
      SET_INSPECTION_LIST: (state, arr) => {
        state.inspectionList = arr;
      }
    }
  };
  
  export default inspection;
  