import request from '@/router/axios';

export const getList = (current, size, params) => {
  return request({
    url: '/api/cloud-user/fly-operator/page',
    method: 'get',
    params: {
      ...params,
      current,
      size,
    }
  })
}

export const getDetail = (id) => {
  return request({
    url: '/api/cloud-user/fly-operator/detail',
    method: 'get',
    params: {
      id
    }
  })
}

export const remove = (ids) => {
  return request({
    url: '/api/cloud-user/fly-operator/remove',
    method: 'delete',
    params: {
      ids,
    }
  })
}

export const add = (row) => {
  return request({
    url: '/api/cloud-user/fly-operator/add',
    method: 'post',
    data: row
  })
}

export const update = (row) => {
  return request({
    url: '/api/cloud-user/fly-operator/update',
    method: 'put',
    data: row
  })
}


export const turnOn = (ids) => {
  return request({
    url: '/api/cloud-user/fly-operator/turnOn',
    method: 'put',
    params: {
      ids
    }
  })
}

export const turnOff = (ids) => {
  return request({
    url: '/api/cloud-user/fly-operator/turnOff',
    method: 'put',
    params: {
      ids
    }
  })
}