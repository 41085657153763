import request from '@/router/axios';

export const getList = (current, size, params) => {
  return request({
    url: '/api/cloud-task/project-task/operatorTaskNoticePage',
    method: 'get',
    params: {
      ...params,
      current,
      size,
    }
  })
}

export const getDetail = (id) => {
  return request({
    url: '/api/project-task/operator-task/detail',
    method: 'get',
    params: {
      id
    }
  })
}

export const remove = (ids) => {
  return request({
    url: '/api/project-task/operator-task/remove',
    method: 'delete',
    params: {
      ids,
    }
  })
}

export const add = (row) => {
  return request({
    url: '/api/project-task/operator-task/add',
    method: 'post',
    data: row
  })
}

export const update = (row) => {
  return request({
    url: '/api/project-task/operator-task/update',
    method: 'put',
    data: row
  })
}

// 查询任务详情
export const detail = (params) => {
  return request({
    url: '/api/cloud-task/project-task/detail',
    method: 'get',
    params
  })
}

// 飞手状态变更
export const changeStatus = (data) => {
  return request({
    url: '/api/cloud-task/project-task/operatorTaskNoticeStatus',
    method: 'put',
    data
  })
} 