import request from "@/router/axios";

export const getAirportList = params => {
    return request({
        url: "/api/aos-gisservice/uavairport/list",
        method: "get",
        params
    });
};

export const getAirportDetail = params => {
    return request({
        url: "/api/aos-gisservice/uavairport/detail",
        method: "get",
        params
    });
};

export const deleteAirportItem = params => {
    return request({
        url: "/api/aos-gisservice/uavairport/remove",
        method: "post",
        params
    });
};

export const addEditAirport = params => {
    return request({
        url: "/api/aos-gisservice/uavairport/submit",
        method: "post",
        data: params
    });
};

export const editAirportEquipment = params => {
    return request({
        url: "/api/aos-gisservice/uavairport/updateUav",
        method: "post",
        data: params
    });
};

// 暂时不做机场导入
// export const importAirport = params => {
//     return request({
//         url: "/api/aos-gisservice/uavairport/import",
//         method: "post",
//         data: params
//     });
// };

export const getEquipmentList = params => {
    return request({
        url: "/api/cloud-equipment/equipment-management/page",
        method: "get",
        params
    });
};