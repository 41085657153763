import request from "@/router/axios";

// 列表
export const getList = (current, size, params) => {
  return request({
    url: "/api/aos-gisservice/recordtask/page",
    method: "get",
    params: {
      ...params,
      current,
      size,
    },
  });
};

// 更新
export const submit = (row) => {
  return request({
    url: "/api/aos-gisservice/recordtask/submit",
    method: "post",
    data: row,
  });
};

// 删除
export const remove = (ids) => {
  return request({
    url: "/api/aos-gisservice/recordtask/remove",
    method: "post",
    params: {
      ids,
    },
  });
};

// 详情
export const getDetail = (params) => {
  return request({
    url: "/api/aos-gisservice/recordtask/detail",
    method: "get",
    params
  });
};

// 获取部门列表
export const getDeptList = (current, size, params) => {
  return request({
    url: '/api/blade-system/dept/list',
    method: 'get',
    params: {
      ...params,
      current,
      size
    }
  })
}
