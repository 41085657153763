var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "tags" }, [
    _vm.subMenu && _vm.subMenu.length
      ? _c(
          "div",
          { staticClass: "tags-router" },
          [
            _vm._l(_vm.subMenu, function (item) {
              return [
                _c(
                  "span",
                  {
                    key: item[_vm.labelKey],
                    class: _vm.nowTagValue.includes(item.path)
                      ? "tags-router-item tags-router-item--active"
                      : "tags-router-item",
                    attrs: { index: item.id },
                    on: {
                      click: function ($event) {
                        return _vm.changeRouter(item)
                      },
                    },
                  },
                  [_vm._v("\n        " + _vm._s(item.name) + "\n      ")]
                ),
              ]
            }),
          ],
          2
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }