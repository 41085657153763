import request from "@/router/axios";

// 获取列表
export const getTreeList = (params) => {
  return request({
    url: "/api/group_element/treeList",
    method: "get",
    params,
  });
};

// 增加tree节点
export const addTreeNode = (data) => {
  return request({
    url: "/api/aos-gisservice/mapdir/submit",
    method: "post",
    data
  })
};

// 修改tree节点
export const updateTreeNode = (data) => {
  return request({
    url: "/api/aos-gisservice/mapdir/submit",
    method: "post",
    data
  })
};


// 新增文件夹
export const addGroup = (row) => {
  return request({
    url: "/api/group_element/add",
    method: "post",
    data: row,
  });
};


export const addLabel = (row)=>{
  return request({
    url: "/api/element/add",
    method: "post",
    data: row
  })
}

export const getExpand = (workSpaceId) => {
  return request({
    url: "/api/expand-node/detail",
    method: "get",
    params: {
      workSpaceId,
    },
  });
};

export const setExpand = (row)=>{
  return request({
    url: "/api/expand-node/save-or-update",
    method: "post",
    data: row
  })
}

export const removeGroup = (ids) => {
  return request({
    url: '/api/group_element/remove',
    method: 'delete',
    params: {
      ids,
    }
  })
}

export const removeNode = (ids) => {
  return request({
    url: '/api/element/remove',
    method: 'delete',
    params: {
      ids,
    }
  })
}