import request from "@/router/axios";

// 全局概况数据
export const getOverallSituation = (params) => {
    return request({
        url: "/api/aos-inspection/inspectionscreen/globalOverview",
        method: "get",
        params
    });
};
// 获取实时任务数据
export const getRealTimeTask = (params) => {
    return request({
        url: "/api/aos-inspection/inspectionscreen/realTimeTask",
        method: "get",
        params
    });
};
// 获取巡检统计数据
export const getInspectionRecord = (params) => {
    return request({
        url: "/api/aos-inspection/inspectionscreen/inspectionStatistics",
        method: "get",
        params
    });
};
// 获取报警记录列表
export const getAlarmRecordList = (params) => {
    return request({
        url: "/api/cloud-task/alarm-data/page",
        method: "get",
        params
    });
}
// 获取报警统计数据
export const getAlarmRecordStatistics = (params) => {
    return request({
        url: "/api/aos-inspection/inspectionscreen/alarmStatistics",
        method: "post",
        data: params
    });
};
// 获取当前租户的巡检对象
export const getUserInspectionObject = (params) => {
    return request({
        url: "/api/aos-gisservice/inspectionobject/listForTenant",
        method: "post",
        data: params
    });
};
// 获取当前租户的标注信息
export const getUserMarkerObject = (params) => {
    return request({
        url: "/api/aos-gisservice/mapmark/listForTenant",
        method: "post",
        data: params
    });
};
// 获取当前租户的机库数据
export const getUserHangarObject = (params) => {
    return request({
        url: "/api/cloud-equipment/uavhangar/listForTenant",
        method: "get",
        params
    });
};
// 获取当前租户的报警列表数据
export const getUserAlarmData = (params) => {
    return request({
        url: "/api/cloud-task/alarm-data/alarmListForTenant",
        method: "post",
        data: params
    });
}
// 根据id获取AI报警详情信息
export const getAIPendAlarmDetails = (params) => {
    return request({
        url: "/api/cloud-task/aiidentifyalarm/detail",
        method: "get",
        params
    });
}