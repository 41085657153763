var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "top-tabs" },
    [
      _vm._l(_vm.menu, function (item) {
        return [
          item.code !== "system"
            ? _c(
                "div",
                {
                  key: item.id,
                  class: _vm.isActivePath(item)
                    ? "top-tabs-item top-tabs-item--active"
                    : "top-tabs-item",
                  on: {
                    click: function ($event) {
                      return _vm.changePage(item)
                    },
                  },
                },
                [_vm._v("\n      " + _vm._s(item.name) + "\n    ")]
              )
            : _vm._e(),
        ]
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }