var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "basic-container",
      class: { "basic-container--block": _vm.block },
      style: _vm.styleName,
    },
    [
      _c(
        "el-card",
        { staticClass: "basic-container__card" },
        [_vm._t("default")],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }