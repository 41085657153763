/*
 * @Description:
 * @Autor: wq
 * @Date: 2022-06-25 09:17:21
 * @watermark: 成都傲势科技
 * @LastEditors: wq
 */
import request from "@/router/axios";

// 获取项目列表
export const forbiddenAreaQuery = (params) => {
  return request({
    url: "/api/forbidden-fly-area/geoPage",
    method: "get",
    params,
  });
};

export const getProjectList = (params) => {
  return request({
    url: "/api/screen-info/item-list",
    method: "get",
    params,
  });
};

export const getTaskList = (params) => {
  return request({
    url: "/api/screen-info/mission-list",
    method: "get",
    params,
  });
};

export const getTaskPSImageParams = (params) => {
  return request({
    url: "/api/mapping-config-entity/detail",
    method: "get",
    params,
  });
};

export const updateTaskPSImageParams = (data) => {
  return request({
    url: "/api/mapping-config-entity/update",
    method: "put",
    data,
  });
};

export const getMapRecord = (page, size, missionId) => {
  return request({
    url: "/api/mapping-record-entity/page",
    method: "get",
    params: {
      page,
      size,
      missionId,
    },
  });
};

export const getMapTaskList = (params) => {
  return request({
    url: "/api/mapping-config-entity/mission-plan",
    method: "get",
    params,
  });
};

export const getJointType = () => {
  return request({
    url: "/api/blade-system/dict/dictionary?code=splicingType",
    method: "get",
  });
};

export const startMappping = (missionId) => {
  return request({
    url: "/api/mapping-server/start-mapping",
    method: "get",
    params: {
      missionId,
    },
  });
};

export const getJointSchedule = (missionId) => {
  return request({
    url: "/api/mapping-server/mapping-progress",
    method: "get",
    params: {
      missionId,
    },
  });
};

export const getJointData = (missionId) => {
  return request({
    url: "/api/mapping-server/mapping-result",
    method: "get",
    params: {
      missionId,
    },
  });
};

export const downLoadFile = (missionId) => {
  return request({
    url: "/api/mapping-server/mapping-progress-result",
    method: "get",
    params: {
      missionId,
    },
  });
};

export const downLoadPic = (params) => {
  return request({
    url: "/api/mapping-record-entity/detail",
    method: "get",
    params,
  });
};

export const updateMapLabel = (data) => {
  return request({
    url: "/api/cloud-task/projecttaginfo/submit",
    method: "post",
    data,
  });
};

// 保存油井
export const saveOilwellpoint = (data) => {
  return request({
    url: "/api/aos-gisservice/oilwellpoint/submit",
    method: "post",
    data,
  });
};

// 获取油井详情
export const getOilwellpoint = (params) => {
  return request({
    url: "/api/aos-gisservice/oilwellpoint/detail",
    method: "get",
    params,
  });
};

// 保存油井管线
export const saveOilline = (data) => {
  return request({
    url: "/api/aos-gisservice/oilline/submit",
    method: "post",
    data,
  });
};

// 获取管线详情/oilline/
export const getOilline = (params) => {
  return request({
    url: "/api/aos-gisservice/oilline/detail",
    method: "get",
    params,
  });
};

// 删除实体
export const deleteOilwellpoint= (params) => {
  return request({
    url: "/api/aos-gisservice/oilwellpoint/remove",
    method: "post",
    params,
  });
};

// 删除实体
export const deleteOilline = (params) => {
  return request({
    url: "/api/aos-gisservice/oilline/remove",
    method: "post",
    params,
  });
};

// 编辑几何体
// export const updateMapEntity= (data) => {
//   return request({
//     url: "/api/cloud-task/mappatrolmark/update",
//     method: "post",
//     data,
//   });
// };

// 显示和隐藏几何体
export const updateVisibility = (params) => {
  return request({
    url: "/api/cloud-task/mappatrolmark/updateVisibility",
    method: "post",
    params,
  });
};

/**
 * 禁飞区数据查询（区域方式）
 * @param {*} params
 * @returns
 */
 export const getProhibitData = params => {
  return request({
    url:"/api/cloud-task/forbidden-fly-area/areaQuery",
    method: "post",
    data: params
  })
};

// 根据taskId获取航向
export const getRouteByTaskId = (params) => {
  return request({
    url: "/api/aos-gisservice/routeline/getRouteByTaskId",
    method: "post",
    params,
  });
};

// 修改石油井的高级设置
export const setOilwellpoint = (data) => {
  return request({
    url: "/api/aos-gisservice/oilwellpoint/advancedSet",
    method: "post",
    data,
  });
};

// 修改石油管线的高级设置
export const setOilline = (data) => {
  return request({
    url: "/api/aos-gisservice/oilline/advancedSet",
    method: "post",
    data,
  });
};