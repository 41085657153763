var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "avue-contail",
      class: { "avue--collapse": _vm.isCollapse },
    },
    [
      _c(
        "div",
        { staticClass: "avue-header", staticStyle: { color: "#FFF" } },
        [_c("top", { ref: "top" }), _c("tags")],
        1
      ),
      _c("div", { staticClass: "avue-layout" }, [
        _c(
          "div",
          { staticClass: "avue-main" },
          [
            _c(
              "keep-Alive",
              { attrs: { include: _vm.include } },
              [_c("router-view")],
              1
            ),
          ],
          1
        ),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }