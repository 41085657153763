import request from '@/router/axios';
// 新增航线
export const submitRoute = data => {
  return request({
    url: "/api/aos-gisservice/routemanageline/submit",
    method: "post",
    data
  });
};

// 获取航线列表
export const getRouteList = params => {
  return request({
    url: "/api/aos-gisservice/routemanageline/page",
    method: "get",
    params
  });
};

// 删除航线
export const removeRoute = params => {
  return request({
    url: "/api/aos-gisservice/routemanageline/remove",
    method: "post",
    params
  });
};

// 获取部门列表
export const getDeptList = (current, size, params) => {
  return request({
    url: '/api/blade-system/dept/list',
    method: 'get',
    params: {
      ...params,
      current,
      size
    }
  })
}


export const getEquipment = params => {
  return request({
    url: '/api/cloud-equipment/manufacturer/page',
    method: 'get',
    params
  })
}

export const getequipmentManagement= params => {
  return request({
    url: '/api/cloud-equipment/equipment-management/page',
    method: 'get',
    params
  })
}
