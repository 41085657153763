import routesMap from './config'
import Layout from '@/page/index/'
let RouterPlugin = function () {
  this.$router = null;
  this.$store = null;

};
RouterPlugin.install = function (vue, option = {}) {
  this.$router = option.router;
  this.$store = option.store;
  this.$vue = new vue({ i18n: option.i18n });

  // 这个的作用是 为了检查出网页链接，因为本项目用到了 iframe
  function isURL(s) {
    if (s.includes('html')) return true;
    return /^http[s]?:\/\/.*/.test(s)
  }

  // 将参数处理为参数的形式拼接
  function objToform(obj) {
    let result = [];
    Object.keys(obj).forEach(ele => {
      result.push(`${ele}=${obj[ele]}`);
    })
    return result.join('&');
  }

  this.$router.$avueRouter = {
    //全局配置
    $website: this.$store.getters.website,
    group: '',
    meta: {},
    safe: this,
    // 设置标题
    setTitle: (title) => {
      // const defaultTitle = this.$vue.$t('title');
      // title = title ? `${title}-${defaultTitle}` : defaultTitle;
      document.title = title;
    },
    closeTag: (value) => {
      let tag = value || this.$store.getters.tag;
      if (typeof value === 'string') {
        tag = this.$store.getters.tagList.filter(ele => ele.value === value)[0]
      }
      this.$store.commit('DEL_TAG', tag)
    },
    generateTitle: (title, key) => {
      if (!key) return title;
      const hasKey = this.$vue.$te('route.' + key)
      if (hasKey) {
        // $t :this method from vue-i18n, inject in @/lang/index.js
        const translatedTitle = this.$vue.$t('route.' + key)

        return translatedTitle
      }
      return title
    },
    //处理路由
    getPath: function (params) {
      let {src} = params;
      let result = src || '/';
      if (isURL(src)) {
        result = `/myiframe/urlPath?${objToform(params)}`;
      }
      return result;
    },
    //正则处理路由
    vaildPath: function (list, path) {
      let result = false;
      list.forEach(ele => {
        if (new RegExp("^" + ele + ".*", "g").test(path)) {
          result = true
        }

      })
      return result;
    },
    //设置路由值
    getValue: function (route) {
      let value = "";
      if (route.query.src) {
        value = route.query.src;
      } else {
        value = route.path;
      }
      return value;
    },
    //动态路由
    // 路由是专门的一个接口获取
      /**
       * aMenu: 接受到的动态路由数据 menu的结构外层有父级path 里面有一个childen 记录页面的路由
       * first: 为了区分外界 调用formatRoutes 和 当前文件调用 formatRoutes
      */
    formatRoutes: function (aMenu = []) {
      let list = createRoutes(aMenu)
      this.safe.$router.addRoutes(list)
    }
  }
}
export default RouterPlugin;

function createRoutes(routes, level){
  if (!level) {
    level = 1
  }
  let list = []
  for (var i = 0; i < routes.length; i++) {
    const item = routes[i]
    if (!routesMap[item.path]) {
      continue
    }
    let router = Object.assign({}, routesMap[item.path])
    let paths = item.path.split('/')
    if(!paths[0]) {
      paths.shift()
    }
    router.path = level === 1? `/${paths[level - 1]}`: paths[level - 1]
    router.name = item.code
    router.meta.title = item.name
    if (item.children && Array.isArray(item.children) && item.children.length > 0) {
      router.children = createRoutes(item.children, level + 1)
    }
    list.push(router)
  }
  return list
}
