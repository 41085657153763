import request from '@/router/axios';

export const getMeteorologyInfo = (params) => {
  return request({
    url: '/api/cloud-task/meteorology/getMeteorologyInfo',
    method: 'get',
    params
  })
}

export const getLocation = () => {
  return request({
    url: '/api/cloud-task/meteorology/getUserRealIP',
    method: 'get'
  })
}
