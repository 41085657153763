var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "avue-top" }, [
    _c(
      "div",
      { staticClass: "top-bar__left" },
      [_c("TopLeft"), _c("TopTabs")],
      1
    ),
    _c(
      "div",
      { staticClass: "top-bar__right" },
      [
        _c(
          "el-tooltip",
          {
            attrs: {
              effect: "dark",
              content: _vm.$t("navbar.lock"),
              placement: "bottom",
            },
          },
          [_c("div", { staticClass: "top-bar__item help" })]
        ),
        _c("img", {
          staticClass: "top-bar__img",
          attrs: { src: _vm.userInfo.avatar || _vm.headPic },
        }),
        _c(
          "el-dropdown",
          [
            _c("span", { staticClass: "el-dropdown-link" }, [
              _vm._v(
                "\n        " + _vm._s(_vm.userInfo.nick_name) + "\n        "
              ),
              _c("i", { staticClass: "el-icon-arrow-down el-icon--right" }),
            ]),
            _c(
              "el-dropdown-menu",
              { attrs: { slot: "dropdown" }, slot: "dropdown" },
              [
                _c(
                  "el-dropdown-item",
                  [
                    _c("router-link", { attrs: { to: "/" } }, [
                      _vm._v(_vm._s(_vm.$t("navbar.dashboard"))),
                    ]),
                  ],
                  1
                ),
                _c(
                  "el-dropdown-item",
                  [
                    _c("router-link", { attrs: { to: "/useInfo/index" } }, [
                      _vm._v(_vm._s(_vm.$t("navbar.userinfo"))),
                    ]),
                  ],
                  1
                ),
                _c(
                  "el-dropdown-item",
                  {
                    attrs: { divided: "" },
                    nativeOn: {
                      click: function ($event) {
                        return _vm.logout($event)
                      },
                    },
                  },
                  [_vm._v(_vm._s(_vm.$t("navbar.logOut")) + "\n        ")]
                ),
              ],
              1
            ),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }