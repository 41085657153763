import request from "@/router/axios";

export const getStationList = (params) => {
    return request({
        url: '/api/aos-gisservice/basestation/page',
        method: 'get',
        params
    });
}

export const addEditStation = (params) => {
    return request({
        url: '/api/aos-gisservice/basestation/submit',
        method: 'post',
        data: params
    });
}

export const deleteStation = (params) => {
    return request({
        url: '/api/aos-gisservice/basestation/remove',
        method: 'post',
        params
    });
}