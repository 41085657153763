import request from "@/router/axios";

export const getList = (current, size, params) => {
  delete params.$app;
  delete params.$tenantId;
  return request({
    url: "/api/blade-system/role/list",
    method: "get",
    params: {
      ...params,
      current,
      size,
    },
  });
};
export const grantTree = (params) => {
  return request({
    url: "/api/blade-system/menu/grant-tree",
    method: "get",
    params: {
      ...params,
    },
  });
};

export const grant = (roleIds, menuIds, dataScopeIds, apiScopeIds) => {
  return request({
    url: "/api/blade-system/role/grant",
    method: "post",
    data: {
      roleIds,
      menuIds,
      dataScopeIds,
      apiScopeIds,
    },
  });
};

export const remove = (ids) => {
  return request({
    url: "/api/blade-system/role/remove",
    method: "post",
    params: {
      ids,
    },
  });
};

export const add = (row) => {
  return request({
    url: "/api/blade-system/role/submit",
    method: "post",
    data: row,
  });
};

export const update = (row) => {
  return request({
    url: "/api/blade-system/role/submit",
    method: "post",
    data: row,
  });
};

export const getRole = (roleIds) => {
  return request({
    url: "/api/blade-system/menu/role-tree-keys",
    method: "get",
    params: {
      roleIds,
    },
  });
};

export const getRoleTree = (tenantId) => {
  return request({
    url: "/api/blade-system/role/tree",
    method: "get",
    params: {
      tenantId,
    },
  });
};

export const getRoleTreeById = (roleId) => {
  return request({
    url: "/api/blade-system/role/tree-by-id",
    method: "get",
    params: {
      roleId,
    },
  });
};

export const getAppRoleListByTenant = (tenantID,app) => {
  return request({
    url: "/api/blade-system/role/getAppRoleListByTenant",
    method: "post",
    params: {
      tenantID,
      app
    },
  });
};

export const getRoleTreeByApp = () => {
  return request({
    url: "/api/blade-system/role/tree-by-id?app=aos-schedule",
    method: "get"
  });
};

