import request from "@/router/axios";

export const getInspectionList = (params) => {
  return request({
    url: "/api/aos-inspection/oilinspectionrecords/page",
    method: "get",
    params,
  });
};

export const getInspectionDetail = (params) => {
  return request({
    url: "/api/aos-inspection/oilinspectionrecords/detail",
    method: "get",
    params,
  });
};

export const submitInspectionrecords = (data) => {
  return request({
    url: "/api/aos-inspection/oilinspectionrecords/submit",
    method: "post",
    data,
  });
};

export const removeInspectionrecords = (params) => {
  return request({
    url: "/api/aos-inspection/oilinspectionrecords/remove",
    method: "post",
    params,
  });
};