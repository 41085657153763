const route = {
  state: {
    /**
     * 航线编辑的详情参数
     */
    routeDetail: {}, //  {deletId, groupId}

    /**
     * 航线是否处于编辑状态
     */
    routeVisible: false,
  },
  actions: {
    setRouteDetail({ commit }, payload) {
      commit("SET_ROUTE", payload);
    },

    setRouteVisible({ commit }, payload) {
      commit("SET_ROUTEVISIBLE", payload);
    },
  },
  mutations: {
    SET_ROUTE: (state, payload) => {
      state.routeDetail = payload;
    },

    SET_ROUTEVISIBLE: (state, payload) => {
      state.routeVisible = payload;
    },
  },
};

export default route;
