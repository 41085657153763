export default{
    /*********************飞行中心**************************/
    /*****一级路由*******/
    // 飞行中心
    'mission-center': {
        redirect : '/mission-center/item-manager-config',
        meta: {
            keepAlive: false,
            i18n: 'info'
        },
        component: ()=> import('@/page/index/')
    },
        /*****二级路由*******/
        // 项目管理
        '/mission-center/item-manager-config': {
            props: {
                modifyType: 0,
            },
            meta: {
                keepAlive: false,
                i18n: 'info'
            },
            component: ()=> import('@/views/mission-center/projectItem/item')
        },
            /*****三级路由*******/
            // 项目详情
            '/mission-center/item-manager-config/project_detail': {
                props: route=>{
                    return route.query.action? {
                        modifyType: 0
                    }: {
                        modifyType: 1
                    }
                },
                meta: {
                    keepAlive: false,
                    i18n: 'info'
                },
                component: ()=> import('@/views/mission-center/projectItem/operationBar/projectManage')
            },
            // 任务
            '/mission-center/item-manager-config/project_task': {
                meta: {
                    keepAlive: false,
                    i18n: 'info'
                },
                component: ()=> import('@/views/mission-center/projectItem/operationBar/taskUav')
            },
            // 标注
            '/mission-center/item-manager-config/mark_manager': {
                props: {
                    modifyType: 2,
                },
                meta: {
                    keepAlive: false,
                    i18n: 'info'
                },
                component: ()=> import('@/views/mission-center/projectItem/operationBar/markPage')
            },
            // 大屏
            '/mission-center/item-manager-config/project_screen': {
                props: {
                    modifyType: 5,
                },
                meta: {
                    keepAlive: false,
                    i18n: 'info'
                },
                component: ()=> import('@/views/mission-center/projectItem/operationBar/screenManage')
            },
            // 报警
            '/mission-center/item-manager-config/project_aram': {
                props: {
                    modifyType: 6,
                },
                meta: {
                    keepAlive: false,
                    i18n: 'info'
                },
                component: ()=> import('@/views/mission-center/projectItem/operationBar/warningPanel')
            },
            // AI识别报警
            '/mission-center/item-manager-config/project-AI-identify': {
                props: {
                    modifyType: 16,
                },
                meta: {
                    keepAlive: false,
                    i18n: 'info'
                },
                component: ()=> import('@/views/mission-center/projectItem/operationBar/AI-identify')
            },
        /*****二级路由*******/
        // 巡检鉴权
        '/mission-center/projectAuth': {
            meta: {
                keepAlive: false,
                i18n: 'info'
            },
            component:  ()=> import('@/views/mission-center/projectauth/index')
        },
        // 巡检管理
        '/mission-center/inspection-records': {
            meta: {
                keepAlive: false,
                i18n: 'info'
            },
            component:  ()=> import('@/views/mission-center/inspectionItem/index')
        },
        // 航线管理
        '/mission-center/route-manager': {
            props: {
                modifyType: 15,
            },
            meta: {
                keepAlive: false,
                i18n: 'info'
            },
            component:  ()=> import('@/views/mission-center/routeManager/index')
        },
        /*****二级路由*******/
        // 禁飞区管理
        '/mission-center/no-fly-zone-manager': {
            redirect : '/mission-center/no-fly-zone-manager/no-fly-zone',
            meta: {
                keepAlive: false,
                i18n: 'info'
            },
            component: ()=> import('@/views/mission-center/noFlyZoneManager/index')
        },
            /*****三级路由*******/
            // 禁飞区
            '/mission-center/no-fly-zone-manager/no-fly-zone': {
                props: {
                    modifyType: 8,
                },
                meta: {
                    keepAlive: false,
                    i18n: 'info'
                },
                component: ()=> import('@/views/mission-center/noFlyZoneManager/operationBar/noFlyZone')
            },
            // 临时
            '/mission-center/no-fly-zone-manager/temporary': {
                props: {
                    modifyType: 9,
                },
                meta: {
                    keepAlive: false,
                    i18n: 'info'
                },
                component: ()=> import('@/views/mission-center/noFlyZoneManager/operationBar/temporary')
            },
            // 限高
            '/mission-center/no-fly-zone-manager/height-limit': {
                props: {
                    modifyType: 10,
                },
                meta: {
                    keepAlive: false,
                    i18n: 'info'
                },
                component: ()=> import('@/views/mission-center/noFlyZoneManager/operationBar/heightLimit')
            },
            // 缓冲区
            '/mission-center/no-fly-zone-manager/buffer-zone': {
                props: {
                    modifyType: 11,
                },
                meta: {
                    keepAlive: false,
                    i18n: 'info'
                },
                component: ()=> import('@/views/mission-center/noFlyZoneManager/operationBar/bufferZone')
            },
            // 自定义禁飞区
            '/mission-center/no-fly-zone-manager/custom-zone': {
                props: {
                    modifyType: 12,
                },
                meta: {
                    keepAlive: false,
                    i18n: 'info'
                },
                component: ()=> import('@/views/mission-center/noFlyZoneManager/operationBar/customZone')
            },
        /*****二级路由*******/
        // 机场管理
        '/mission-center/airport-manager': {
            props: {
                modifyType: 13,
            },
            meta: {
                keepAlive: false,
                i18n: 'info'
            },
            component: ()=> import('@/views/mission-center/airportManager/index')
        },
    /*********************设备中心**************************/
    /*****一级路由*******/
    // 设备中心
    'equipment': {
        meta: {
            keepAlive: false,
            i18n: 'info'
        },
        component: ()=> import('@/page/index/')
    },
        /*****二级路由*******/
        // 设备管理
        '/equipment/equipment-manager': {
            meta: {
                keepAlive: false,
                i18n: 'info'
            },
            component:  ()=> import('@/views/equipment/equipment-manager')
        },
        // 品牌管理
        '/equipment/brandManagement': {
            meta: {
                keepAlive: false,
                i18n: 'info'
            },
            component:  ()=> import('@/views/equipment/brandManagement')
        },
        // 基站管理
        '/equipment/baseStationManagement': {
            meta: {
                keepAlive: false,
                i18n: 'info'
            },
            component:  ()=> import('@/views/equipment/baseStationManagement')
        },
        // 机库管理
        '/equipment/hangarManagement': {
            props: {
                modifyType: 14,
            },
            meta: {
                keepAlive: false,
                i18n: 'info'
            },
            component:  ()=> import('@/views/equipment/hangarManagement/index')
        },
    /*********************用户中心**************************/
    /*****一级路由*******/
    'user-center': {
        meta: {
            keepAlive: false,
            i18n: 'info'
        },
        component: ()=> import('@/page/index/')
    },
        /*****二级路由*******/
        // 用户管理
        '/user-center/user': {
            meta: {
                keepAlive: false,
                i18n: 'info'
            },
            component:  ()=> import('@/views/user-center/user')
        },
        // 角色管理
        '/user-center/role': {
            meta: {
                keepAlive: false,
                i18n: 'info'
            },
            component:  ()=> import('@/views/user-center/role')
        },
        // 团队管理
        '/user-center/dept': {
            meta: {
                keepAlive: false,
                i18n: 'info'
            },
            component:  ()=> import('@/views/user-center/dept')
        },
        // 飞手管理
        '/user-center/flyOperator': {
            meta: {
                keepAlive: false,
                i18n: 'info'
            },
            component:  ()=> import('@/views/user-center/flyOperator')
        },
        // 应急队伍
        '/user-center/emergencyranks': {
            meta: {
                keepAlive: false,
                i18n: 'info'
            },
            component:  ()=> import('@/views/user-center/emergencyranks')
        },
    /*********************林草巡检**************************/
    /*****一级路由*******/
    // 林草巡检
    'forest-grass': {
        redirect : '/forest-grass/FG-Inspection',
        meta: {
            keepAlive: false,
            i18n: 'info'
        },
        component: ()=> import('@/page/index/')
    },
        /*****二级路由*******/
        // 项目管理
        '/forest-grass/FG-Inspection': {
            props: {
                modifyType: 0,
            },
            meta: {
                keepAlive: false,
                i18n: 'info'
            },
            component: ()=> import('@/views/forest-grass/projectItem/item')
        },
            /*****三级路由*******/
            // 总览
            '/forest-grass/FG-Inspection/FG_detail': {
                props: route=>{
                    return route.query.action? {
                        modifyType: 0
                    }: {
                        modifyType: 1
                    }
                },
                meta: {
                    keepAlive: false,
                    i18n: 'info'
                },
                component: ()=> import('@/views/forest-grass/projectItem/operationBar/projectManage')
            },
            // 标注
            '/forest-grass/FG-Inspection/FG-mark': {
                props: route=>{
                    return {
                        modifyType: 2
                    }
                },
                meta: {
                    keepAlive: false,
                    i18n: 'info'
                },
                component: ()=> import('@/views/forest-grass/projectItem/operationBar/markPage')
            },
            // 林斑
            '/forest-grass/FG-Inspection/forest-spot': {
                props: {
                    modifyType: 6,
                },
                meta: {
                    keepAlive: false,
                    i18n: 'info'
                },
                component: ()=> import('@/views/forest-grass/projectItem/operationBar/warningPanel')
            },
            // 大屏
            '/forest-grass/FG-Inspection/FG_screen': {
                props: {
                    modifyType: 5,
                },
                meta: {
                    keepAlive: false,
                    i18n: 'info'
                },
                component: ()=> import('@/views/forest-grass/projectItem/operationBar/screenManage')
            },
            // 报警
            '/forest-grass/FG-Inspection/FG_aram': {
                props: {
                    modifyType: 6,
                },
                meta: {
                    keepAlive: false,
                    i18n: 'info'
                },
                component: ()=> import('@/views/forest-grass/projectItem/operationBar/warningPanel')
            },
         // 巡检管理
        '/forest-grass/inspection-records': {
            meta: {
                keepAlive: false,
                i18n: 'info'
            },
            component:  ()=> import('@/views/forest-grass/item-manager-config/projectTask')
        },
    /*********************油气巡检**************************/
    /*****一级路由*******/
    // 油气巡检
    'oil-gas': {
        redirect : '/oil-gas/OG-Inspection',
        meta: {
            keepAlive: false,
            i18n: 'info'
        },
        component: ()=> import('@/page/index/')
    },
        /*****二级路由*******/
        // 项目管理
        '/oil-gas/OG-Inspection': {
            props: {
                modifyType: 0,
            },
            meta: {
                keepAlive: false,
                i18n: 'info'
            },
            component: ()=> import('@/views/oil-gas/projectItem/item')
        },
            /*****三级路由*******/
            // 总览
            '/oil-gas/OG-Inspection/OG_detail': {
                props: route=>{
                    return route.query.action? {
                        modifyType: 0
                    }: {
                        modifyType: 1
                    }
                },
                meta: {
                    keepAlive: false,
                    i18n: 'info'
                },
                component: ()=> import('@/views/oil-gas/projectItem/operationBar/projectManage')
            },
            // 巡检
            '/oil-gas/OG-Inspection/OG_inspection_Object': {
                props: {
                    modifyType: 3,
                },
                meta: {
                    keepAlive: false,
                    i18n: 'info'
                },
                component: ()=> import('@/views/oil-gas/projectItem/operationBar/inspectionPage')
            },
            // 大屏
            '/oil-gas/OG-Inspection/OG_screen': {
                props: {
                    modifyType: 5,
                },
                meta: {
                    keepAlive: false,
                    i18n: 'info'
                },
                component: ()=> import('@/views/oil-gas/projectItem/operationBar/screenManage')
            },
            // 报警
            '/oil-gas/OG-Inspection/OG_aram': {
                props: {
                    modifyType: 6,
                },
                meta: {
                    keepAlive: false,
                    i18n: 'info'
                },
                component: ()=> import('@/views/oil-gas/projectItem/operationBar/warningPanel')
            },
            // AI识别报警
            '/oil-gas/OG-Inspection/OG-AI-identify': {
                props: {
                    modifyType: 16,
                },
                meta: {
                    keepAlive: false,
                    i18n: 'info'
                },
                component: ()=> import('@/views/oil-gas/projectItem/operationBar/AI-identify')
            },
        // 巡检记录
        '/oil-gas/OG-records': {
            meta: {
                keepAlive: false,
                i18n: 'info'
            },
            component:  ()=> import('@/views/oil-gas/inspectionItem/index')
        },
        // 巡检大屏
        '/oil-gas/OG-screen': {
            props: {
                modifyType: 0,
            },
            meta: {
                keepAlive: false,
                i18n: 'info'
            },
            component:  ()=> import('@/views/oil-gas/screen/index')
        },
    /*********************应急**************************/
    /*****一级路由*******/
    'emergency': {
        redirect : '/emergency/emergency-task',
        meta: {
            keepAlive: false,
            i18n: 'info'
        },
        component: ()=> import('@/page/index/')
    },
        /*****二级路由*******/
        // 应急任务
        '/emergency/emergency-task': {
            props: {
                modifyType: 0,
            },
            meta: {
                keepAlive: false,
                i18n: 'info'
            },
            component: ()=> import('@/views/emergency/projectItem/item')
        },
            /*****三级路由*******/
            // 详情
            '/emergency/emergency-task/ET-detail': {
                props: route=>{
                    return route.query.action? {
                        modifyType: 0
                    }: {
                        modifyType: 2
                    }
                },
                meta: {
                    keepAlive: false,
                    i18n: 'info'
                },
                component: ()=> import('@/views/emergency/projectItem/operationBar/projectManage')
            },
            // 记录
            '/emergency/emergency-task/ET-record': {
                // 独立地图页面
                props: {
                    // modifyType: 4,
                },
                meta: {
                    keepAlive: false,
                    i18n: 'info'
                },
                component: ()=> import('@/views/emergency/projectItem/operationBar/emergencyRecordsBar')
            },
            // 大屏
            '/emergency/emergency-task/ET-screen': {
                props: {
                    modifyType: 5,
                },
                meta: {
                    keepAlive: false,
                    i18n: 'info'
                },
                component: ()=> import('@/views/emergency/projectItem/operationBar/screenManage')
            }
}