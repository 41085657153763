import { render, staticRenderFns } from "./index.vue?vue&type=template&id=fcac2578"
var script = {}
import style0 from "./index.vue?vue&type=style&index=0&id=fcac2578&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/mnt/aos/volumes/webworkspace/cloud/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('fcac2578')) {
      api.createRecord('fcac2578', component.options)
    } else {
      api.reload('fcac2578', component.options)
    }
    module.hot.accept("./index.vue?vue&type=template&id=fcac2578", function () {
      api.rerender('fcac2578', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/loadingview/index.vue"
export default component.exports