import Vue from 'vue'
import VueI18n from 'vue-i18n'
// 引入element的语言包
import ElementLocale from "element-ui/lib/locale";
import elementEnLocale from 'element-ui/lib/locale/lang/en' // element-ui lang
import elementZhLocale from 'element-ui/lib/locale/lang/zh-CN'// element-ui lang
// 引入自己应用需要支持语言配置文件
import enLocale from './en'
import zhLocale from './zh'
import { getStore } from '@/util/store'
Vue.use(VueI18n)
const Avue = window.AVUE;
const messages = {
  en: {
    ...enLocale,
    ...elementEnLocale,
    ...Avue.locale.en,
  },
  zh: {
    ...zhLocale,
    ...elementZhLocale,
    ...Avue.locale.zh,
  }
}

const i18n = new VueI18n({
  locale: getStore({ name: 'language' }) || 'zh',
  messages
})
ElementLocale.i18n((key, value) => i18n.t(key, value));
export default i18n