import Layout from '@/page/index/'

export default [
  // 登录
  {
    path: '/login',
    name: '登录页',
    component: () =>
      import( /* webpackChunkName: "page" */ '@/page/login/index'),
    meta: {
      keepAlive: false,
      title: '登录'
    }
  },
  // 直接登录页面
  {
    path: '/no-login',
    name: '自动登录',
    component: () =>
      import( /* webpackChunkName: "page" */ '@/page/noLogin/index'),
    meta: {
      keepAlive: false,
      title: '自动登录'
    }
  },
  {
    path: "/screenLogin",
    name: '石油大屏登录',
    component: () =>
      import( /* webpackChunkName: "page" */ '@/page/login/index'),
    meta: {
      keepAlive: false,
      title: '石油大屏登录'
    }
  },
  // 锁屏
  {
    path: '/lock',
    name: '锁屏页',
    component: () =>
      import( /* webpackChunkName: "page" */ '@/page/lock/index'),
    meta: {
      keepAlive: false,
      title: '锁屏保护'
    }
  },
  // 404
  {
    path: '/404',
    component: () =>
      import( /* webpackChunkName: "page" */ '@/components/error-page/404'),
    name: '404',
    meta: {
      keepAlive: false,
      title: '404'
    }

  },
  // 403
  {
    path: '/403',
    component: () =>
      import( /* webpackChunkName: "page" */ '@/components/error-page/403'),
    name: '403',
    meta: {
      keepAlive: false,
      title: '403'
    }
  },
  // 500
  {
    path: '/500',
    component: () =>
      import( /* webpackChunkName: "page" */ '@/components/error-page/500'),
    name: '500',
    meta: {
      keepAlive: false,
      title: '500'
    }
  },
  // 主页
  {
    path: '/',
    name: '主页',
    redirect: '/mission-center'
  },
  // iframe
  {
    path: '/myiframe',
    component: Layout,
    redirect: '/myiframe',
    meta: {
      keepAlive: false,
      title: ''
    },
    children: [{
      path: ":routerPath",
      name: 'iframe',
      component: () => import( /* webpackChunkName: "page" */ '@/components/iframe/main'),
      props: true
    }]

  },
  // 大屏
  {
    path: "/screen-datav",
    name: "大屏",
    component: () =>
      import(/* webpackChunkName: "page" */ "@/views/screen/index"),
    meta: {
      keepAlive: false,
      title: '大屏可视化数据'
    },
  },
  // 个人信息
  {
    path: '/useInfo',
    component: Layout,
    redirect: '/useInfo',
    children: [{
      path: "index",
      name: 'useInfoIndex',
      meta: {
        i18n: "info",
        title: '个人信息'
      },
      component: () => import( /* webpackChunkName: "page" */ '@/views/user-center/userinfo'),
      props: true
    }]

  },
  // 石油巡检大屏(独立页面大屏)
  {
    path: "/screen-oil",
    name: "石油大屏",
    redirect: '/screen-oil/overall-situation',
    component: () => import(/* webpackChunkName: "page" */ "@/page/screen/index"),
    meta: {
      keepAlive: false,
      title: '大屏可视化数据'
    },
    children: [
      {
        name: "全局概况",
        path: 'overall-situation',
        component: () => import(/* webpackChunkName: "page" */ "@/page/screen/components/overall-situation/index"),
        meta: {
          keepAlive: false,
          title: '大屏可视化数据'
        },
      },
      {
        name: "实时任务",
        path: 'real-time',
        component: () => import(/* webpackChunkName: "page" */ "@/page/screen/components/real-time/index"),
        meta: {
          keepAlive: false,
          title: '大屏可视化数据'
        },
      },
      {
        name: "巡检记录",
        path: 'inspection-record',
        component: () => import(/* webpackChunkName: "page" */ "@/page/screen/components/inspection-record/index"),
        meta: {
          keepAlive: false,
          title: '大屏可视化数据'
        },
      },
      {
        name: "报警记录",
        path: 'alarm-record',
        component: () => import(/* webpackChunkName: "page" */ "@/page/screen/components/alarm-record/index"),
        meta: {
          keepAlive: false,
          title: '大屏可视化数据'
        },
      },
    ]
  },
]
