import request from '@/router/axios';


export const getProjectAuthList = (current, size, params) => {
  return request({
    url: '/api/cloud-task/projectauth/page',
    method: 'get',
    params: {
      ...params,
      current,
      size,
    }
  });
}

export const getDetail = (id) => {
  return request({
    url: '/api/cloud-task/projectauth/detail',
    method: 'get',
    params: {
      projectId: id
    }
  })
}

export const remove = (ids) => {
  return request({
    url: '/api/cloud-task/projectauth/remove',
    method: 'post',
    params: {
      ids,
    }
  })
}

export const add = (row) => {
  return request({
    url: '/api/cloud-task/projectauth/submit',
    method: 'post',
    data: row
  })
}

export const update = (row) => {
  return request({
    url: '/api/cloud-task/projectauth/submit',
    method: 'post',
    data: row
  })
}

export const selectList = params => {
  return request({
    url: '/api/cloud-task/project-management/select-list',
    method: 'get',
    params
  })
}

export const inspetionProjects = params => {
  return request({
    url: '/api/cloud-task/projectauth/inspetionProjects',
    method: 'get',
    params
  })
}