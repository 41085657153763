import request from '@/router/axios';

// 获得数据
export const getRealTime = (page, size, taskIds='',params) => {
    return request({
        url: '/api/mission-alarm/real-time/alarm',
        method: 'get',
        params: {
            page,
            size,
            taskIds,
            ...params
        }
    })
}