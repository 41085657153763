import request from "@/router/axios";

export const getList = (current, size, params) => {
  return request({
    url: "/api/cloud-task/project-task/page",
    method: "get",
    params: {
      ...params,
      current,
      size,
    },
  });
};

export const getDetail = (id) => {
  return request({
    url: "/api/cloud-task/project-task/detail",
    method: "get",
    params: {
      id,
    },
  });
};

export const remove = (ids) => {
  return request({
    url: "/api/cloud-task/project-task/remove",
    method: "delete",
    params: {
      ids,
    },
  });
};

export const add = (row) => {
  return request({
    url: "/api/cloud-task/project-task/add",
    method: "post",
    data: row,
  });
};

export const update = (row) => {
  return request({
    url: "/api/cloud-task/project-task/update",
    method: "put",
    data: row,
  });
};

export const getRoutes = (current, size, params) => {
  return request({
    url: "/api/cloud-task/project-task/historicalRoutePage",
    method: "get",
    params: {
      ...params,
      current,
      size,
    },
  });
};

export const checkRoutes = (params) => {
  return request({
    url: "/api/cloud-task/project-management/checkSameArea",
    method: "get",
    params,
  });
};

// 获取飞手列表
export const getFlyList = (params) => {
  return request({
    url: "/api/cloud-user/fly-operator/page",
    method: "get",
    params: {
      ...params,
      current: 1,
      size: 9999,
    },
  });
};

// 指派飞手
export const assign = (data) => {
  return request({
    url: "/api/cloud-task/project-task/assign",
    method: "post",
    data,
  });
};

// 获取任务站点
export const getSite = () => {
  return request({
    url: "/api/cloud-task/site-management/dict",
    method: "get",
  });
};

// 获取执行任务人
export const getUsers = (params) => {
  return request({
    url: "/api/blade-user/user-list",
    method: "get",
    params,
  });
};

//通过projectId查询对应航线list
export const getListByProjectID = (projectID) => {
  return request({
    url: "/api/cloud-task/conflux-line-model/getListByProjectID",
    method: "get",
    params: {
      projectID,
    },
  });
};

//通过租户ID和header查询首页飞机Home点及属性
export const getTaskToHomePage = (tenantId, bearerAuth) => {
  return request({
    url: "/api/cloud-task/project-task/getTaskToHomePage",
    method: "get",
    headers: {
      'Blade-Auth': bearerAuth,
    },
    params: {
      tenantID: tenantId
    },
  });
};


export const getHistoryVideo = (id) => {
  return request({
    url: "/api/cloud-task/project-task/get_history_video",
    method: "get",
    params: {
      id,
    },
  });
};

// 结束任务
export const endTask = (params) => {
  return request({
    url: '/api/cloud-task/project-task/closeTask',
    method: 'get',
    params
  })
}

// 取消机库任务
export const cancelHangarTask = (params) => {
  return request({
    url: '/api/cloud-task/project-task/cancelHangarTask',
    method: 'get',
    params
  })
}

// 手动任务执行
export const executeHangarManualTask = (params) => {
  return request({
    url: '/api/cloud-task/project-task/executeHangarManualTask',
    method: 'get',
    params
  })
}

// 获取航线list
export const getRouteList = (params) => {
  return request({
    url: '/api/aos-gisservice/routemanageline/list',
    method: 'get',
    params
  })
}