var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "basic-block", style: _vm.styleName }, [
    _c(
      "div",
      { staticClass: "box", style: _vm.boxStyleName },
      [
        _c("router-link", { attrs: { to: _vm.to } }, [
          _c("span", { domProps: { textContent: _vm._s(_vm.text) } }),
          _c("p", { domProps: { textContent: _vm._s(_vm.dept) } }),
          _c("i", { class: _vm.icon }),
        ]),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }